import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Partnerships from "./Partnerships";
import SocialMediaIcon from "./social-media-icon";
import Contact from "./Contact";
import NavigationItems from "./NavigationItems";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(14.5)
    }
  },
  companyAddresTitle: {
    marginBottom: theme.spacing(5)
  }
}));

const FooterPL = ({
  data: { companyAddresses, partners, socialMediaIcons },
  topNavigationItems
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <NavigationItems navigationItems={topNavigationItems} />
      <Grid item xs={12} className={classes.container}>
        <Grid container spacing={isMobile ? 10 : 5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h4" className={classes.companyAddresTitle}>
              Contact
            </Typography>
            <Grid container spacing={isMobile ? 10 : 5}>
              <Grid item xs={12}>
                <Contact companyAddress={companyAddresses[0]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Partnerships partners={partners} />
          </Grid>
          <Grid item xs={12} className={classes.socialMediaIcons}>
            {socialMediaIcons.map(icon => (
              <SocialMediaIcon data={icon} key={icon.id} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FooterPL;
