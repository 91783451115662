import React, { useState } from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import AccordionItem from "./accordion-item";
import { Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import SectionContainer from "../../section-container";
import Divider from "../../divider";
import { useTheme } from "@material-ui/core";
import { isDarkBackground } from "../../layout/theme/palette";

export const AccordionSectionQueryFragment = graphql`
  fragment AccordionSection on ContentfulModuleAccordionSection {
    sectionHeader {
      ...SectionHeader
    }
    items {
      id
      header
      subheader {
        childMarkdownRemark {
          html
        }
      }
      cta {
        ...Cta
      }
      animatedBackground
    }
    sectionHeaderAndItemsAreBelowEachOther
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  divider: {
    opacity: 0.2
  }
}));

const AccordionSection = ({
  data: {
    sectionHeader,
    items,
    sectionHeaderAndItemsAreBelowEachOther,
    sectionProperties
  }
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [activeIds, setActiveIds] = useState([]);
  const backgroundColor = sectionProperties.backgroundColor.color;
  const darkBackground = isDarkBackground(backgroundColor);

  const handleClick = id => {
    if (activeIds.includes(id)) {
      setActiveIds(prev => {
        return prev.filter(activeId => activeId !== id);
      });
    } else {
      setActiveIds(prev => [...prev, id]);
    }
  };
  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          sm={!!sectionHeaderAndItemsAreBelowEachOther ? 12 : 4}
        >
          <SectionHeader
            data={sectionHeader}
            darkBackground={
              !!sectionProperties.backgroundColor &&
              isDarkBackground(sectionProperties.backgroundColor.color)
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={!!sectionHeaderAndItemsAreBelowEachOther ? 12 : 8}
          md={!!sectionHeaderAndItemsAreBelowEachOther ? 12 : 6}
        >
          {!!items &&
            items.map((item, index) => {
              return (
                <Grid container key={item.id}>
                  {index === 0 && (
                    <Divider
                      height={!!item.animatedBackground ? 2 : 1}
                      color={
                        !!item.animatedBackground || !darkBackground
                          ? theme.palette.background.black
                          : theme.palette.background.white
                      }
                      className={classes.divider}
                    />
                  )}
                  <AccordionItem
                    data={item}
                    onClick={() => handleClick(item.id)}
                    activeIds={activeIds}
                    backgroundColor={backgroundColor}
                  />

                  <Divider
                    height={!!item.animatedBackground ? 2 : 1}
                    color={
                      !!item.animatedBackground || !darkBackground
                        ? theme.palette.background.black
                        : theme.palette.background.white
                    }
                    className={classes.divider}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default AccordionSection;
