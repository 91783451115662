import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Effect from "../../background-effects";
import Button from "../../buttons";
import { getBackgroundStyles } from "../../layout/theme/palette";

export const ContactBannerQueryFragment = graphql`
  fragment ContactBanner on ContentfulBlockContactBanner {
    id
    name
    header {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
    backgroundColor
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(6),
    wordBreak: "break-word",
    flexGrow: 1
  },
  headerDark: {
    color: theme.palette.text.secondary
  },
  container: {
    background: theme.palette.background.teal,
    padding: theme.spacing(4),
    position: "relative",
    overflow: "hidden"
  },
  content: {
    zIndex: 1,
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden"
  }
}));

const ContactBanner = ({
  data: { header, cta, backgroundColor, isHidden },
  className,
  effectType = 0
}) => {
  const classes = useStyles();

  const darkVariant = backgroundColor === "blue";

  const backgroundStyles = getBackgroundStyles(backgroundColor);

  const getEffectColor = () => {
    switch (backgroundColor) {
      case "blue":
        return "#253e52";
      case "teal":
        return "#bbd3d3";
      case "white":
        return "#e1ecec";
    }
  };

  const effectColor = getEffectColor();
  if (isHidden) {
    return <></>;
  } else {
    return (
      <Grid
        container
        className={classNames(classes.container, className, "ad-banner")}
        style={backgroundStyles}
      >
        <Effect effectType={effectType} color={effectColor} />
        <Grid container className={classes.content}>
          <RenderHtml
            html={header}
            className={classNames(
              classes.header,
              darkVariant && classes.headerDark
            )}
          />
          {!!cta ? (
            <Button
              href={cta.url}
              className={!!cta.className && cta.className}
              variant={darkVariant ? "secondaryWhite" : "secondaryBlue"}
            >
              {cta.text}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    );
  }
};

export default ContactBanner;
