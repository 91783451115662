import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import CustomLink from "../../../../link";

const useStyles = makeStyles(theme => ({
  navigationItemsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "& p": {
      padding: theme.spacing(0.375, 0)
    },
    "&:hover, &:active, &:focus, &:visited": {
      textDecoration: "underline"
    }
  }
}));

const NavigationItems = ({ navigationItems }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={4}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.navigationItemsWrapper}>
          {navigationItems.map(item => (
            <CustomLink key={item.id} link={item.url} className={classes.link}>
              <Typography variant="h3">{item.name}</Typography>
            </CustomLink>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavigationItems;
