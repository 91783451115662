/*
  Builds breadcrumbs based on the parentPage.

  Examples:
  - For parentPage with slug="/services"
    returns:
    [
      {
        path: "/services"
        text: "Services"
      }
    ]
  - For parentPage with slug="/experience-design" and grandparentPage slug="/services"
    returns:
    [
      {
        "text": "Services",
        "path": "/services"
      },
      {
        "text": "Experience design",
        "path": "/services/experience-design"
      }
    ]
*/

const buildBreadcrumbs = parentPage => {
  if (!parentPage || parentPage.length === 0) {
    return null;
  }

  const grandparentPage = parentPage?.parentPage;
  const breadcrumbs = [];

  //level 1 path - grandparent (e.g. "/services") or nothing
  if (!!grandparentPage && !!grandparentPage.slug) {
    breadcrumbs.push({
      text: grandparentPage.breadcrumbText,
      path: "/" + grandparentPage.slug
    });
  }

  //level 2 path - grandparent + parent (e.g. "/services/experience-design") or parent (e.g. "/services")
  if (!!grandparentPage && !!grandparentPage.slug) {
    breadcrumbs.push({
      text: parentPage.breadcrumbText,
      path: "/" + grandparentPage.slug + parentPage.slug
    });
  } else {
    breadcrumbs.push({
      text: parentPage.breadcrumbText,
      path: "/" + parentPage.slug
    });
  }

  return breadcrumbs;
};

export default buildBreadcrumbs;
