import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../media";

const useStyles = makeStyles(theme => ({
  singleIcon: {
    width: "auto",
    height: 38,
    margin: theme.spacing(0, 1, 2.25, 0),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 1, 2, 0)
    }
  }
}));

const ContactCompanyIcon = ({ icon }) => {
  const classes = useStyles();

  return <>{icon && <Media source={icon} className={classes.singleIcon} />}</>;
};

export default ContactCompanyIcon;
