import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import Tag from "./tag";
import ToggleButton from "./toggle-button";

const useStyles = makeStyles(theme => ({
  listHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.functional_grey_2
  },
  list: {
    display: "flex",
    paddingLeft: 0,
    margin: 0,
    flexDirection: "column",
    listStyleType: "none",
    paddingInlineStart: 0,
    "& li": {
      marginTop: theme.spacing(0.5)
    }
  },
  hidden: {
    display: "none"
  }
}));

const CollapsibleList = ({
  categoryToTagsMap,
  isExpanded,
  handleExpandCollapse,
  onTagClicked,
  selectedTag,
  darkBackground
}) => {
  const classes = useStyles();
  const categories = Array.from(categoryToTagsMap.keys());

  return (
    <Grid item xs={12}>
      <ToggleButton onClick={handleExpandCollapse} isExpanded={isExpanded} />
      <Grid
        container
        className={!isExpanded ? classes.hidden : null}
        spacing={4}
      >
        {categories.map(category => {
          return (
            <Grid item xs={6} sm={3} md={2} key={`category-${category}`}>
              <Typography
                variant="body1"
                key={category}
                className={classes.listHeader}
              >
                {category}
              </Typography>
              <Grid container component="ul" className={classes.list}>
                {categoryToTagsMap.get(category).map(tag => {
                  return (
                    <Grid item component="li">
                      <Tag
                        tag={tag}
                        onClick={onTagClicked}
                        isSelected={selectedTag.name === tag.name}
                        darkBackground={darkBackground}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CollapsibleList;
