import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Media from "../../media";
import BlogTeaserLink from "./blog-teaser-link";
import TagsListSection from "../../tags-list-section";
import truncateText from "../../../helpers/truncate-text";
import { RenderHtml } from "../../../helpers";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    height: "100%",
    width: "100%",
    textDecoration: "none",
    color: "inherit"
  },
  image: {
    marginBottom: theme.spacing(2.5),
    paddingTop: "60%",
    height: 0,
    "& img": {
      transition: "0.3s linear!important"
    }
  },
  date: {
    marginTop: theme.spacing(2.5),
    fontSize: "1rem"
  },
  text: {
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    display: "-webkit-box",
    lineClamp: 4,
    boxOrient: "vertical"
  },
  tagsList: {
    marginBottom: theme.spacing(3.75)
  },
  subheader: {
    marginTop: theme.spacing(2.5)
  }
}));

const BlogTeaser = ({
  subheader,
  text,
  date,
  timeToRead,
  image,
  link,
  target,
  tags
}) => {
  const classes = useStyles();
  target = target || "_blank";

  const dateString =
    (!!date ? date : "") + (!!timeToRead ? ` / ${timeToRead} min read` : "");

  return (
    <BlogTeaserLink link={link} target={target}>
      <Media source={image} className={classes.image} />
      <Typography variant="body1" className={classes.date}>
        {dateString}
      </Typography>
      <Typography variant="h3" className={classes.text}>
        {text}
      </Typography>
      {!!tags ? (
        <TagsListSection
          tags={tags}
          linkTo="blog"
          className={classes.tagsList}
        />
      ) : null}
      <RenderHtml
        html={truncateText(subheader.childMarkdownRemark.html, 190, true)}
        className={classes.subheader}
      />
    </BlogTeaserLink>
  );
};

export default BlogTeaser;
