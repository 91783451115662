import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Media, { MoreIcon } from "../../media";
import CaseTeaserLink from "./case-teaser-link";
import { RenderHtml } from "../../../helpers";
import truncateText from "../../../helpers/truncate-text";
import TagsListSection from "../../tags-list-section";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    height: "100%",
    width: "100%",
    textDecoration: "none",
    color: "inherit"
  },
  image: {
    marginBottom: theme.spacing(2.5),
    paddingTop: "60%",
    height: 0,
    "& img": {
      transition: "0.3s linear!important"
    }
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  tagsList: {
    marginBottom: theme.spacing(3.75)
  },
  text: {
    "& p": {
      overflow: "hidden",
      display: "-webkit-box",
      lineClamp: 4,
      boxOrient: "vertical"
    }
  }
}));

const textStyles = {
  "& p": {
    marginBottom: 0
  }
};

const CaseTeaser = ({ tags, header, subheader, image, target, url = null }) => {
  const classes = useStyles();
  target = target || "_blank";

  return (
    <CaseTeaserLink link={url} target={target}>
      <Media source={image} className={classes.image} />
      <Typography variant="h3" className={classes.header}>{header}</Typography>
      {!!tags ? (
        <TagsListSection
          tags={tags}
          linkTo="cases"
          className={classes.tagsList}
        />
      ) : null}
      {!!subheader && (
        <RenderHtml
          className={classes.text}
          html={truncateText(subheader.childMarkdownRemark.html, 190, true)}
          styles={textStyles}
        />
      )}
    </CaseTeaserLink>
  );
};

export default CaseTeaser;
