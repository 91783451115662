import typography from "./typography";

export default {
  MuiCssBaseline: {
    "@global": {
      html: {
        fontSize: typography.htmlFontSize
      }
    }
  }
};
