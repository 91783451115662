import { makeStyles } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import { Grid } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    "& $subheader p:first-child": {
      marginBottom: theme.spacing(3.125)
    }
  },
  lightBackgroundContainer: {
    backgroundColor: theme.palette.background.white,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1.5)
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3)
    },
    color: theme.palette.text.black
  },
  subheader: {
    margin: theme.spacing(2.75, 0, 4)
  },
  subheaderMargin: {
    [theme.breakpoints.up(1600)]: {
      marginTop: 0
    }
  },
  button: {
    marginBottom: theme.spacing(5.75)
  }
}));

const AccordionItemBody = ({
  data: { subheader, cta, animatedBackground },
  darkBackground
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classNames(
        classes.container,
        animatedBackground ? classes.lightBackgroundContainer : null
      )}
    >
      <Grid
        item
        xs={12}
        sm={!!animatedBackground ? 10 : 12}
        md={!!animatedBackground ? 7 : 12}
      >
        {!!subheader && (
          <RenderHtml
            html={subheader}
            className={classNames(
              classes.subheader,
              !!animatedBackground ? classes.subheaderMargin : null
            )}
          />
        )}
        {!!cta ? (
          <Button
            variant={darkBackground ? "linkWhite" : "linkBlue"}
            href={cta.url}
            className={classNames(
              classes.button,
              !!cta.className && cta.className
            )}
          >
            {cta.text}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AccordionItemBody;
