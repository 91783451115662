import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PlusMinusIcon from "./plus-minus-icon";

const useStyles = makeStyles(theme => ({
  toggleButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: "none",
    background: "none",
    padding: 0,
    cursor: "pointer"
  },
  buttonInner: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  }
}));

const ToggleButton = ({ onClick, isExpanded }) => {
  const classes = useStyles();

  return (
    <button onClick={onClick} className={classes.toggleButton}>
      {isExpanded ? (
        <Typography
          component="span"
          variant="body1"
          className={classes.buttonInner}
        >
          <PlusMinusIcon icon="minus" />
          less topics
        </Typography>
      ) : (
        <Typography
          component="span"
          variant="body1"
          className={classes.buttonInner}
        >
          <PlusMinusIcon icon="plus" />
          all topics
        </Typography>
      )}
    </button>
  );
};

export default ToggleButton;
