import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import Media from "../../media";
import Effect from "../../background-effects";
import {
  getBackgroundColorByName,
  isDarkBackground
} from "../../layout/theme/palette";

export const HeaderWithLogoQueryFragment = graphql`
  fragment HeaderWithLogo on ContentfulModuleHeaderWithLogo {
    header {
      childMarkdownRemark {
        html
      }
    }
    subheader {
      childMarkdownRemark {
        html
      }
    }
    logo {
      ...Media600
    }
    logoHidden
    cta {
      ...Cta
    }
    backgroundColor
    stripesEffectEnabled
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    height: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  containerDark: {
    color: theme.palette.text.secondary
  },
  header: {
    marginBottom: theme.spacing(4)
  },
  subheader: {
    marginBottom: theme.spacing(6)
  },
  wrapper: {
    height: "100%",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 0)
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(10, 0, 0, 0)
    }
  },
  logo: {
    width: 230,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      width: 300,
      height: 70
    },
    [theme.breakpoints.up("lg")]: {
      width: 450,
      height: 100
    }
  },
  logoContainer: {
    background: "transparent",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    }
  }
}));

const HeaderWithLogo = ({ data }) => {
  const classes = useStyles();

  const inlineStyles = {
    backgroundColor: getBackgroundColorByName(data.backgroundColor)
  };
  const darkBackground = isDarkBackground(data.backgroundColor);

  return (
    <div
      className={classNames(
        classes.container,
        darkBackground ? classes.containerDark : null
      )}
      styles={{ inlineStyles }}
    >
      {data.stripesEffectEnabled ? <Effect effectType={0} /> : null}
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          {!!data.header ? (
            <RenderHtml className={classes.header} html={data.header} />
          ) : null}
          {!!data.subheader ? (
            <RenderHtml className={classes.subheader} html={data.subheader} />
          ) : null}
          {!!data.cta ? (
            <Button
              href={data.cta.url}
              variant={darkBackground ? "secondaryWhite" : "secondaryBlue"}
              className={!!data.cta.className && data.cta.className}
            >
              {data.cta.text}
            </Button>
          ) : null}
        </Grid>
        {!!data.logo && !data.logoHidden ? (
          <Grid
            item
            container
            xs={12}
            className={classes.logoContainer}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Media source={data.logo} className={classes.logo} />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default HeaderWithLogo;
