import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import MenuItemDropdown from "./menu-item-dropdown";
import MenuItemLink from "./menu-item-link";
const useStyles = makeStyles(theme => ({
  listRoot: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(12),
      width: "100%"
    },
    textAlign: "right",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      position: "static",
      top: "auto",
      left: "auto",
      maxWidth: "none",
      transform: "none",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: 0
    }
  },
  listItemRoot: {
    padding: 0,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      padding: theme.spacing(0, 0, 0, 2),
      width: "auto",
      margin: 0,
      justifyContent: "flex-start"
    },
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "UK" ? 1150 : null}`
    )]: {
      padding: theme.spacing(0, 0, 0, 3.75)
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 0, 0, 6),
      width: "auto"
    }
  }
}));

const MenuItems = ({
  navigationItems,
  onSelectMenuItem,
  onHoverMenuItemDropdown,
  onClickMenuItemDropdown,
  dropdownMenuOpen,
  expandedMenuDropdownId,
  windowWidth,
  topNavigationDarkTheme
}) => {
  const classes = useStyles();

  navigationItems = navigationItems || [];
  const items = [...navigationItems];

  return (
    <List classes={{ root: classes.listRoot }}>
      {items.map(item => (
        <ListItem
          component={"li"}
          classes={{
            root: classes.listItemRoot
          }}
          key={item.contentful_id}
        >
          <>
            {!!item.expandable &&
            !!item.subitems &&
            !!item.subitems.length > 0 ? (
              <MenuItemDropdown
                item={item}
                text={item.name}
                items={item.subitems}
                alwaysExpanded={item.alwaysExpanded}
                onSelectMenuItem={onSelectMenuItem}
                topLevelDropdown={true}
                windowWidth={windowWidth}
                onHoverMenuItemDropdown={() =>
                  onHoverMenuItemDropdown(item.contentful_id)
                }
                onClickMenuItemDropdown={() =>
                  onClickMenuItemDropdown(item.contentful_id)
                }
                currentlyExpanded={
                  dropdownMenuOpen &&
                  !!expandedMenuDropdownId &&
                  expandedMenuDropdownId === item.contentful_id
                }
                topNavigationDarkTheme={topNavigationDarkTheme}
              />
            ) : (
              <MenuItemLink
                item={item}
                onSelectMenuItem={onSelectMenuItem}
                outlined={item.outlined}
                topLevelLink={true}
                topNavigationDarkTheme={topNavigationDarkTheme}
              />
            )}
          </>
        </ListItem>
      ))}
    </List>
  );
};

export default MenuItems;
