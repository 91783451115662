import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { RenderHtml } from "../../../helpers";
import HubspotForm from "../../hubspot-form";
import { throttle } from "lodash";
import Cookies from "universal-cookie";
import {
  getBackgroundStyles,
  isDarkBackground
} from "../../layout/theme/palette";
import classNames from "classnames";
import Button from "../../buttons";
import Media from "../../media";

export const PopupQueryFragment = graphql`
  fragment Popup on ContentfulBlockPopup {
    header {
      childMarkdownRemark {
        html
      }
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    logo {
      ...Media400
    }
    image {
      ...Media400
    }
    form {
      ...HubspotForm
    }
    cookie {
      name
    }
    cta {
      ...Cta
    }
    variant
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    width: "40%",
    padding: theme.spacing(3),
    bottom: 20,
    right: 20,
    zIndex: 105,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${2 * theme.contentSizes.mobileMargin}px)`
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      bottom: 50,
      right: 50
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%"
    },
    [theme.breakpoints.up(1800)]: {
      width: "30%"
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
      maxWidth: 600
    }
  },
  whiteText: {
    color: theme.palette.text.secondary
  },
  logoWrapper: {
    height: 40,
    "& img": {
      height: "100%"
    },
    marginBottom: theme.spacing(1)
  },
  closeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    position: "relative",
    background: "none",
    border: "none",
    height: 30,
    width: 30,
    cursor: "pointer",
    "&:before, &:after": {
      position: "absolute",
      content: '""',
      top: "50%",
      left: "50%",
      height: 2,
      width: 30,
      backgroundColor: theme.palette.background.blue
    },
    "&:before": {
      transform: "translate(-50%, -50%) rotate(45deg)"
    },
    "&:after": {
      transform: "translate(-50%, -50%) rotate(-45deg)"
    }
  },
  whiteButton: {
    "&:before, &:after": {
      backgroundColor: theme.palette.background.white
    }
  },
  imageAndHeaderContainer: {
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1)
    },
    marginBottom: theme.spacing(2)
  },
  image: {
    height: "100%"
  },
  headerWrapper: {
    marginBottom: theme.spacing(1.5)
  },
  margins: {
    margin: theme.spacing(2, 0, 4),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 2.5)
    }
  },
  textWrapper: {
    marginBottom: theme.spacing(2)
  },
  margin: {
    marginTop: 0
  },
  input: {
    "& .MuiOutlinedInput-input": {
      background: theme.palette.functional_grey_4
    }
  }
}));

const Popup = ({
  data: { header, text, logo, image, form, cookie, cta, variant, isHidden }
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const darkBackground = isDarkBackground(variant);
  const cookies = new Cookies();
  const checkCookie = !!form ? cookies.get(cookie.name) : false;
  const isMobile = useMediaQuery(theme.breakpoints.down(450));

  const [popupOpen, setPopupOpen] = useState(true);

  const handleClick = () => {
    setPopupOpen(false);
  };

  const [popupVisible, setPopupVisible] = useState(false);

  let heightOfEntireDocument = 0;
  let distanceToShowPopup = 0;

  useEffect(() => {
    heightOfEntireDocument = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    distanceToShowPopup = heightOfEntireDocument / 3;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = throttle(
    () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos >= distanceToShowPopup) {
        setPopupVisible(true);
      }
    },
    200,
    {
      leading: true,
      trailing: false
    }
  );

  const [rightDistanceFromViewport, setRightDistanceFromViewport] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const currentWindowWidth = document.body.clientWidth;
    if (currentWindowWidth >= theme.contentSizes.maxContentWidth) {
      setRightDistanceFromViewport(
        (currentWindowWidth - theme.contentSizes.maxContentWidth) / 2
      );
    } else if (currentWindowWidth <= theme.breakpoints.values["md"]) {
      setRightDistanceFromViewport(theme.contentSizes.mobileMargin);
    } else setRightDistanceFromViewport(theme.contentSizes.desktopMargin);
  };

  const backgroundStyles = getBackgroundStyles(variant);

  if (!isHidden && !checkCookie && popupOpen && popupVisible) {
    return (
      <Grid
        container
        className={classNames(
          classes.container,
          !!darkBackground ? classes.whiteText : null
        )}
        style={{ ...backgroundStyles, right: rightDistanceFromViewport }}
      >
        {!!logo ? (
          <Grid item xs={6} className={classes.logoWrapper}>
            <Media source={logo} />
          </Grid>
        ) : null}
        <Grid
          item
          xs={!!logo ? 6 : 12}
          className={classes.closeButtonContainer}
        >
          <button
            className={classNames(
              classes.closeButton,
              !!darkBackground ? classes.whiteButton : null
            )}
            onClick={handleClick}
          ></button>
        </Grid>
        {!!image && !!header ? (
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              className={classes.imageAndHeaderContainer}
            >
              <Grid item xs={isMobile ? 12 : 4}>
                <Media source={image} className={classes.image} />
              </Grid>
              <Grid item xs={isMobile ? 12 : 8}>
                <RenderHtml html={header} />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {!image && !!header ? (
          <Grid
            item
            xs={12}
            className={classNames(
              classes.headerWrapper,
              !text && !form ? classes.margins : null
            )}
          >
            <RenderHtml html={header} />
          </Grid>
        ) : null}
        {!!text ? (
          <Grid item xs={12} className={classes.textWrapper}>
            <RenderHtml html={text} />
          </Grid>
        ) : null}
        {!!cta ? (
          <Button
            variant={!!darkBackground ? "primaryWhite" : "primaryBlue"}
            href={cta.url}
            className={!!cta.className && cta.className}
          >
            {cta.text}
          </Button>
        ) : null}
        {!!form ? (
          <Grid item xs={12}>
            <HubspotForm
              data={form}
              formInputAndSubmitButtonInOneLine
              className={classes.input}
              buttonVariant={!!darkBackground ? "primaryWhite" : "primaryBlue"}
              sendFormCheckerStyles={classes.margin}
              darkBackground={darkBackground}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  } else {
    return <></>;
  }
};

export default Popup;
