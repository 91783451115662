import React from "react";
import { graphql } from "gatsby";
import SectionContainer from "../../section-container";
import { makeStyles, Grid } from "@material-ui/core";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import buildBreadcrumbs from "../../../helpers/breadcrumbs-builder";
import Breadcrumbs from "../../breadcrumbs/_components/breadcrumbs";
import classNames from "classnames";
import Button from "../../buttons";

export const PageHeroSectionWithMediaBackgroundQueryFragment = graphql`
  fragment PageHeroSectionWithMediaBackground on ContentfulModulePageHeroSectionWithMediaBackground {
    header {
      childMarkdownRemark {
        html
      }
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    media {
      ...MediaFullWidth
    }
    mediaCaption {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("sm")]: {
      minHeight: 600
    },
    [theme.breakpoints.up("1600")]: {
      minHeight: 725
    },
    [theme.breakpoints.up("xl")]: {
      width: theme.contentSizes.maxContentWidth
    },
    margin: "0 auto"
  },
  video: {
    objectFit: "cover",
    objectPosition: "50% 30%",
    zIndex: 0
  },
  image: {
    zIndex: 0
  },
  overlay: {
    backgroundColor: "rgba(0, 25, 50, 0.7)",
    zIndex: 1
  },
  position: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  wrapper: {
    width: "100%",
    zIndex: 2,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(12.5)
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.contentSizes.mobileMargin,
      marginRight: theme.contentSizes.mobileMargin
    },
    marginLeft: theme.contentSizes.desktopMargin,
    marginRight: theme.contentSizes.desktopMargin,
    marginTop: theme.spacing(22.5),
    marginBottom: theme.spacing(22.5)
  },
  breadcrumbs: {
    position: "relative",
    top: -12,
    "& a": {
      color: theme.palette.text.secondary
    }
  },
  header: {
    marginBottom: theme.spacing(4.5)
  },
  textWrapper: {
    marginBottom: theme.spacing(3)
  },
  mediaCaption: {
    padding: theme.spacing(1),
    textAlign: "right",
    maxWidth: theme.contentSizes.maxContentWidth,
    margin: "0 auto",
    "& p": {
      fontSize: "0.8rem",
      color: theme.palette.text.primary
    }
  }
}));

const PageHeroSectionWithMediaBackground = ({
  data: { header, text, media, mediaCaption, cta, sectionProperties },
  pageContext
}) => {
  const classes = useStyles();
  const breadcrumbs = buildBreadcrumbs(pageContext?.parentPage);

  const videoMediaProps = {
    muted: true,
    autoPlay: true,
    loop: true
  };

  return (
    <>
      <SectionContainer sectionProperties={sectionProperties} fullWidth>
        <Grid container className={classes.container}>
          {!!media && media.file.contentType.includes("video") ? (
            <Media
              source={media}
              className={classNames(classes.video, classes.position)}
              {...videoMediaProps}
            />
          ) : (
            <Media
              source={media}
              className={classNames(classes.image, classes.position)}
            />
          )}
          <Grid
            item
            className={classNames(classes.overlay, classes.position)}
          ></Grid>
          <Grid item className={classes.wrapper}>
            <Grid item xs={12} className={classes.breadcrumbs}>
              {!!breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderHtml
                html={header}
                className={classes.header}
                preserveFirstH1tag
              />
            </Grid>
            {!!text ? (
              <Grid item xs={12} sm={6} className={classes.textWrapper}>
                <RenderHtml html={text} />
              </Grid>
            ) : null}
            {!!cta ? (
              <Button href={cta.url} variant="secondaryWhite">
                {cta.text}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </SectionContainer>
      {!!mediaCaption && (
        <Grid item xs={12} className={classes.mediaCaption}>
          <RenderHtml html={mediaCaption} />
        </Grid>
      )}
    </>
  );
};

export default PageHeroSectionWithMediaBackground;
