import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import { Row } from "../../grid";
import Effect from "../../background-effects";
import { getBackgroundStyles } from "../../layout/theme/palette";
import classNames from "classnames";

export const FullWidthBannerQueryFragment = graphql`
  fragment FullWidthBanner on ContentfulModuleFullWidthBanner {
    text {
      childMarkdownRemark {
        html
      }
    }
    backgroundColor
    cta {
      ...Cta
    }
    ctaVariant
    ctaPosition
    animation
    animationOnWholeWidth
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    justifyContent: "center",
    color: theme.palette.text.secondary
  },
  textWrapper: {
    zIndex: 10,
    padding: theme.spacing(4, 2.5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 6.25)
    }
  },
  rightColumn: {
    position: "relative"
  },
  buttonRightWrapper: {
    zIndex: 10,
    padding: theme.spacing(4, 2.5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 6.25)
    },
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  button: {
    zIndex: 10
  }
}));

const FullWidthBanner = ({
  data: {
    text,
    backgroundColor,
    cta,
    ctaVariant,
    ctaPosition,
    animation,
    animationOnWholeWidth,
    isHidden
  }
}) => {
  const classes = useStyles();

  const backgroundStyles = getBackgroundStyles(backgroundColor);

  const getCtaVariant = () => {
    if (ctaVariant === "blue") {
      return "primaryBlue";
    } else if (ctaVariant === "red") {
      return "primaryRed";
    } else return "secondaryWhite";
  };
  ctaVariant = getCtaVariant();

  const getEffectColor = () => {
    switch (backgroundColor) {
      case "blue":
        return "#253e52";
      case "red":
        return "#F0EEEA33";
    }
  };
  const effectColor = getEffectColor();

  if (isHidden) {
    return <></>;
  } else
    return (
      <Row noPadding fullWidth style={backgroundStyles}>
        <Grid container className={classes.container}>
          {!!animation && animationOnWholeWidth ? (
            <Effect color={effectColor} effectType={1} />
          ) : null}
          <Grid
            item
            sm={
              (!!animation && !animationOnWholeWidth) || ctaPosition === "right"
                ? 8
                : null
            }
            className={classes.textWrapper}
          >
            <Grid container spacing={4}>
              <Grid item>
                <RenderHtml html={text} />
              </Grid>
              {!!cta && ctaPosition === "bottom" ? (
                <Grid item xs={12}>
                  <Button
                    variant={ctaVariant}
                    href={cta.url}
                    className={!!cta.className && cta.className}
                  >
                    {cta.text}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.rightColumn}>
            {!!animation && !animationOnWholeWidth ? (
              <Effect color={effectColor} effectType={0} />
            ) : null}
            {!!cta && ctaPosition === "right" ? (
              <div className={classes.buttonRightWrapper}>
                <Button
                  variant={ctaVariant}
                  href={cta.url}
                  className={classNames(
                    !!cta.className ? cta.className : null,
                    classes.button
                  )}
                >
                  {cta.text}
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Row>
    );
};

export default FullWidthBanner;
