export const isAbsoluteUrl = url => {
  if (!url) {
    return true;
  }

  const regex = new RegExp("^(?:[a-z]+:)?//", "i");
  return regex.test(url);
};

export const isMailtoUrl = url => {
  const regex = new RegExp("mailto:([^?]*)");
  return regex.test(url);
};

export const getMailFromUrl = url => {
  const matchResult = url.match("mailto:([^?]*)");
  return matchResult[1];
};
