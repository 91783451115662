import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import React from "react";
import { graphql, Link } from "gatsby";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import SectionContainer from "../../section-container";
import Breadcrumbs from "../../breadcrumbs/_components/breadcrumbs";
import buildBreadcrumbs from "../../../helpers/breadcrumbs-builder";
import HubspotForm from "../../hubspot-form";
import classNames from "classnames";
import {
  getBackgroundStyles,
  isDarkBackground
} from "../../layout/theme/palette";
import { ContactForm } from "../../contact-form";
import Media from "../../media";
import Effect from "../../background-effects";

export const PageHeroSectionQueryFragment = graphql`
  fragment PageHeroSection on ContentfulModulePageHeroSection {
    name
    breadcrumbText
    breadcrumbLink
    isHidden
    header {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    form {
      ... on ContentfulBlockHubspotForm {
        ...HubspotForm
      }
      ... on ContentfulModuleContactForm {
        ...ContactForm
      }
    }
    image {
      ...Media900
    }
    stripesEffectEnabled
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(20, 0)
    },
    position: "relative",
    overflow: "hidden"
  },
  leftColumn: {
    position: "relative"
  },
  header: {
    [theme.breakpoints.up("lg")]: {
      "& p": {
        width: "90%"
      }
    }
  },
  button: {
    marginTop: 30
  },
  textContainer: {
    zIndex: 10
  },
  breadcrumbs: {
    position: "absolute",
    top: -16,
    fontSize: "1rem",
    width: "auto",
    color: theme.palette.text.primary,
    textDecoration: "none"
  },
  textWhite: {
    color: theme.palette.text.secondary
  },
  formContainer: {
    justifyContent: "flex-end",
    zIndex: 10
  },
  form: {
    background: theme.palette.background.creme,
    padding: theme.spacing(3.75),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(4, 0),
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      zIndex: 3,
      width: 350
    },
    [theme.breakpoints.up("md")]: {
      width: 450
    },
    [theme.breakpoints.up("lg")]: {
      width: 500
    }
  },
  formHeader: {
    marginBottom: theme.spacing(3)
  },
  contactFormWrapper: {
    zIndex: 10,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2)
    }
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      minHeight: 200
    },
    height: "100%",
    width: "100%",
    "& > div:first-child": {
      display: "none"
    }
  }
}));

const PageHeroSection = ({
  data: {
    breadcrumbText,
    breadcrumbLink,
    header,
    text,
    image,
    form,
    cta,
    stripesEffectEnabled,
    sectionProperties,
    isHidden
  },
  pageContext
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const breadcrumbs = buildBreadcrumbs(pageContext?.parentPage);

  const backgroundColor = sectionProperties?.backgroundColor?.color || "white";
  const darkBackground = isDarkBackground(backgroundColor);
  const backgroundStyles = getBackgroundStyles(backgroundColor);

  const getEffectColor = () => {
    switch (backgroundColor) {
      case "blue":
        return "#e1ecec4d";
      case "teal":
        return "#bbd3d3";
      case "white":
        return "#e1ecec";
      default:
        return "#001932";
    }
  };

  const effectColor = getEffectColor();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        className={classes.row}
        style={backgroundStyles}
        noPadding
      >
        {stripesEffectEnabled ? <Effect color={effectColor} /> : null}
        <Grid container spacing={isMobile ? 4 : 5}>
          <Grid
            item
            xs={12}
            sm={
              !text &&
              !image &&
              form?.__typename === "ContentfulModuleContactForm"
                ? 4
                : 6
            }
            className={classes.leftColumn}
          >
            {!!breadcrumbs ? (
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                darkBackground={darkBackground}
              />
            ) : !!breadcrumbText && !!breadcrumbLink ? (
              <Link
                to={breadcrumbLink}
                className={classNames(
                  classes.breadcrumbs,
                  !!darkBackground ? classes.textWhite : null
                )}
              >
                {breadcrumbText}
              </Link>
            ) : null}
            <RenderHtml
              html={header}
              preserveFirstH1tag
              className={classNames(
                classes.header,
                !!darkBackground ? classes.textWhite : null
              )}
            />
            {!!cta ? (
              <Button
                href={cta.url}
                className={classNames(
                  classes.button,
                  !!cta.className && cta.className
                )}
                variant={darkBackground ? "secondaryWhite" : "primaryBlue"}
              >
                {cta.text}
              </Button>
            ) : null}
          </Grid>
          {!!text ? (
            <Grid item xs={12} sm={6} md={4} className={classes.textContainer}>
              <RenderHtml
                html={text}
                className={!!darkBackground ? classes.textWhite : null}
              />
            </Grid>
          ) : null}
          {!text && !!image ? (
            <Grid item container xs={12} sm={6}>
              <Media
                imgStyle={{ objectFit: "scale-down" }}
                source={image}
                className={classes.image}
              />
            </Grid>
          ) : null}
          {!text && !image && !!form ? (
            form.__typename === "ContentfulBlockHubspotForm" ? (
              <Grid
                item
                container
                xs={12}
                sm={6}
                className={classes.formContainer}
              >
                <div className={classes.form}>
                  <Typography variant="h3" className={classes.formHeader}>
                    {form.header}
                  </Typography>
                  <HubspotForm data={form} />
                </div>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                className={classes.contactFormWrapper}
              >
                <ContactForm data={form} />
              </Grid>
            )
          ) : null}
        </Grid>
      </SectionContainer>
    );
  }
};

export default PageHeroSection;
