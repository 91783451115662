import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import { isAbsoluteUrl } from "../../../helpers";
import { useContext } from "react";
import { LayoutContext } from "../../layout/LayoutContext";

const useStyles = makeStyles(theme => ({
  link: {
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem"
    },
    fontWeight: "bold",
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    padding: theme.spacing(1.2, 2.5, 1.2, 2.5),
    display: "inline-block",
    lineHeight: 1.5,
    border: `2px solid transparent`,
    transition: "background-color 250ms"
  },
  button: {
    fontSize: "1rem",
    textDecoration: "none",
    padding: theme.spacing(1.2, 2.5, 1.2, 2.5),
    display: "inline-block",
    background: "transparent",
    cursor: "pointer",
    border: `2px solid transparent`,
    transition: "background-color 250ms",
    "&:disabled": {
      opacity: 0.3
    }
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem"
    },
    textTransform: "uppercase",
    fontWeight: "bold",
    lineHeight: 1.5
  },
  primaryBlue: {
    backgroundColor: theme.palette.background.blue,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.background.blue,
    "&:hover": {
      borderColor: theme.palette.background.blue,
      background: "transparent",
      color: theme.palette.background.blue
    },
    "&:disabled": {
      "&:hover": {
        background: theme.palette.background.blue,
        color: theme.palette.text.secondary,
        cursor: "default"
      }
    }
  },
  primaryWhite: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.text.primary,
    borderColor: theme.palette.background.white,
    "&:hover": {
      borderColor: theme.palette.background.default,
      background: "transparent",
      color: theme.palette.text.secondary
    },
    "&:disabled": {
      "&:hover": {
        background: theme.palette.background.white,
        color: theme.palette.text.primary,
        cursor: "default"
      }
    }
  },
  primaryRed: {
    backgroundColor: theme.palette.background.red,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.ignite_primary_2_hover
    },
    "&:disabled": {
      "&:hover": {
        backgroundColor: theme.palette.background.red,
        color: theme.palette.text.secondary,
        cursor: "default"
      }
    }
  },
  secondaryBlue: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.ignite_primary_1,
    "&:hover": {
      backgroundColor: theme.palette.ignite_primary_1,
      color: theme.palette.text.secondary
    },
    "&:disabled": {
      "&:hover": {
        background: "transparent",
        color: theme.palette.text.primary,
        cursor: "default"
      }
    }
  },
  secondaryWhite: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.text.primary
    },
    "&:disabled": {
      "&:hover": {
        background: "transparent",
        color: theme.palette.text.secondary,
        cursor: "default"
      }
    }
  },
  secondaryRed: {
    color: theme.palette.text.link,
    borderColor: theme.palette.background.red,
    "&:hover": {
      color: theme.palette.text.secondary,
      background: theme.palette.background.red
    },
    "&:disabled": {
      "&:hover": {
        background: "transparent",
        color: theme.palette.text.link,
        cursor: "default"
      }
    }
  },
  md: {
    padding: theme.spacing(1.6, 2.5, 0.8, 2.5)
  },
  lg: {
    padding: theme.spacing(1.6, 5, 0.8, 5)
  },
  linkBlue: {
    padding: 0,
    color: theme.palette.text.primary,
    textDecoration: "underline",
    lineHeight: 1,
    border: "none",
    textAlign: "left"
  },
  linkWhite: {
    padding: 0,
    color: theme.palette.text.secondary,
    textDecoration: "underline"
  }
}));

/**
 *
 * @param {{
 * variant: "primaryBlue" | "primaryWhite" | "primaryRed" | "secondaryBlue" | "secondaryWhite" | "secondaryRed" | "linkBlue" | "linkWhite";
 * }} variant The variant to use
 *
 */

const Button = ({
  variant = "secondaryBlue",
  size = "md",
  href,
  children,
  className,
  ...props
}) => {
  const classes = useStyles();
  const baseClasses = [classes[variant], classes[size], className];

  const { location } = useContext(LayoutContext);

  // Rendered as link when link property is present,
  // otherwise rendered as a button
  if (!!href) {
    let gtmClass;
    if (href.includes("consultation")) {
      gtmClass = "gtm-book-here-cta";
    } else if (href.includes("webinar")) {
      gtmClass = "gtm-register-webinar-cta";
    } else gtmClass = "gtm-button-cta";

    if (isAbsoluteUrl(href)) {
      return (
        <a
          href={href}
          className={classNames(...baseClasses, classes.link, gtmClass)}
          target="_blank"
          rel="noopener"
          {...props}
        >
          {children}
        </a>
      );
    } else {
      if (href.includes("#")) {
        if (href.includes(location)) {
          return (
            <AnchorLink
              to={href}
              className={classNames(...baseClasses, classes.link, gtmClass)}
              {...props}
            >
              {children}
            </AnchorLink>
          );
        } else if (href.includes("/")) {
          return (
            <AnchorLink
              to={href}
              className={classNames(...baseClasses, classes.link, gtmClass)}
              {...props}
            >
              {children}
            </AnchorLink>
          );
        } else {
          return (
            <AnchorLink
              to={`${location}${href}`}
              className={classNames(...baseClasses, classes.link, gtmClass)}
              {...props}
            >
              {children}
            </AnchorLink>
          );
        }
      } else {
        return (
          <Link
            to={href}
            className={classNames(...baseClasses, classes.link, gtmClass)}
            {...props}
          >
            {children}
          </Link>
        );
      }
    }
  }

  return (
    <button className={classNames(...baseClasses, classes.button)} {...props}>
      <Typography className={classes.buttonText}>{children}</Typography>
    </button>
  );
};

export default Button;
