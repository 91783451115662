import React from "react";
import { graphql } from "gatsby";
import { Row } from "../../grid";
import { useMediaQuery, useTheme } from "@material-ui/core";

export const SectionPropertiesQueryFragment = graphql`
  fragment SectionProperties on ContentfulItemSectionProperties {
    anchorTag
    noPaddingBottom
    noPaddingTop
    backgroundColor {
      color
    }
    backgroundImage {
      ...MediaFullWidth
    }
    sectionHiddenOnMobile
    sectionHiddenOnDesktop
  }
`;

const SectionContainer = ({
  sectionProperties,
  children,
  className,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (!sectionProperties) {
    return (
      <Row className={className} {...props}>
        {children}
      </Row>
    );
  }

  if (
    (!!sectionProperties.sectionHiddenOnMobile && isMobile) ||
    (!!sectionProperties.sectionHiddenOnDesktop && !isMobile)
  ) {
    return <></>;
  }

  return (
    <Row
      id={sectionProperties.anchorTag}
      noPaddingBottom={sectionProperties.noPaddingBottom}
      noPaddingTop={sectionProperties.noPaddingTop}
      backgroundColor={
        !!sectionProperties.backgroundColor
          ? sectionProperties.backgroundColor.color
          : null
      }
      backgroundImage={sectionProperties.backgroundImage}
      className={className}
      {...props}
    >
      {children}
    </Row>
  );
};

export default SectionContainer;
