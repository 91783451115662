import { makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Divider from "../../divider";
import Button from "../../buttons";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "column"
  },
  divider: { marginBottom: theme.spacing(2) },
  header: {
    marginBottom: theme.spacing(4)
  },
  link: {
    marginTop: theme.spacing(5)
  }
}));

const CardSimple = ({ header, text, cta, darkBackground }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Divider
        height={1}
        color={
          darkBackground
            ? theme.palette.text.secondary
            : theme.palette.text.primary
        }
        className={classes.divider}
      />
      <RenderHtml html={header} className={classes.header} />
      {!!text ? <RenderHtml html={text} /> : null}
      {!!cta ? (
        <Button
          href={cta.url}
          variant="linkBlue"
          className={classNames(classes.link, !!cta.className && cta.className)}
        >
          {cta.text}
        </Button>
      ) : null}
    </>
  );
};

export default CardSimple;
