import React from "react";
import { parse as cleanupIncomingHtml } from "./";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";
import { Link } from "gatsby";
import { isAbsoluteUrl, isMailtoUrl, getMailFromUrl } from "./url-helper";
import Media from "../components/media";
import VideoEmbed from "../components/video-embed";
import palette from "../components/layout/theme/palette";
import { useContext } from "react";
import { LayoutContext } from "../components/layout/LayoutContext";
import downloadFile from "./download-file";
import Button from "../components/buttons";
import Iframe from "../components/iframe";
import Counter from "../components/counter";
import { parseStringToBoolean } from "./parse-string-to-boolean";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage
} from "react-compare-slider";

const useStyles = makeStyles(theme => ({
  inlineHtmlWrapper: styles => ({
    width: "100%",

    "& h1:not(:last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& h2:not(:last-child)": {
      marginBottom: theme.spacing(4.5)
    },
    "& h3:not(:last-child)": {
      marginBottom: theme.spacing(4.5)
    },
    "& h4:not(:last-child)": {
      marginBottom: theme.spacing(4.5)
    },
    "& h4:last-child": {
      marginBottom: 0
    },
    "& p:not(:last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& p + h1, & p + h2, & p + h3, & p + h4, & p + h5": {
      marginTop: theme.spacing(10)
    },
    "& ol": {
      paddingLeft: theme.spacing(4),
      listStyleType: "decimal",
      "& ol": {
        marginTop: theme.spacing(0.5),
        listStyleType: "lower-alpha",
        "& ol": {
          marginTop: theme.spacing(0.5),
          listStyleType: "lower-roman"
        }
      },
      "& ul": {
        marginTop: theme.spacing(0.5)
      }
    },
    "& ol + h1, & ol + h2, & ol + h3, & ol + h4, & ol + h5": {
      marginTop: theme.spacing(10)
    },
    "& ol:not(:last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& ul": {
      paddingLeft: theme.spacing(4),
      listStyleType: "disc",
      "& ul": {
        marginTop: theme.spacing(0.5),
        listStyleType: "circle",
        "& ul": {
          marginTop: theme.spacing(0.5),
          listStyleType: "square"
        }
      },
      "& ol": {
        marginTop: theme.spacing(0.5)
      }
    },
    "& ul + h1, & ul + h2, & ul + h3, & ul + h4, & ul + h5": {
      marginTop: theme.spacing(10)
    },
    "& ul:not(:last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& li:not(:last-child)": {
      marginBottom: theme.spacing(0.5)
    },
    "& a": {
      wordWrap: "break-word"
    },
    "& img": {
      maxWidth: "100%"
    },
    "& blockquote": {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "617px",
        margin: "46px 0 40px 65px"
      },
      margin: "20px 0 20px 20px"
    },
    "& blockquote p": {
      lineHeight: "36px",
      fontSize: "30px",
      fontWeight: 500,
      fontStyle: "italic",
      fontFamily: ['"Aften Screen"'],
      marginBottom: "1.2em"
    },
    "& .quoteAuthor": {
      display: "flex",
      alignItems: "center",
      "& p": {
        lineHeight: 1.56,
        fontSize: "18px",
        fontWeight: 400,
        fontStyle: "normal",
        fontFamily: ['"Aften Screen"'],
        marginBottom: 0
      }
    },
    "& .imageComparison": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      "& img": {
        margin: 0
      }
    },
    "& .quoteAuthorImage": {
      borderRadius: "50%",
      height: 100,
      width: 100,
      [theme.breakpoints.up("lg")]: {
        height: 120,
        width: 120
      },
      marginRight: theme.spacing(3.75)
    },
    "& .quoteMarginTop": {
      marginTop: theme.spacing(3)
    },
    "& .oneDimensionalTable, .twoDimensionalTable": {
      width: "100%",
      marginBottom: theme.spacing(2),
      border: "2px solid rgba(0,0,0,.15)",
      "& table": {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        fontSize: theme.spacing(2),
        "& tbody tr:nth-child(odd)": {
          background: "#F2F2F2"
        },
        "& th,td": {
          padding: ".7em",
          textAlign: "left",
          border: "1px solid rgba(0, 0, 0, .15)"
        },
        "& p": {
          fontSize: theme.spacing(2),
          marginBottom: 0
        },
        "& img": {
          marginTop: 0,
          marginBottom: 0,
          maxHeight: theme.spacing(6)
        },
        "& a": {
          wordBreak: "break-all",
          textDecoration: "none",
          fontSize: theme.spacing(1.8),
          "&:hover": {
            textDecoration: "underline"
          }
        }
      }
    },
    "& .twoDimensionalTable": {
      overflow: "auto",
      maxHeight: "85vh",
      "& table": {
        "& tbody": {
          "& tr:first-child": {
            "& th, th[scope='col'] ": {
              borderTop: "none"
            }
          },
          "& tr": {
            "& th:first-child, td:first-child": {
              borderLeft: "none"
            },
            "& th:last-child, td:last-child": {
              borderRight: "none"
            }
          },
          "& tr:last-child th, tr:last-child td": {
            borderBottom: "none"
          },
          "& tr:first-child th": {
            position: "sticky",
            top: 0,
            left: 0,
            zIndex: 50,
            background: theme.palette.background.default,
            height: 80
          },
          "& tr:first-child th:first-child": {
            left: 0,
            zIndex: 100
          },
          "& tr:not(:first-child) th[scope='row'], tr:not(:first-child) th[scope='col']": {
            position: "sticky",
            left: 0,
            zIndex: 10,
            background: theme.palette.background.default
          },
          "& tr:not(:first-child) th[scope='col']": {
            top: 80,
            zIndex: 20,
            textAlign: "center"
          },
          "& th,td": {
            wordWrap: "break-word",
            [theme.breakpoints.down("xs")]: {
              width: "30vw"
            },
            [theme.breakpoints.up("sm")]: {
              width: "20vw"
            },
            [theme.breakpoints.up("md")]: {
              width: "10vw"
            }
          }
        }
      }
    },
    "& .oneDimensionalTable": {
      "& table": {
        [theme.breakpoints.down("xs")]: {
          display: "block",
          overflowX: "scroll"
        },
        "& tr:first-child th, tr:not(:first-child) th": {
          position: "sticky",
          top: 0,
          background: "#F2F2F2",
          zIndex: 10,
          transition: "top 400ms ease-out"
        },
        "& tr:not(:first-child) th": {
          zIndex: 5
        }
      }
    },
    "& .translate": {
      "& table": {
        "& tr:first-child th, tr:not(:first-child) th": {
          [theme.breakpoints.up("md")]: {
            top: 100
          }
        }
      }
    },
    "& table + h1, & table + h2, & table + h3, & table + h4, & table + h5": {
      marginTop: theme.spacing(10)
    },
    "& .linkButton": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none"
      }
    },
    "& pre, code": {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word"
    },
    ...styles
  })
}));

let h1Counter = 0;
let preserveFirstH1 = false;

const renderChildren = children => {
  return domToReact(children, { replace: replaceWithMuiComponents });
};

const replaceWithMuiComponents = (cleanHtml, component, mainNavIsVisible) => {
  const { type, name, attribs, children } = cleanHtml;

  if (type !== "tag") {
    return;
  }

  const item = !!component ? component : name;

  switch (item) {
    case "h1":
      const shouldRenderH1tag = h1Counter === 0 && preserveFirstH1;
      const component = (
        <Typography variant="h1" component={shouldRenderH1tag ? "h1" : "h2"}>
          {renderChildren(children)}
        </Typography>
      );
      h1Counter++;
      return component;
    case "h2":
      return <Typography variant="h2">{renderChildren(children)}</Typography>;
    case "h3":
      return <Typography variant="h3">{renderChildren(children)}</Typography>;
    case "h4":
      return <Typography variant="h4">{renderChildren(children)}</Typography>;
    case "h5":
      return <Typography variant="h5">{renderChildren(children)}</Typography>;
    case "p":
      return (
        <Typography variant="body1">{renderChildren(children)}</Typography>
      );
    case "ol":
      return (
        <Typography variant="body1" component="ol">
          {renderChildren(children)}
        </Typography>
      );
    case "ul":
      return (
        <Typography variant="body1" component="ul">
          {renderChildren(children)}
        </Typography>
      );
    case "noavideo":
      return <VideoEmbed url={attribs.url} />;
    case "highlight":
      if (palette.background.hasOwnProperty(attribs.color)) {
        return (
          <span style={{ color: palette.background[attribs.color] }}>
            {renderChildren(children)}
          </span>
        );
      } else {
        return renderChildren(children);
      }
    case "table": {
      return (
        <div
          class={classNames(
            attribs.twodimensionaltable === "true"
              ? "twoDimensionalTable"
              : classNames(
                  "oneDimensionalTable",
                  mainNavIsVisible ? "translate" : ""
                )
          )}
        >
          <table>{renderChildren(children)}</table>
        </div>
      );
    }

    case "a":
      if (isMailtoUrl(attribs.href)) {
        return (
          <a
            href={`mailto:${getMailFromUrl(attribs.href)}`}
            style={{ color: attribs.color ? attribs.color : null }}
          >
            {renderChildren(children)}
          </a>
        );
      }

      const isAbsolute = isAbsoluteUrl(attribs.href);

      if (isAbsolute) {
        return (
          <a
            href={attribs.href}
            target="_blank"
            rel="noopener"
            style={{ color: attribs.color ? attribs.color : null }}
          >
            {renderChildren(children)}
          </a>
        );
      } else {
        return (
          <Link
            to={attribs.href}
            style={{ color: attribs.color ? attribs.color : null }}
          >
            {renderChildren(children)}
          </Link>
        );
      }
    case "iframe": {
      return (
        <Iframe
          src={attribs.src}
          consents={{
            preferences: parseStringToBoolean(attribs.preferences),
            statistics: parseStringToBoolean(attribs.statistics),
            marketing: parseStringToBoolean(attribs.marketing)
          }}
        />
      );
    }
    case "quote": {
      const image = { file: { url: attribs.authorimage } };
      return (
        <blockquote>
          <Typography variant="body1">{renderChildren(children)}</Typography>
          {!!attribs.authorname && (
            <div
              class={classNames(
                "quoteAuthor",
                !attribs.authorimage && "quoteMarginTop"
              )}
            >
              {!!attribs.authorimage && (
                <Media
                  source={image}
                  alt={attribs.authorname}
                  class="quoteAuthorImage"
                />
              )}
              <div>
                <Typography variant="body1">
                  <b>{attribs.authorname}</b>
                </Typography>
                {!!attribs.authorposition && (
                  <Typography variant="body1">
                    {attribs.authorposition}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </blockquote>
      );
    }
    case "downloadbutton":
      return (
        <Button
          variant="secondaryBlue"
          onClick={() => downloadFile(attribs.url, attribs.filename)}
        >
          {renderChildren(children)}
        </Button>
      );
    case "linkbutton":
      return (
        <Button
          variant={attribs.variant}
          href={attribs.url}
          className="linkButton"
        >
          {renderChildren(children)}
        </Button>
      );
    case "countdown": {
      return <Counter date={attribs.date} description={attribs.description} />;
    }
    case "image-comparison": {
      return (
        <div className="imageComparison">
          <ReactCompareSlider
            position={attribs.position ? Number(attribs.position) : 50}
            handle={
              <ReactCompareSliderHandle
                buttonStyle={{
                  backgroundColor: "#EB4646",
                  border: 0,
                  boxShadow: "rgba(164, 166, 169, 0.8) 0px 0px 10px"
                }}
                linesStyle={{
                  color: "#EB4646",
                  width: "4px",
                  boxShadow: "rgba(164, 166, 169, 0.8) 0px 0px 10px"
                }}
              />
            }
            itemOne={
              <ReactCompareSliderImage
                src={attribs.image1}
                alt={attribs.alt1}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={attribs.image2}
                alt={attribs.alt2}
              />
            }
          />
        </div>
      );
    }
    default:
      return;
  }
};

const RenderGatsbyHtml = ({
  className,
  html,
  component,
  preserveFirstH1tag,
  styles = {}
}) => {
  const classes = useStyles(styles);
  const cleanHtml = cleanupIncomingHtml(html);
  h1Counter = 0;
  preserveFirstH1 = preserveFirstH1tag;

  const { mainNavIsVisible } = useContext(LayoutContext);

  return (
    <div className={classNames(classes.inlineHtmlWrapper, className)}>
      {parse(cleanHtml, {
        replace: cleanHtml =>
          replaceWithMuiComponents(cleanHtml, component, mainNavIsVisible)
      })}
    </div>
  );
};
export default RenderGatsbyHtml;
