exports.onRouteUpdate = ({ location, prevLocation }, options) => {
  if (prevLocation === null) {
    // Stop event fired on first page load //
    return;
  } else {
    setTimeout(function() {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "mw-gatsby-route-change",
        pageUrl: location.href,
        pageTitle: document.title
      });
    }, 50);
  }
};
