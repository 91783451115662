import { makeStyles } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Media from "../../media";

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    marginBottom: theme.spacing(4),
    width: 120,
    height: 30
  },
  logo: {
    maxHeight: "100%",
    maxWidth: "100%"
  },
  text: {
    "& p": {
      marginBottom: 0
    }
  }
}));

const Partner = ({ data: { logo, text } }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.logoWrapper}>
        <Media source={logo} className={classes.logo} />
      </div>
      <RenderHtml html={text} className={classes.text} />
    </div>
  );
};

export default Partner;
