import axios from "axios";
import fileDownload from "js-file-download";

const downloadFile = (url, filename) => {
  if (!url.includes("https")) {
    url = "https:" + url;
  }
  axios({
    method: "get",
    url: url,
    responseType: "blob"
  }).then(res => fileDownload(res.data, filename));
};

export default downloadFile;
