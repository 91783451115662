import React, { useEffect, useState } from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import DropdownMenu from "./dropdown-menu";
import DropdownSubmenu from "./dropdown-submenu";
import theme from "../../../theme";
import MenuItemLink from "./menu-item-link";
import DropdownChevronIcon from "./dropdown-chevron-icon";
import palette from "../../../theme/palette";

const useStyles = makeStyles(theme => ({
  dropdown: {
    padding: 0,
    margin: 0,
    fontFamily: "Aften Screen",
    fontSize: "1rem",
    lineHeight: "1.43",
    position: "relative",
    background: "transparent",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    cursor: "pointer",
    textAlign: "left",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: theme.spacing(2),
      width: "100%",
      padding: theme.spacing(2.5, 0),
      paddingLeft: theme.contentSizes.mobileMargin
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0.5, 0),
      fontSize: "1rem",
      lineHeight: "2.125rem",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        borderBottom: "2px solid transparent",
        transition: "border-bottom-color 200ms ease-out"
      },
      "&:hover:after": {
        borderBottomColor: theme.palette.text.primary,
        transition: "border-bottom-color 600ms ease-in"
      }
    }
  },
  dropdownDark: {
    color: theme.palette.text.secondary,
    "&:hover:after": {
      borderBottomColor: theme.palette.text.secondary,
      transition: "border-bottom-color 600ms ease-in"
    }
  },
  topLevelDropdown: {
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.25, 0),
      paddingLeft: theme.contentSizes.mobileMargin
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      lineHeight: "2.125rem"
    }
  },
  secondLevelDropdown: {
    fontWeight: "bold",
    "& > div": {
      marginBottom: 8
    }
  },
  chevronWrapper: {
    width: 12,
    display: "flex",
    alignItems: "center"
  },
  chevron: {
    padding: theme.spacing(0, 0, 0.5, 0)
  },
  dropdownMenuWrapper: {
    width: "100%"
  }
}));

const MenuItemDropdown = ({
  item,
  text,
  items,
  topLevelDropdown,
  onSelectMenuItem,
  onHoverMenuItemDropdown,
  onClickMenuItemDropdown,
  currentlyExpanded,
  alwaysExpanded,
  windowWidth,
  topNavigationDarkTheme
}) => {
  const classes = useStyles();

  const isDesktop = windowWidth >= theme.breakpoints.values["md"];

  const expandedOnLoad = isDesktop ? !topLevelDropdown : alwaysExpanded;

  const [expanded, setExpanded] = useState(expandedOnLoad);
  const toggleExpand = () => setExpanded(!expanded);

  const handleClick = () => {
    if (isDesktop && topLevelDropdown) {
      onClickMenuItemDropdown();
    } else {
      toggleExpand();
    }
  };

  useEffect(() => {
    if (currentlyExpanded != null) {
      setExpanded(currentlyExpanded);
    }
  }, [currentlyExpanded]);

  return (
    <>
      {!isDesktop && alwaysExpanded && topLevelDropdown ? (
        <MenuItemLink
          item={item}
          onSelectMenuItem={onSelectMenuItem}
          topLevelLink
          topNavigationDarkTheme={topNavigationDarkTheme}
        />
      ) : (!isDesktop && alwaysExpanded) || (isDesktop && !topLevelDropdown) ? (
        <MenuItemLink
          item={item}
          onSelectMenuItem={onSelectMenuItem}
          bold
          topNavigationDarkTheme={topNavigationDarkTheme}
        />
      ) : (
        <button
          onClick={handleClick}
          onMouseEnter={
            windowWidth > theme.breakpoints.values["md"]
              ? onHoverMenuItemDropdown
              : null
          }
          className={classNames(
            classes.dropdown,
            topLevelDropdown
              ? classes.topLevelDropdown
              : classes.secondLevelDropdown,
            topNavigationDarkTheme ? classes.dropdownDark : null
          )}
        >
          <div className={classes.chevronWrapper}>
            <DropdownChevronIcon
              isActive={expanded}
              color={
                topNavigationDarkTheme
                  ? palette.background.white
                  : palette.background.blue
              }
            />
          </div>
          {text}
        </button>
      )}

      {/* DropdownMenu menu on mobile and DropdownSubmenu on mobile and desktop */}
      {/* DropdownMenu on desktop is handled in NavigationView */}
      {!(topLevelDropdown && isDesktop) && !!items && !!items.length > 0 ? (
        <div className={classes.dropdownMenuWrapper}>
          <Collapse in={expanded || alwaysExpanded}>
            {topLevelDropdown ? (
              <DropdownMenu
                items={items}
                onSelectMenuItem={onSelectMenuItem}
                windowWidth={windowWidth}
                topNavigationDarkTheme={topNavigationDarkTheme}
              />
            ) : (
              <DropdownSubmenu
                items={items}
                onSelectMenuItem={onSelectMenuItem}
                topNavigationDarkTheme={topNavigationDarkTheme}
              />
            )}
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

export default MenuItemDropdown;
