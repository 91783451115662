import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  OutlinedInput,
  Typography
} from "@material-ui/core";
import FileAttachmentButton from "./contact-form-file-attachment-button";
import Media from "../../media";
import classNames from "classnames";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    width: "100%",
    height: "100%"
  },
  formLabel: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    "&.Mui-focused": {
      color: theme.palette.text.primary
    },
    display: "flex",
    alignItems: "center",
    lineHeight: "25px"
  },
  whiteFormLabel: {
    color: theme.palette.text.secondary,
    "&.Mui-focused": {
      color: theme.palette.text.secondary
    },
    "&.Mui-focused.Mui-error": {
      color: theme.palette.text.link
    }
  },
  required: {
    "& p": {
      position: "relative",
      "&::after": {
        content: '"*"',
        position: "absolute",
        right: -10,
        top: 0,
        color: theme.palette.background.red
      }
    }
  },
  input: {
    borderRadius: 0,
    "& textarea": {
      overflow: "hidden"
    },
    backgroundColor: theme.palette.background.white
  },
  helperText: {
    color: theme.palette.text.primary,
    position: "absolute",
    bottom: "-25px",
    fontSize: "0.8rem"
  },
  root: {
    padding: 0,
    background: "transparent",
    "& $notchedOutline": {
      borderColor: theme.palette.ignite_primary_1
    },
    "& input": {
      height: "50px",
      boxSizing: "border-box",
      padding: theme.spacing(2)
    },
    "& textarea": {
      padding: theme.spacing(2)
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.ignite_primary_1,
      borderWidth: 1
    }
  },
  focused: {},
  errorFocused: {
    "&$focused $notchedOutline": {
      borderColor: theme.palette.ignite_primary_2,
      borderWidth: 2
    }
  },
  notchedOutline: {},
  whiteInput: {
    background: theme.palette.background.white
  },
  singleIcon: {
    width: "auto",
    height: 25,
    marginRight: theme.spacing(1)
  }
}));

const ContactFormInput = ({
  type,
  name,
  label,
  placeholder,
  multiline,
  rows,
  rowsMax,
  variant,
  files,
  setFiles,
  fileUploading,
  setFileUploading,
  attachmentUploadUrl,
  apiKey,
  labelIcon,
  required,
  control,
  darkBackground
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { isTouched, error }
      }) => {
        return (
          <FormControl className={classes.inputWrapper} error={Boolean(error)}>
            {!!label && (
              <FormLabel
                htmlFor={name}
                className={classNames(
                  darkBackground ? classes.whiteFormLabel : null,
                  required
                    ? classNames(classes.formLabel, classes.required)
                    : classes.formLabel
                )}
              >
                <Media source={labelIcon} className={classes.singleIcon} />
                <Typography variant="body1">{label}</Typography>
              </FormLabel>
            )}

            {type === "file" ? (
              <>
                <FileAttachmentButton
                  label="Add attachment"
                  files={files}
                  setFiles={setFiles}
                  fileUploading={fileUploading}
                  setFileUploading={setFileUploading}
                  attachmentUploadUrl={attachmentUploadUrl}
                  apiKey={apiKey}
                />
              </>
            ) : (
              <>
                <OutlinedInput
                  id={name}
                  type={type}
                  name={name}
                  variant={variant}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  multiline={multiline}
                  minRows={rows}
                  maxRows={rowsMax}
                  notched={false}
                  className={classes.input}
                  onBlur={onBlur}
                  classes={{
                    root: classes.root,
                    focused:
                      isTouched && error
                        ? classes.errorFocused
                        : classes.focused,
                    notchedOutline: classes.notchedOutline,
                    input: darkBackground ? classes.whiteInput : null
                  }}
                />
                {error ? (
                  <FormHelperText className={classes.helperText}>
                    {error}
                  </FormHelperText>
                ) : null}
              </>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
};

export default ContactFormInput;
