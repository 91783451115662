import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { RenderHtml } from "../../../../../helpers";
import ContactObfusscator from "../../../../contact-obfusscator";
import noWhiteSpaces from "../../../../../helpers/no-white-spaces";

const useStyles = makeStyles(theme => ({
  companyAddress: {
    paddingBottom: theme.spacing(2),
    "& p": {
      marginBottom: 0,
      whiteSpace: "pre-wrap"
    }
  },
  contactLinksWrapper: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(5)
    },
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
      borderBottom: "none",
      "&:hover, &:active, &:focus, &:visited": {
        color: theme.palette.text.primary,
        borderBottom: "none",
        textDecoration: "underline"
      }
    }
  }
}));

const textStyles = {
  "& h3:not(:last-child)": {
    marginBottom: 10
  }
};

const Contact = ({ companyAddress }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.companyAddress}>
        <RenderHtml html={companyAddress.address} styles={textStyles} />
      </div>
      <Typography variant="body1" className={classes.contactLinksWrapper}>
        <a href={`tel:${noWhiteSpaces(companyAddress.phone)}`}>
          {companyAddress.phone}
        </a>
        <br />
        <ContactObfusscator email={companyAddress.email} />
      </Typography>
    </>
  );
};

export default Contact;
