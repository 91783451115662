const colors = {
  ignite_primary_1: "#001932",
  ignite_primary_2: "#EB4646",
  ignite_primary_2_hover: "#B83737",
  ignite_secondary_1: "#CFE6E6",
  ignite_secondary_2: "#DED2C1",
  noa_primary_1: "#313131",
  noa_primary_2: "#FFFFFF",
  functional_grey_1: "#676A6E",
  functional_grey_2: "#A4A6A9",
  functional_grey_3: "#CECFD2",
  functional_grey_4: "#EEEEF0"
};

const darkBackgroundColors = {
  blue: "#001932",
  lightBlack: "#313131",
  black: "#111111"
};

const palette = {
  background: {
    default: "#fff",
    blue: "#001932",
    red: "#EB4646",
    teal: "#CAE0DC",
    beige: "#DBD1C7",
    creme: "#F0EEEA",
    gray: "#FAFAFA",
    lightBlack: "#313131",
    black: "#111111",
    white: "#FFFFFF",
    lightBlue: "rgba(0, 55, 255, 0.05)",
    lightGreen: "rgba(164, 210, 166, 0.12)",
    lightRed: "rgba(253, 29, 29, 0.05)"
  },
  text: {
    primary: colors.ignite_primary_1,
    secondary: "#fff",
    black: "#111111",
    link: colors.ignite_primary_2
  },
  ...colors
};

const isDarkBackground = color => {
  return (
    palette.background.hasOwnProperty(color) &&
    darkBackgroundColors.hasOwnProperty(color)
  );
};

const getBackgroundColorByName = color => {
  if (palette.background.hasOwnProperty(color)) {
    return palette.background[color];
  }
  return color;
};

const getBackgroundStyles = backgroundColor => {
  return {
    backgroundColor: palette.background.hasOwnProperty(backgroundColor)
      ? palette.background[backgroundColor]
      : backgroundColor
  };
};

export default palette;
export { isDarkBackground, getBackgroundColorByName, getBackgroundStyles };
