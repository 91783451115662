import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import classNames from "classnames";

export const SectionHeaderQueryFragment = graphql`
  fragment SectionHeader on ContentfulBlockSectionHeader {
    header {
      childMarkdownRemark {
        html
      }
    }
    subheader {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
  }
`;

const useStyles = makeStyles(theme => ({
  subheader: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    }
  },
  link: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    }
  }
}));

const SectionHeader = ({ data, darkBackground }) => {
  const classes = useStyles();

  if (!data) {
    return <></>;
  }

  return (
    <Grid container>
      {!!data.header ? <RenderHtml html={data.header} /> : null}
      {!!data.subheader ? (
        <RenderHtml html={data.subheader} className={classes.subheader} />
      ) : null}
      {!!data.cta ? (
        <Button
          href={data.cta.url}
          className={classNames(
            classes.link,
            !!data.cta.className && data.cta.className
          )}
          variant={!!darkBackground ? "secondaryWhite" : "secondaryBlue"}
        >
          {data.cta.text}
        </Button>
      ) : null}
    </Grid>
  );
};
export default SectionHeader;
