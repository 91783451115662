import React from "react";
import get from "lodash/get";
import "../../assets/styles/base.css";
import { getGlobalSettings } from "../../queries/global-settings";
import { graphql, useStaticQuery } from "gatsby";
import LayoutResponsiveWrapper from "./LayoutResponsiveWrapper";

const LayoutDataController = props => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      footerImage: contentfulAsset(
        contentful_id: { eq: "3PPdg7frKJI3ym3jiZV1pi" }
      ) {
        ...Media600
      }
      ...MwLogo
      ...GlobalSettings
      contentful404 {
        pageModulesRenderedOnUkWebsite {
          ...PageHeroSection
          ...CasesSection
          ...BlogSection
          ...Banner
        }
        pageModulesRenderedOnPlWebsite {
          ...TwoColumnsSection
        }
      }
      __typename
    }
  `);

  const locationPath = get(props, "location.pathname");
  const globalSettings = getGlobalSettings(data, locationPath);

  return (
    <LayoutResponsiveWrapper
      {...props}
      globalSettings={globalSettings}
      footerImage={data.footerImage}
      error404data={data.contentful404}
      mwLogo={data.mwLogo}
      pagePath={locationPath}
    />
  );
};

export default LayoutDataController;
