import React from "react";
import { renderModule } from "../../../../helpers";

const HandleError404 = ({ pagePath, locationPath, data }) => {
  const is404 =
    (pagePath && pagePath.includes("404")) ||
    (locationPath && locationPath.includes("404"));

  if (!is404) {
    return <></>;
  }

  if (process.env.SITE_VERSION === "PL") {
    return data.pageModulesRenderedOnPlWebsite.map((module, index) =>
      renderModule(module, index)
    );
  } else
    return data.pageModulesRenderedOnUkWebsite.map((module, index) =>
      renderModule(module, index)
    );
};

export default HandleError404;
