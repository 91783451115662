import { makeStyles } from "@material-ui/core";
import React from "react";
import Media from "../../../../media";

const useStyles = makeStyles(theme => ({
  link: {
    display: "inline-block",
    marginRight: theme.spacing(2.5)
  },
  image: {
    width: "30px",
    height: "30px"
  }
}));

const SocialMediaIcon = ({ data: { icon, url, linkTitle } }) => {
  const classes = useStyles();

  return (
    <a
      href={url}
      title={linkTitle}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
    >
      <Media source={icon} className={classes.image} />
    </a>
  );
};

export default SocialMediaIcon;
