import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import BlogTeaser from "./blog-teaser";
import SectionHeader from "../../section-header";
import SectionContainer from "../../section-container";
import useAllBlogArticles from "../../use-all-blog-articles";

export const BlogSectionQueryFragment = graphql`
  fragment BlogSection on ContentfulModuleBlogSection {
    id
    backgroundColour
    isHidden
    header {
      ...SectionHeader
    }
    articleSelectionMethod
    tagList {
      name
    }
    blogArticles {
      id
      subheader {
        childMarkdownRemark {
          html
        }
      }
      mainImage {
        ...Media600
      }
      body {
        childMarkdownRemark {
          timeToRead
        }
      }
      name
      date(formatString: "MMMM D, YYYY")
      slug
      tags {
        id
        name
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    "& ul, & ul li": {
      listStyleType: "none",
      paddingInlineStart: 0,
      paddingLeft: 0
    }
  },
  padding: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(10)
    }
  },
  marginTeaser: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7.5)
    }
  }
}));

const BlogSection = ({
  data: {
    header,
    articleSelectionMethod,
    tagList,
    blogArticles,
    sectionProperties
  },
  location
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const allBlogArticles = useAllBlogArticles();

  const sortByDate = (art1, art2) => {
    return (
      new Date(art2.node.date).getTime() - new Date(art1.node.date).getTime()
    );
  };

  const getTheLatestBlogArticles = () => {
    return allBlogArticles.sort(sortByDate).slice(0, 2);
  };

  const getLatestBlogArticlesByTags = () => {
    const listOfTagNames = tagList.map(tag => tag.name);
    let blogArticlesRelatedByTags = allBlogArticles.filter(article => {
      return (
        article.node.tags &&
        article.node.tags.some(tag => listOfTagNames.includes(tag.name))
      );
    });
    if (blogArticlesRelatedByTags < 2) {
      const blogArticlesNotRelatedByTags = allBlogArticles.filter(article => {
        return (
          article.node.tags &&
          !article.node.tags.some(tag => listOfTagNames.includes(tag.name))
        );
      });
      blogArticlesRelatedByTags = blogArticlesRelatedByTags.concat(
        blogArticlesNotRelatedByTags
      );
    }
    return blogArticlesRelatedByTags.sort(sortByDate).slice(0, 2);
  };

  const getListOfBlogArticles = () => {
    if (articleSelectionMethod === "latest blog articles") {
      return getTheLatestBlogArticles().map(item => item.node);
    } else if (articleSelectionMethod === "latest blog articles by tags") {
      return getLatestBlogArticlesByTags().map(item => item.node);
    } else {
      return blogArticles;
    }
  };

  const listOfBlogArticles = getListOfBlogArticles();

  return (
    <SectionContainer
      sectionProperties={sectionProperties}
      className={location?.pathname === "/" ? classes.padding : null}
    >
      <Grid item xs={12} className={classes.root}>
        <Grid container spacing={isMobile ? 4 : 5}>
          <Grid item xs={12} sm={4}>
            <SectionHeader data={header} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container component="ul" spacing={5}>
              {!!listOfBlogArticles
                ? listOfBlogArticles.map((article, index) => {
                    return (
                      <Grid
                        key={article.id}
                        item
                        xs={12}
                        md={6}
                        component="li"
                        className={
                          index === listOfBlogArticles.length - 1
                            ? null
                            : classes.marginTeaser
                        }
                      >
                        <BlogTeaser
                          subheader={article.subheader}
                          image={article.mainImage}
                          date={article.date}
                          timeToRead={
                            article.body.childMarkdownRemark.timeToRead
                          }
                          text={article.name}
                          link={article.slug}
                          tags={article.tags}
                          target="_self"
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default BlogSection;
