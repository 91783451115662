import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import { RenderHtml } from "../../../helpers";
import TechnologyCard from "../../technology-card/_components/technology-card";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";

export const TechnologyExpertiseSectionQueryFragment = graphql`
  fragment TechnologyExpertiseSection on ContentfulModuleTechnologyExpertiseSection {
    sectionHeader {
      ...SectionHeader
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    technologyCards {
      id
      image {
        ...Media600
      }
      header
      subheader {
        childMarkdownRemark {
          html
        }
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(5)
  }
}));

const TechnologyExpertiseSection = ({
  data: { sectionHeader, text, technologyCards, sectionProperties, isHidden }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <SectionHeader data={sectionHeader} />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Grid container spacing={10}>
              <Grid item>
                <RenderHtml html={text} className={classes.text} />
              </Grid>
              <Grid item>
                <Grid container spacing={5}>
                  {technologyCards.map(technologyCard => (
                    <Grid item xs={12} md={6} key={technologyCard.id}>
                      <TechnologyCard data={technologyCard} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default TechnologyExpertiseSection;
