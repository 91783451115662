import React from "react";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Row } from "../../grid";
import BlogTeaser from "../../blog/_components/blog-teaser";
import SectionHeader from "../../section-header";

const useStyles = makeStyles(theme => ({
  row: {
    paddingBottom: theme.spacing(16.25)
  },
  root: {
    "& ul, & ul li": {
      listStyleType: "none",
      paddingInlineStart: 0,
      paddingLeft: 0
    }
  }
}));

const BlogArticleRelated = ({ header, articles, tags, siteTitle }) => {
  const tagsNames = tags.map(tag => tag.name);

  const sortByDate = (art1, art2) =>
    new Date(art2.node.date).getTime() - new Date(art1.node.date).getTime();

  const relatedByTags = articles
    .filter(article => {
      if (article.node.name === siteTitle) return false;
      if (!!article.node.tags) {
        return article.node.tags.some(tag => {
          return tagsNames.includes(tag.name);
        });
      }
    })
    .sort(sortByDate);

  const noRelatedByTags = articles
    .filter(article => {
      if (article.node.name === siteTitle) return false;
      if (!!article.node.tags) {
        return !article.node.tags.some(tag => tagsNames.includes(tag.name));
      }
    })
    .sort(sortByDate);

  const concateArray = (relatedByTags, noRelatedByTags) => {
    if (relatedByTags.length < 2) {
      return [...relatedByTags, ...noRelatedByTags];
    } else return relatedByTags;
  };

  const relatedArticles = concateArray(relatedByTags, noRelatedByTags).slice(
    0,
    2
  );

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  return (
    <Row noPadding className={classes.row}>
      <Grid item xs={12} className={classes.root}>
        <Grid container spacing={isMobile ? 8 : 5}>
          <Grid item xs={12} sm={4}>
            <SectionHeader data={header} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={isMobile ? 8 : 5} component="ul">
              {relatedArticles.map(post => {
                return (
                  <Grid key={post.node.id} item xs={12} md={6} component="li">
                    <BlogTeaser
                      subheader={post.node.subheader}
                      image={post.node.mainImage}
                      date={post.node.date}
                      timeToRead={post.node.body.childMarkdownRemark.timeToRead}
                      text={post.node.name}
                      link={post.node.slug}
                      tags={post.node.tags}
                      target="_self"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Row>
  );
};

export default BlogArticleRelated;
