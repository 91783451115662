import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  submitMessage: {
    width: "100%",
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2)
    }
  },
  submitError: {
    color: "#f44336"
  },
  whiteText: {
    color: theme.palette.text.secondary
  }
}));

const SendFormChecker = ({
  success,
  error,
  successfulSend,
  failSend,
  textAlign = "center",
  sendFormCheckerStyles,
  darkBackground
}) => {
  const classes = useStyles();

  if (success)
    return (
      <Typography
        variant="h3"
        className={classNames(
          classes.submitMessage,
          "gtm-thank-you-message",
          sendFormCheckerStyles,
          !!darkBackground ? classes.whiteText : null
        )}
        style={{ textAlign: textAlign }}
      >
        {successfulSend}
      </Typography>
    );
  if (error)
    return (
      <Typography
        variant="h3"
        className={`${classes.submitMessage} ${classes.submitError} ${
          classes.sendFormCheckerStyles
        } ${!!darkBackground ? classes.whiteText : null}`}
        style={{ textAlign: textAlign }}
      >
        {failSend}
      </Typography>
    );
  else return null;
};

export default SendFormChecker;
