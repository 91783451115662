import { makeStyles } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player/lazy";
import PlayIcon from "./play_icon.svg";

const useStyles = makeStyles(theme => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  playIcon: {
    width: "7%",
    [theme.breakpoints.up("md")]: {
      width: "4%"
    }
  }
}));

const Video = ({ url, videoPlaceholderImageUrl, controls, loopVideo }) => {
  const classes = useStyles();

  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        style={{ position: "absolute", top: 0, left: 0 }}
        url={url}
        light={videoPlaceholderImageUrl}
        controls={typeof controls !== "undefined" ? controls : true}
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
              loop: typeof loopVideo !== "undefined" ? loopVideo : false
            }
          }
        }}
        width="100%"
        height="100%"
        playIcon={<img src={PlayIcon} className={classes.playIcon} />}
      />
    </div>
  );
};

export default Video;
