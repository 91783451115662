import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  imageWrapper: {
    marginBottom: theme.spacing(2.5)
  },
  image: {
    border: `1px solid ${theme.palette.functional_grey_3}`,
    paddingTop: "60%",
    height: 0
  },
  header: {
    marginBottom: theme.spacing(2.5)
  },
  subheader: {
    marginBottom: theme.spacing(5)
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 20,
    flexWrap: "wrap",
    marginBottom: theme.spacing(5)
  },
  linkButton: {
    paddingTop: 6
  },
  button: {
    padding: "6px 40px 0px",
    borderWidth: 1
  }
}));

const TechnologyCard = ({
  data: { image, header, subheader, linkUrl, linkText, cta }
}) => {
  const classes = useStyles();
  return (
    <>
      {!!image && (
        <div className={classes.imageWrapper}>
          <Media source={image} className={classes.image} objectFit="contain" />
        </div>
      )}
      {!!header && (
        <Typography variant="h3" className={classes.header}>
          {header}
        </Typography>
      )}
      {!!subheader && (
        <RenderHtml html={subheader} className={classes.subheader} />
      )}
      <div className={classes.buttonsWrapper}>
        {!!linkUrl && !!linkText ? (
          <Button
            variant="linkBlue"
            href={linkUrl}
            className={classes.linkButton}
          >
            {linkText}
          </Button>
        ) : null}
        {!!cta ? (
          <Button
            variant="secondaryBlue"
            href={cta.url}
            className={classNames(
              classes.button,
              !!cta.className && cta.className
            )}
          >
            {cta.text}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default TechnologyCard;
