import { Grid } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import SuccessStoryCard from "./success-story-card";
import getNumberOfColumns from "../../../helpers/get-number-of-columns";

export const SuccessStoriesSectionQueryFragment = graphql`
  fragment SuccessStoriesSection on ContentfulModuleSuccessStoriesSection {
    sectionHeader {
      ...SectionHeader
    }
    stories {
      id
      logo {
        ...Media200
      }
      header
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const SuccessStoriesSection = ({
  data: { sectionHeader, stories, sectionProperties }
}) => {
  const columns = getNumberOfColumns(stories.length);
  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SectionHeader data={sectionHeader} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {!!stories &&
              stories.map(story => (
                <Grid item xs={12} sm={6} md={columns} key={story.id}>
                  <SuccessStoryCard data={story} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default SuccessStoriesSection;
