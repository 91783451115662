import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { graphql } from "gatsby";
import React, { useEffect, useState, useContext } from "react";
import { Row } from "../../grid";
import classNames from "classnames";
import { LayoutContext } from "../../layout/LayoutContext";
import SubnavigationItem from "./subnavigation-item";
import { throttle } from "lodash";

export const SubnavigationQueryFragment = graphql`
  fragment Subnavigation on ContentfulModuleSubnavigation {
    subnavigationItems {
      id
      name
      url
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.white,
    zIndex: 99
  },
  translateContainer: {
    position: "sticky",
    top: 0,
    transition: "top 0.4s ease-out"
  },
  translate: {
    top: 80
  },
  list: {
    padding: theme.spacing(3.75, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 6, 0)
    },
    listStyleType: "none",
    textDecoration: "none"
  },
  listItem: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.25, 0),
      width: "100%"
    }
  }
}));

const Subnavigation = ({ data: { subnavigationItems } }) => {
  const classes = useStyles();

  const { mainNavIsVisible } = useContext(LayoutContext);
  const [translateSubnavigation, setTranslateSubnavigation] = useState(false);

  const theme = useTheme();
  const largeScreenResolution = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = throttle(
    () => {
      if (largeScreenResolution) {
        if (mainNavIsVisible) {
          setTranslateSubnavigation(true);
        } else {
          setTranslateSubnavigation(false);
        }
      }
    },
    200,
    {
      leading: true,
      trailing: true
    }
  );

  return (
    <Row
      noPadding
      className={classNames(
        classes.container,
        !!largeScreenResolution && classes.translateContainer,
        !!translateSubnavigation && classes.translate
      )}
    >
      <Grid
        container
        component="ul"
        className={classes.list}
        spacing={!isMobile && 5}
      >
        {!!subnavigationItems &&
          subnavigationItems.map(item => (
            <Grid
              item
              component="li"
              key={item.id}
              className={classes.listItem}
            >
              <SubnavigationItem item={item} />
            </Grid>
          ))}
      </Grid>
    </Row>
  );
};

export default Subnavigation;
