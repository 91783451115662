import React, { Fragment } from "react";

const NonBreakingWrapper = ({ word }) => (
  <span style={{ whiteSpace: "nowrap" }}>{word}</span>
);

const WrapHyphenatedWords = ({ word }) => (
  <>
    {word.indexOf("-") === -1 ? (
      <>{word}</>
    ) : (
      <NonBreakingWrapper word={word} />
    )}
  </>
);

const space = " ";

const DoNotBreakOnHyphens = ({ text }) => {
  if (!text) {
    text = "";
  }

  const words = text.split(" ");

  return (
    <>
      {words.map((word, i) => (
        <Fragment key={`${word}_${i}`}>
          <WrapHyphenatedWords word={word} />
          {space}
        </Fragment>
      ))}
    </>
  );
};

export { DoNotBreakOnHyphens };
