import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Divider from "../../divider";
import Media from "../../media";
import Button from "../../buttons";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "column"
  },
  header: {
    marginBottom: theme.spacing(2.5)
  },
  subheader: { marginBottom: theme.spacing(2.5) },
  divider: { marginBottom: theme.spacing(2.5) },
  image: {
    marginBottom: theme.spacing(3.75),
    paddingTop: "60%", //15:9 ratio
    height: 0
  },
  logoWrapper: {
    marginBottom: theme.spacing(3.75),
    width: 250,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  logo: {
    maxHeight: "100%",
    maxWidth: "100%",
    flexGrow: 1
  },
  link: {
    marginTop: theme.spacing(6.3)
  }
}));

const textStyles = {
  "& p": {
    margin: 0
  }
};

const Card = ({ image, logo, header, subheader, divider, text, cta }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container item className={classes.container}>
      {!!image ? <Media source={image} className={classes.image} /> : null}
      {!!logo ? (
        <div className={classes.logoWrapper}>
          <Media source={logo} className={classes.logo} objectFit="contain" />
        </div>
      ) : null}
      {!!header ? (
        <RenderHtml html={header} className={classes.header} />
      ) : null}
      {!!subheader ? (
        <Typography variant="body1" className={classes.subheader}>
          {subheader}
        </Typography>
      ) : null}
      {!!divider ? (
        <Divider
          height={1}
          color={theme.palette.text.primary}
          className={classes.divider}
        />
      ) : null}
      {!!text ? <RenderHtml html={text} styles={textStyles} /> : null}
      {!!cta ? (
        <Button
          href={cta.url}
          variant="linkBlue"
          className={classNames(classes.link, !!cta.className && cta.className)}
        >
          {cta.text}
        </Button>
      ) : null}
    </Grid>
  );
};

export default Card;
