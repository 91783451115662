import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(theme => ({
  chevron: {
    borderStyle: "solid",
    borderWidth: "0.13rem 0.13rem 0 0",
    content: "",
    display: "inline-block",
    height: "0.45rem",
    width: "0.45rem",
    position: "relative",
    transform: "rotate(45deg)"
  },
  rotateBottom: {
    transform: "rotate(135deg)"
  }
}));

const DropdownChevronIcon = ({ isActive, color }) => {
  const classes = useStyles();
  return (
    <span
      className={classNames(
        classes.chevron,
        isActive ? classes.rotateBottom : ""
      )}
      style={{ color: color }}
    />
  );
};

export default DropdownChevronIcon;
