const getCurrentUrl = location => {
  return !!location
    ? `${location.hostname}${location.pathname}`
    : process.env.SITE_URL.replace(/(^\w+:|^)\/\//, ""); //remove 'http(s)://' prefix
};

const getCurrentPageName = location => {
  return !!location
    ? location.hostname
    : process.env.SITE_URL.replace(/(^\w+:|^)\/\//, "");
};

export { getCurrentUrl, getCurrentPageName };
