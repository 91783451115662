import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import SectionContainer from "../../section-container";
import { Grid } from "@material-ui/core";

export const FullWidthImageSectionQueryFragment = graphql`
  fragment FullWidthImageSection on ContentfulModuleFullWidthImage {
    image {
      ...MediaFullWidth
    }
    name
    caption {
      childMarkdownRemark {
        html
      }
    }
    sidePadding
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  caption: {
    margin: theme.spacing(1),
    "& p": {
      fontSize: "0.85rem"
    }
  }
}));

const FullWidthImageSection = ({
  data: { image, sidePadding, caption, sectionProperties, isHidden }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        noPadding={!sectionProperties ? true : false}
        fullWidth={!!sidePadding ? false : true}
      >
        <Grid container>
          <Grid item xs={12}>
            <Media source={image} />
          </Grid>
          {!!caption ? (
            <Grid item xs={12}>
              <RenderHtml html={caption} className={classes.caption} />
            </Grid>
          ) : null}
        </Grid>
      </SectionContainer>
    );
};

export default FullWidthImageSection;
