import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";

const useStyles = makeStyles(theme => ({
  number: {
    marginBottom: theme.spacing(2.5)
  },
  header: {
    marginBottom: theme.spacing(2.5)
  },
  description: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3.5)
    }
  }
}));

const StepCard = ({ data: { stepNumber, header, description, cta } }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" component="h2" className={classes.number}>
        {stepNumber}
      </Typography>
      <Typography variant="h3" className={classes.header}>
        {header}
      </Typography>
      {!!description && (
        <RenderHtml html={description} className={classes.description} />
      )}
      {!!cta ? (
        <Button
          variant="primaryRed"
          href={cta.url}
          className={!!cta.className && cta.className}
        >
          {cta.text}
        </Button>
      ) : null}
    </>
  );
};

export default StepCard;
