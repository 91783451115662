import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import CaseTeaser from "./case-teaser";
import SectionHeader from "../../section-header";
import SectionContainer from "../../section-container";
import { useContext } from "react";
import { LayoutContext } from "../../layout/LayoutContext";

export const CasesSectionQueryFragment = graphql`
  fragment CasesSection on ContentfulModuleCasesSection {
    id
    isHidden
    header {
      ...SectionHeader
    }
    articleSelectionMethod
    showMainCaseStudy
    allCaseStudies {
      ...CasesPanel
    }
    tagList {
      name
    }
    caseStudies {
      id
      name
      slug
      tags {
        id
        name
      }
      teaserHeader
      teaserSubheader {
        childMarkdownRemark {
          html
        }
      }
      teaserImage {
        ...Media900
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  root: {
    "& ul, & ul li": {
      listStyleType: "none",
      paddingInlineStart: 0,
      paddingLeft: 0
    }
  },
  mainCaseStudyWrapper: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(7.5)
    }
  },
  marginMainCaseStudy: {
    marginBottom: theme.spacing(7.5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(17.5)
    }
  },
  marginTeaser: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7.5)
    }
  },
  hidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));

const CasesSection = ({
  data: {
    header,
    articleSelectionMethod,
    showMainCaseStudy,
    allCaseStudies,
    tagList,
    caseStudies,
    sectionProperties
  }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const { location } = useContext(LayoutContext);

  const getTheLatestCases = allCases => {
    const latestCases = allCases.filter(
      article => !location.includes(article.slug)
    );
    return showMainCaseStudy
      ? latestCases.slice(0, 4)
      : latestCases.slice(0, 2); //return the first few cases from a sorted list from contentful
  };

  const getTheLatestCasesByTags = allCases => {
    const tagNamesList = tagList.map(tag => tag.name);
    let casesRelatedByTags = allCases.filter(article => {
      if (location.includes(article.slug)) return false;
      if (!!article.tags) {
        return article.tags.some(tag => tagNamesList.includes(tag.name));
      }
    });
    if (
      (casesRelatedByTags.length < 4 && !!showMainCaseStudy) ||
      (casesRelatedByTags.length < 2 && !showMainCaseStudy)
    ) {
      const casesNotRelatedByTags = allCases.filter(article => {
        if (location.includes(article.slug)) return false;
        if (!!article.tags) {
          return !article.tags.some(tag => tagNamesList.includes(tag.name));
        }
      });
      casesRelatedByTags = casesRelatedByTags.concat(casesNotRelatedByTags);
    }
    return showMainCaseStudy
      ? casesRelatedByTags.slice(0, 4)
      : casesRelatedByTags.slice(0, 2);
  };

  const getListOfCases = () => {
    const allCases =
      !!allCaseStudies &&
      allCaseStudies.articles.filter(
        article => article.__typename === "ContentfulPageCase"
      ); //we make this filtering, because the list may also include contact banner

    if (articleSelectionMethod === "latest cases") {
      return getTheLatestCases(allCases);
    } else if (articleSelectionMethod === "latest cases by tags") {
      return getTheLatestCasesByTags(allCases);
    } else {
      return caseStudies;
    }
  };

  const listOfCases = getListOfCases();

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid item xs={12} className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <SectionHeader data={header} />
          </Grid>
          {!!showMainCaseStudy ? (
            listOfCases.map((article, index) => {
              if (index === 0) {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    key={article.id}
                    className={classes.marginMainCaseStudy}
                  >
                    <CaseTeaser
                      tags={article.tags}
                      header={article.teaserHeader}
                      subheader={article.teaserSubheader}
                      image={article.teaserImage}
                      url={article.slug}
                      target="_self"
                    />
                  </Grid>
                );
              } else {
                return (
                  <React.Fragment key={article.id}>
                    <Grid item sm={4} className={classes.hidden}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      className={
                        index === listOfCases.length - 1
                          ? null
                          : classes.marginTeaser
                      }
                    >
                      <CaseTeaser
                        tags={article.tags}
                        header={article.teaserHeader}
                        subheader={article.teaserSubheader}
                        image={article.teaserImage}
                        url={article.slug}
                        target="_self"
                      />
                    </Grid>
                  </React.Fragment>
                );
              }
            })
          ) : (
            <Grid item xs={12} sm={8}>
              <Grid container spacing={isMobile ? 4 : 5}>
                {listOfCases.map((article, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={article.id}
                    className={
                      index === listOfCases.length - 1
                        ? null
                        : classes.marginTeaser
                    }
                  >
                    <CaseTeaser
                      tags={article.tags}
                      header={article.teaserHeader}
                      subheader={article.teaserSubheader}
                      image={article.teaserImage}
                      url={article.slug}
                      target="_self"
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default CasesSection;
