import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  line: {
    position: "absolute",
    top: "50%",
    right: 0,
    height: 3,
    width: 25,
    backgroundColor: theme.palette.text.primary,
    transition: "transform 200ms ease-out",
    marginLeft: theme.spacing(10)
  },
  lineWhite: {
    backgroundColor: theme.palette.text.secondary
  },
  verticalLine: {
    transform: "rotate(90deg)"
  },
  rotateVerticalLine: {
    transform: "rotate(45deg)"
  },
  rotateHorizontalLine: {
    transform: "rotate(-45deg)"
  }
}));

const CrossIcon = ({ isActive, className, darkBackground }) => {
  const classes = useStyles();
  return (
    <>
      <span
        className={classNames(
          classes.line,
          isActive ? classes.rotateHorizontalLine : null,
          darkBackground ? classes.lineWhite : null,
          className
        )}
      ></span>
      <span
        className={classNames(
          classes.line,
          classes.verticalLine,
          isActive ? classes.rotateVerticalLine : null,
          darkBackground ? classes.lineWhite : null,
          className
        )}
      ></span>
    </>
  );
};

export default CrossIcon;
