import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  hidden: {
    display: "none"
  }
}));

const ContactObfusscator = ({ email }) => {
  const classes = useStyles();
  const twoParts = email.split("@");

  const classesForGtmTracking = classNames("mailto", `mailto-${twoParts[0]}`);

  function onClick(e) {
    e.preventDefault();
    (location.href = "mailto:" + twoParts[0] + "@" + twoParts[1]), true;
  }

  return (
    <a href="" className={classesForGtmTracking} onClick={onClick}>
      <span>{twoParts[0]}</span>
      <span className={classes.hidden}>xyz</span>
      <span>@{twoParts[1]}</span>
    </a>
  );
};

export default ContactObfusscator;
