import React from "react";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";
import { Controller } from "react-hook-form";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  formControlLabel: {
    display: "flex",
    alignItems: "flex-start",
    padding: 0,
    "& a": {
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.text.primary
      }
    }
  },
  whiteFormControlLabel: {
    color: theme.palette.text.secondary,
    "& a": {
      color: theme.palette.text.secondary,
      borderBottom: "none",
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.text.secondary,
        borderBottom: "none",
        textDecoration: "underline"
      }
    }
  },
  checkbox: {
    color: theme.palette.ignite_primary_1,
    paddingTop: theme.spacing(0.3),
    paddingBottom: 0,
    "&:hover": {
      background: "transparent"
    }
  },
  whiteCheckbox: {
    color: theme.palette.background.white
  },
  checkboxError: {
    color: theme.palette.ignite_primary_2,
    paddingTop: theme.spacing(0.3),
    paddingBottom: 0,
    "&:hover": {
      background: "transparent"
    }
  },
  asterisk: {
    "& p::after": {
      content: '" *"',
      color: theme.palette.ignite_primary_2
    }
  }
}));

const innerHtmlStyles = {
  "& p, & h5": {
    margin: 0,
    display: "inline"
  },
  display: "inline"
};

const ContactFormCheckbox = ({
  name,
  htmlText,
  required,
  labelTextStyles,
  control,
  darkBackground
}) => {
  const classes = useStyles();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error }
        }) => {
          return (
            <FormControlLabel
              className={classNames(
                classes.formControlLabel,
                !!darkBackground ? classes.whiteFormControlLabel : null
              )}
              control={
                <Checkbox
                  name={name}
                  color="default"
                  disableRipple
                  checked={value}
                  className={
                    Boolean(error)
                      ? classes.checkboxError
                      : classNames(
                          classes.checkbox,
                          !!darkBackground ? classes.whiteCheckbox : null
                        )
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputProps={{
                    "aria-label":
                      "field with accepting the consent of the privacy policy"
                  }}
                />
              }
              label={
                <>
                  <RenderHtml
                    html={htmlText}
                    styles={
                      !!labelTextStyles ? labelTextStyles : innerHtmlStyles
                    }
                    className={!!required && classes.asterisk}
                  />
                </>
              }
            />
          );
        }}
      ></Controller>
    </>
  );
};

export default ContactFormCheckbox;
