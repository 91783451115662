import React from "react";
import { default as MuiDivider } from "@material-ui/core/Divider";
import palette from "../../layout/theme/palette";

const Divider = ({ height, color = palette.ignite_primary_1, className }) => {
  return (
    <MuiDivider
      style={{ height: height, backgroundColor: color, width: "100%" }}
      className={className}
    />
  );
};

export default Divider;
