const onDefaultTagSelected = setQueryTag => {
  setQueryTag(undefined);
};

const onTagsSelected = (tags, setQueryTag) => {
  if (!tags || tags.length === 0) {
    setQueryTag(undefined);
  } else {
    setQueryTag(tags[0].name);
  }
};

const filterByTag = (article, queryTag) => {
  if (queryTag == null) return true;
  if (article.tags == null || article.tags.length === 0) return false;

  return article.tags.some(t => queryTag === t.name);
};

export { onDefaultTagSelected, onTagsSelected, filterByTag };
