import React from "react";
import { graphql } from "gatsby";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography
} from "@material-ui/core";
import Media from "../../media";
import HubspotForm from "../../hubspot-form";
import SectionContainer from "../../section-container";
import { RenderHtml } from "../../../helpers";
import { Row } from "../../grid";
import Button from "../../buttons";

export const PageHeroSectionWithImageAtTheTopQueryFragment = graphql`
  fragment PageHeroSectionWithImageAtTheTop on ContentfulModulePageHeroSectionWithImageAtTheTop {
    image {
      ...MediaFullWidth
    }
    header {
      childMarkdownRemark {
        html
      }
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    form {
      ...HubspotForm
    }
    cta {
      ...Cta
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    overflow: "visible",
    maxWidth: theme.contentSizes.maxContentWidth,
    margin: "0 auto"
  },
  image: {
    width: "100%",
    height: "100%"
  },
  formWrapper: {
    position: "absolute",
    top: "50%",
    right: 20,
    background: theme.palette.background.creme,
    padding: theme.spacing(3.75),
    zIndex: 3,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.up("sm")]: {
      width: 350
    },
    [theme.breakpoints.up("md")]: {
      top: "80%",
      width: 450
    },
    [theme.breakpoints.up("lg")]: {
      right: 100,
      width: 500
    }
  },
  formHeader: {
    marginBottom: theme.spacing(3)
  },
  row: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(10, 0, 12.5, 0)
    },
    padding: theme.spacing(10, 0, 22.5, 0)
  },
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  form: {
    background: theme.palette.background.creme,
    padding: theme.spacing(3.75),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(4, 0),
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 350
    },
    [theme.breakpoints.up("md")]: {
      width: 450
    },
    [theme.breakpoints.up("lg")]: {
      width: 500
    }
  }
}));

const PageHeroSectionWithImageAtTheTop = ({
  data: { image, header, text, form, cta, sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  if (isHidden) {
    return <></>;
  } else
    return (
      <>
        <SectionContainer
          className={classes.container}
          sectionProperties={{
            ...sectionProperties,
            anchorTag: isMobile ? null : sectionProperties?.anchorTag
          }}
          fullWidth
          noPadding
        >
          <Media source={image} className={classes.image} />
          {!!form ? (
            <div className={classes.formWrapper}>
              <Typography variant="h3" className={classes.formHeader}>
                {form.header}
              </Typography>
              <HubspotForm data={form} />
            </div>
          ) : null}
        </SectionContainer>
        <Row className={classes.row} noPadding>
          <Grid container spacing={isMobile ? 4 : 5}>
            <Grid item xs={12} sm={!!form ? 6 : 10}>
              <RenderHtml html={header} preserveFirstH1tag />
            </Grid>
            {!!text ? (
              <Grid item xs={12} sm={6} md={4}>
                <RenderHtml html={text} />
              </Grid>
            ) : null}
            {!!cta ? (
              <Grid item xs={12}>
                <Button
                  variant="primaryRed"
                  href={cta.url}
                  className={!!cta.className && cta.className}
                >
                  {cta.text}
                </Button>
              </Grid>
            ) : null}
            {!!form ? (
              <Grid item container xs={12} sm={6} className={classes.wrapper}>
                <div
                  className={classes.form}
                  id={isMobile && sectionProperties.anchorTag}
                >
                  <Typography variant="h3" className={classes.formHeader}>
                    {form.header}
                  </Typography>
                  <HubspotForm data={form} />
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Row>
      </>
    );
};

export default PageHeroSectionWithImageAtTheTop;
