import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import Cookie from "./cookie.svg";
import Blocked from "./blocked.svg";
import Button from "../../buttons";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "100%",
    padding: theme.spacing(4, 2),
    backgroundColor: theme.palette.background.creme
  },
  iconWrapper: {
    "& img": {
      [theme.breakpoints.down("xs")]: {
        height: 30,
        width: 30
      }
    }
  },
  headerWrapper: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(1)
    },
    marginBottom: theme.spacing(2)
  },
  textWrapper: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      width: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem"
    },
    textAlign: "justify"
  },
  textWidth: {
    [theme.breakpoints.up("sm")]: {
      width: "60%"
    }
  },
  buttonWrapper: {
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.secondary,
        textDecoration: "none"
      }
    }
  }
}));

const CookieInfo = ({
  cookiebot,
  className,
  fullWidthVideo,
  cookiesToAcceptText
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item className={className}>
        <Grid container className={classes.flexContainer} id="flex-container">
          <Grid item className={classes.iconWrapper}>
            {!!cookiebot ? <img src={Cookie} /> : <img src={Blocked} />}
          </Grid>
          <Grid item className={classes.headerWrapper}>
            <Typography variant="h3">No cookies, no video</Typography>
          </Grid>
          <Grid
            item
            className={classNames(
              classes.textWrapper,
              !!fullWidthVideo ? classes.textWidth : null
            )}
          >
            {!!cookiebot ? (
              <Typography variant="body1">
                You have blocked cookies, so in concern for your privacy, we do
                not display this video. If you want to see the video, please
                accept {cookiesToAcceptText} cookies - we only collect the
                necessary information.
              </Typography>
            ) : (
              <Typography variant="body1">
                You can't see this video because your ad blocker doesn't allow
                displaying a cookie banner, the acceptance of which is necessary
                to view this video. You can disable the ad blocker in the
                browser's extensions list or, if you have an ad blocker
                installed as a separate app on your device, in the app's
                settings.
              </Typography>
            )}
          </Grid>
          {!!cookiebot ? (
            <Grid item className={classes.buttonWrapper}>
              <Button
                href="javascript:Cookiebot.renew()"
                variant="secondaryBlue"
              >
                Manage your preferences
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default CookieInfo;
