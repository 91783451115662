export const getContainerStyles = theme => ({
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.contentSizes.mobileMargin,
    paddingRight: theme.contentSizes.mobileMargin
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.contentSizes.desktopMargin,
    paddingRight: theme.contentSizes.desktopMargin
  },
  [theme.breakpoints.up("xl")]: {
    width: theme.contentSizes.maxContentWidth
  }
});
