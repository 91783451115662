import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumb from "./breadcrumb";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    top: -16,
    width: "auto"
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    display: "flex"
  },
  separator: {
    margin: theme.spacing(0, 1),
    fontSize: "1rem"
  }
}));

const Breadcrumbs = ({ breadcrumbs, darkBackground = false }) => {
  const classes = useStyles();

  if (!breadcrumbs || breadcrumbs.length === 0) {
    return <></>;
  }

  return (
    <nav className={classes.container}>
      <ol className={classes.list}>
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={breadcrumb.path}>
            <li>
              <Breadcrumb
                text={breadcrumb.text}
                path={breadcrumb.path}
                darkBackground={darkBackground}
              />
            </li>
            {index < breadcrumbs.length - 1 ? (
              <li className={classes.separator} aria-hidden="true">
                /
              </li>
            ) : null}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
