import React from "react";
import { Link } from "gatsby";
import { Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "& p": {
      padding: theme.spacing(0.375, 0)
    },
    "&:hover, &:active, &:focus, &:visited": {
      textDecoration: "underline"
    }
  },
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const Services = ({ services }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Link to="/services" className={classes.link}>
        <Typography variant="h4" className={classes.title}>
          Services
        </Typography>
      </Link>
      {services.map(service => {
        return (
          <Link
            to={`/services/${service.slug}`}
            className={classes.link}
            key={service.id}
          >
            <Typography variant="body1">{service.name}</Typography>
          </Link>
        );
      })}
    </Grid>
  );
};

export default Services;
