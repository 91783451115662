import React, { useEffect, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1)
    }
  },
  textContainer: {
    "& img": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      "& + span": {
        display: "block",
        fontFamily: "Aften Screen",
        fontSize: "0.8rem",
        marginBottom: theme.spacing(3)
      }
    },
    "& .gatsby-resp-image-wrapper": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    "& a + span": {
      display: "block",
      fontFamily: "Aften Screen",
      fontSize: "0.8rem",
      marginBottom: theme.spacing(3)
    },
    "& img[src*='#right']": {
      float: "right"
    },
    "& img[src*='#left']": {
      float: "left"
    },
    "& code": {
      fontFamily: "Volte",
      fontSize: theme.spacing(1.75)
    },
    "& h2": {
      scrollMarginTop: `${theme.spacing(6.25)}px`,
      [theme.breakpoints.up("sm")]: {
        scrollMarginTop: `${theme.spacing(10)}px`
      }
    }
  }
}));

const textStyles = {
  "& h1": {
    fontSize: "2.22rem",
    lineHeight: 1.375
  }
};

const BlogArticleBody = ({
  data,
  setActiveHeadingId,
  idsFromTableOfContents
}) => {
  const classes = useStyles();
  const ref = useRef();
  const scrollRef = useRef(0);

  const addIdToHeadings = arr => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].setAttribute("id", idsFromTableOfContents[i]);
    }
  };

  useEffect(() => {
    const headingsFromArticle = Array.from(ref.current.querySelectorAll("h2"));
    addIdToHeadings(headingsFromArticle);
    const idsFromHeadings = headingsFromArticle.map(heading => heading.id);

    const handleObserver = entries => {
      entries.forEach(entry => {
        if (entry?.isIntersecting) {
          setActiveHeadingId(entry.target.id);
          scrollRef.current = window.scrollY;
        }
        const isScrollingUp =
          scrollRef.current - window.scrollY > 0 ? true : false;
        const currentIndex = idsFromHeadings.indexOf(entry.target.id);
        const prevEntryId = idsFromHeadings[currentIndex - 1];
        if (isScrollingUp) {
          setActiveHeadingId(prevEntryId);
        }
      });
    };
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: "-100px 0px -80% 0px"
    });

    headingsFromArticle.forEach(heading => observer.observe(heading));
    return () =>
      headingsFromArticle.forEach(heading => observer.unobserve(heading));
  }, [idsFromTableOfContents]);

  return (
    <Grid item xs={12} className={classes.container} ref={ref}>
      {!!data.body ? (
        <RenderHtml
          html={data.body.childMarkdownRemark.html}
          className={classes.textContainer}
          styles={textStyles}
        />
      ) : null}
    </Grid>
  );
};

export default BlogArticleBody;
