import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import SectionHeader from "../../section-header";
import Divider from "../../divider";
import getNumberOfColumns from "../../../helpers/get-number-of-columns";

export const ServiceCardsSectionQueryFragment = graphql`
  fragment ServiceCardsSection on ContentfulModuleServiceCardsSection {
    sectionHeader {
      ...SectionHeader
    }
    cards {
      id
      name
      slug
      serviceCardImage {
        ...Media700
      }
      serviceCardDescription {
        childMarkdownRemark {
          html
        }
      }
      serviceCardCtaText
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(11.25)
  },
  card: {
    marginBottom: theme.spacing(7.5),
    "&:last-child": {
      marginBottom: 0
    }
  },
  image: {
    marginBottom: theme.spacing(6.25),
    aspectRatio: "16/9"
  },
  header: {
    marginBottom: theme.spacing(2.5)
  },
  description: {
    marginBottom: theme.spacing(2.75)
  },
  button: {
    textDecoration: "none"
  }
}));

const ServiceCardsSection = ({
  data: { sectionHeader, cards, sectionPropertes, isHidden }
}) => {
  const classes = useStyles();
  const columns = getNumberOfColumns(cards.length);

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionPropertes}>
        <Grid container>
          <Grid item xs={12}>
            <SectionHeader data={sectionHeader} />
          </Grid>
          <Divider height={1} className={classes.divider} />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              {cards.map(card => {
                return (
                  <Grid
                    item
                    key={card.id}
                    xs={12}
                    sm={6}
                    md={columns}
                    className={classes.card}
                  >
                    <Media
                      source={card.serviceCardImage}
                      className={classes.image}
                    />
                    <Typography variant="h3" className={classes.header}>
                      {card.name}
                    </Typography>
                    <RenderHtml
                      html={card.serviceCardDescription}
                      className={classes.description}
                    />
                    <Button
                      variant="linkBlue"
                      href={`/services/${card.slug}`}
                      className={classes.button}
                    >
                      {card.serviceCardCtaText}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default ServiceCardsSection;
