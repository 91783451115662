import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import get from "lodash/get";

export const PageMetadataQueryFragment = graphql`
  fragment PageMetadata on ContentfulPageMetaData {
    title
    metaDescription
    ogTitle
    ogimage {
      ...AssetUrl
    }
    ogDescription
    javascriptForHead {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    canonicalLink
    ogSiteName
    ogLogo {
      ...AssetUrl
    }
    ogUrl
    robots
    facebookDomainVerificationId
    __typename
  }
`;

const replacePageTitle = (text, pageTitle) =>
  text.replace("%pageTitle%", pageTitle);

const Metadata = ({ metadata, pageTitle }) => {
  if (!metadata) {
    return (
      <Helmet>
        <html lang="en" />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }

  const headScript = get(
    metadata,
    "javascriptForHead.childMarkdownRemark.rawMarkdownBody"
  );

  return (
    <Helmet>
      <html lang="en" />
      <meta
        name="robots"
        content={metadata.robots ? metadata.robots : "index, follow"}
      />
      {metadata.title && (
        <title>{replacePageTitle(metadata.title, pageTitle)}</title>
      )}
      {metadata.metaDescription && (
        <meta name="description" content={metadata.metaDescription} />
      )}
      {metadata.ogUrl && <meta property="og:url" content={metadata.ogUrl} />}
      {metadata.ogTitle && (
        <meta
          property="og:title"
          content={replacePageTitle(metadata.ogTitle, pageTitle)}
          ogUrl
        />
      )}
      {metadata.ogDescription && (
        <meta property="og:description" content={metadata.ogDescription} />
      )}
      {metadata.ogLogo && (
        <meta property="og:logo" content={metadata.ogLogo.file.url} />
      )}
      {metadata.ogimage && (
        <meta
          property="og:image"
          content={`https:${metadata.ogimage.file.url}`}
        />
      )}
      {metadata.ogSiteName && (
        <meta property="og:site_name" content={metadata.ogSiteName} />
      )}
      {metadata.canonicalLink && (
        <link rel="canonical" href={metadata.canonicalLink} />
      )}
      {headScript && <script type="text/javascript">{headScript}</script>}
      {metadata.facebookDomainVerificationId && (
        <meta
          name="facebook-domain-verification"
          content={metadata.facebookDomainVerificationId}
        />
      )}
    </Helmet>
  );
};

export default Metadata;
