import React from "react";
import { Typography, makeStyles, IconButton } from "@material-ui/core";
import { FileIcon, RemoveIcon } from "../../media";
import truncateText from "../../../helpers/truncate-text";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.functional_grey_4
  },
  filename: {
    flex: 1,
    wordWrap: "break-word",
    overflow: "hidden",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  button: {
    "&:hover": {
      background: "transparent"
    }
  },
  fileIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const Attachment = ({ index, filename, handleRemove }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FileIcon className={classes.fileIcon} />
      <Typography variant="body1" className={classes.filename}>
        {truncateText(filename, 40, false)}
      </Typography>
      <IconButton
        onClick={() => handleRemove(index)}
        className={classes.button}
        disableRipple
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default Attachment;
