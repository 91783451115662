import { Grid, makeStyles } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import ListItem from "./list-item";
import { RenderHtml } from "../../../helpers";
import { isDarkBackground } from "../../layout/theme/palette";

export const ListSectionQueryFragment = graphql`
  fragment ListSection on ContentfulModuleListSection {
    header {
      ...SectionHeader
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    listItems {
      id
      header
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: "center"
  },
  listContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  headerWrapper: {
    marginBottom: theme.spacing(2)
  }
}));

const ListSection = ({
  data: { header, text, listItems, sectionProperties }
}) => {
  const classes = useStyles();
  let amountOfItems = listItems.length;
  const darkBackground = isDarkBackground(
    sectionProperties?.backgroundColor?.color
  );

  if (amountOfItems === 2) {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={4} className={classes.container}>
          {!!header ? (
            <Grid
              item
              xs={12}
              sm={4}
              container
              className={classes.headerWrapper}
            >
              <SectionHeader data={header} />
            </Grid>
          ) : null}
          {!!text ? (
            <Grid item xs={12} sm={8}>
              <RenderHtml html={text} />
            </Grid>
          ) : null}
          <Grid item className={classes.listContainer}>
            <Grid container item xs={12} md={9} spacing={5}>
              {!!listItems &&
                listItems.map(item => (
                  <Grid item key={item.id} xs={12} sm={6}>
                    <ListItem
                      header={item.header}
                      text={item.text}
                      darkBackground={darkBackground}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  } else if (amountOfItems === 3) {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={4} className={classes.container}>
          {!!header ? (
            <Grid
              item
              xs={12}
              sm={3}
              container
              className={classes.headerWrapper}
            >
              <SectionHeader data={header} />
            </Grid>
          ) : null}
          {!!text ? (
            <Grid item xs={12} sm={9}>
              <RenderHtml html={text} />
            </Grid>
          ) : null}
          <Grid item xs={12} className={classes.listContainer}>
            <Grid container item spacing={5} xs={12} md={9}>
              {!!listItems &&
                listItems.map(item => (
                  <Grid item key={item.id} xs={12} sm={4}>
                    <ListItem
                      header={item.header}
                      text={item.text}
                      darkBackground={darkBackground}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  } else if (amountOfItems >= 4) {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid
          container
          className={classes.container}
          spacing={4}
          alignItems="center"
        >
          {!!header ? (
            <Grid
              item
              xs={12}
              sm={4}
              container
              className={classes.headerWrapper}
            >
              <SectionHeader data={header} />
            </Grid>
          ) : null}
          {!!text ? (
            <Grid item xs={12} sm={8}>
              <RenderHtml html={text} />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Grid container spacing={5}>
              {!!listItems &&
                listItems.map(item => (
                  <Grid item key={item.id} xs={12} sm={6} md={3}>
                    <ListItem
                      header={item.header}
                      text={item.text}
                      darkBackground={darkBackground}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default ListSection;
