import React from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import AccordionItemHeader from "./accordion-item-header";
import AccordionItemHeaderWithAnimatedBackground from "./accordion-item-header-with-animated-background";
import AccordionItemBody from "./accordion-item-body";
import { isDarkBackground } from "../../layout/theme/palette";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

const AccordionItem = ({ data, onClick, activeIds, backgroundColor }) => {
  const classes = useStyles();
  const darkBackground = isDarkBackground(backgroundColor);

  if (data.animatedBackground) {
    return (
      <>
        <AccordionItemHeaderWithAnimatedBackground
          header={data.header}
          onClick={onClick}
          isActive={activeIds.includes(data.id)}
        />
        <Collapse
          in={activeIds.includes(data.id)}
          classes={{ root: classes.root }}
        >
          <AccordionItemBody data={data} />
        </Collapse>
      </>
    );
  } else
    return (
      <>
        <AccordionItemHeader
          header={data.header}
          onClick={onClick}
          isActive={activeIds.includes(data.id)}
          backgroundColor={backgroundColor}
        />
        <Collapse in={activeIds.includes(data.id)}>
          <AccordionItemBody data={data} darkBackground={darkBackground} />
        </Collapse>
      </>
    );
};

export default AccordionItem;
