const loadMoreArticles = (
  setNumberOfArticles,
  responsiveStatus,
  numberOfArticlesPerLoading
) => {
  if (responsiveStatus.small) {
    setNumberOfArticles(prev => prev + 1);
  } else {
    setNumberOfArticles(prev => prev + numberOfArticlesPerLoading);
  }
};

export default loadMoreArticles;
