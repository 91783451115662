import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import Media from "../../media";
import { isDarkBackground } from "../../layout/theme/palette";
import SectionContainer from "../../section-container";
import HubspotForm from "../../hubspot-form";

export const BannerSectionQueryFragment = graphql`
  fragment BannerSection on ContentfulModuleBannerSection {
    name
    isHidden
    sectionHeader {
      ...SectionHeader
    }
    sectionProperties {
      ...SectionProperties
    }
    leftPanelBackgroundImage {
      ...Media900
    }
    rightPanelBackgroundImage {
      ...Media900
    }
    form {
      ...HubspotForm
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.contentSizes.mobileMargin,
    marginRight: theme.contentSizes.mobileMargin,
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.contentSizes.desktopMargin,
      marginRight: theme.contentSizes.desktopMargin
    }
  },
  wrapper: {
    height: "auto",
    minHeight: 700,
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
      height: "auto"
    }
  },
  textPanel: {
    height: "auto",
    padding: theme.spacing(7.5, 0),
    paddingLeft: theme.contentSizes.desktopMargin,
    paddingRight: theme.contentSizes.desktopMargin,
    [theme.breakpoints.down("sm")]: {
      minHeight: 600,
      height: "auto",
      paddingLeft: theme.contentSizes.mobileMargin,
      paddingRight: theme.contentSizes.mobileMargin,
      order: 1
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    }
  },
  imagePanel: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 230,
      order: 0
    }
  },
  image: {
    height: "100%"
  }
}));

const BannerSection = ({
  data: {
    sectionHeader,
    sectionProperties,
    leftPanelBackgroundImage,
    rightPanelBackgroundImage,
    form,
    isHidden
  }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer
        fullWidth
        sectionProperties={sectionProperties}
        className={classes.container}
      >
        <Grid container className={classes.wrapper}>
          {!!leftPanelBackgroundImage && !rightPanelBackgroundImage && (
            <Grid item xs={12} md={4} className={classes.imagePanel}>
              <Media
                source={leftPanelBackgroundImage}
                className={classes.image}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={8}
            className={classes.textPanel}
            style={{
              backgroundSize: "cover",
              backgroundImage:
                !!leftPanelBackgroundImage && !!rightPanelBackgroundImage
                  ? `url(${leftPanelBackgroundImage.gatsbyImageData.images.fallback.src})`
                  : null
            }}
          >
            <Grid item xs={12} lg={11} xl={10}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <SectionHeader
                    data={sectionHeader}
                    darkBackground={
                      !!sectionProperties.backgroundColor &&
                      isDarkBackground(sectionProperties.backgroundColor.color)
                    }
                  />
                </Grid>
                {!!form ? (
                  <Grid item xs={12} sm={8}>
                    <HubspotForm data={form} />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {!!rightPanelBackgroundImage && (
            <Grid item xs={12} md={4} className={classes.imagePanel}>
              <Media
                source={rightPanelBackgroundImage}
                className={classes.image}
              />
            </Grid>
          )}
        </Grid>
      </SectionContainer>
    );
  }
};

export default BannerSection;
