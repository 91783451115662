import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../../media/Media";

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: `-${theme.spacing(5)}px`
    }
  },
  container: {
    margin: theme.spacing(5, 0),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3)
    }
  },
  subtitle: {
    display: "block",
    fontFamily: "Aften Screen",
    marginTop: theme.spacing(1)
  }
}));

const BlogArticleImage = ({ data }) => {
  const classes = useStyles();

  if (!data.mainImageDisplay) {
    return <></>;
  }
  return (
    <Grid item xs={12} className={classes.container}>
      <Media source={data.mainImage} />
      <Typography
        variant="subtitle2"
        component="span"
        className={classes.subtitle}
      >
        {data.mainImage.title}
      </Typography>
    </Grid>
  );
};

export default BlogArticleImage;
