import { graphql } from "gatsby";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";
import SectionContainer from "../../section-container";
import Media from "../../media";
import Button from "../../buttons";
import classNames from "classnames";
import { getBackgroundStyles } from "../../layout/theme/palette";

export const BannerFragmentQuery = graphql`
  fragment Banner on ContentfulModuleBanner {
    header {
      childMarkdownRemark {
        html
      }
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
    redCtaVariant
    variant
    image {
      ...Media600
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.contentSizes.mobileMargin,
    [theme.breakpoints.up("md")]: {
      margin: theme.contentSizes.desktopMargin
    },
    backgroundColor: theme.palette.background.teal,
    [theme.breakpoints.down("xs")]: {
      height: "100%"
    }
  },
  header: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(5)
  },
  headerLight: {
    color: theme.palette.text.secondary
  },
  text: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5)
    },
    color: theme.palette.text.primary
  },
  textLight: {
    color: theme.palette.text.secondary
  },
  imageWrapper: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxHeight: 300
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%"
    }
  },
  image: {
    width: "100%",
    height: "100%"
  },
  textWrapper: {
    padding: theme.spacing(6, 2.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7.5, 5)
    }
  }
}));

const Banner = ({
  data: {
    header,
    text,
    cta,
    redCtaVariant,
    variant,
    image,
    sectionProperties,
    isHidden
  }
}) => {
  const classes = useStyles();

  const darkVariant = variant === "blue";

  const backgroundStyles = getBackgroundStyles(variant);

  const getCtaVariant = () => {
    if (!!redCtaVariant) {
      return "primaryRed";
    } else if (!!darkVariant) {
      return "secondaryWhite";
    } else {
      return "secondaryBlue";
    }
  };

  const ctaVariant = getCtaVariant();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer
        noPadding
        fullWidth
        sectionProperties={sectionProperties}
      >
        <Grid
          container
          className={classNames(classes.container, "ad-banner")}
          style={backgroundStyles}
        >
          <Grid item xs={12} sm={4} className={classes.imageWrapper}>
            <Media source={image} className={classes.image} />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.textWrapper}>
            <Grid item xs={12} md={10}>
              <RenderHtml
                html={header}
                className={classNames(
                  classes.header,
                  darkVariant && classes.headerLight
                )}
              />
              <RenderHtml
                html={text}
                className={classNames(
                  classes.text,
                  darkVariant && classes.textLight
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                href={cta.url}
                variant={ctaVariant}
                className={!!cta.className && cta.className}
              >
                {cta.text}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default Banner;
