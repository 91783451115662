import React from "react";
import { Link } from "gatsby";
import Media from "../../../../media";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Row } from "../../../../grid";
import palette from "../../../theme/palette";
import BottomSectionRow from "./BottomSectionRow";
import FooterPL from "./FooterPL";
import FooterUK from "./FooterUK";
import SimplifiedFooter from "./SimplifiedFooter";

const useStyles = makeStyles(theme => ({
  footer: {
    color: theme.palette.text.primary,
    position: "relative"
  },
  row: {
    padding: theme.spacing(8, 0, 11.5)
  },
  footerContent: {
    height: "100%"
  },
  logoWrapper: {
    display: "inline-block",
    marginBottom: theme.spacing(2),
    width: 120,
    height: 31,
    [theme.breakpoints.up("lg")]: {
      width: 140,
      height: 36
    }
  },
  logo: {
    width: "100%",
    height: "100%"
  },
  badgesContainer: {
    height: 90,
    padding: "0 !important",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    }
  },
  badgeLink: {
    height: "100%"
  },
  badgeImage: {
    height: "100%",
    width: "100px",
    marginLeft: "5px"
  }
}));

const Footer = ({ globalSettings, useSimplifiedFooter }) => {
  const classes = useStyles();
  const { footer, topNavigationItems } = globalSettings;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  return (
    <footer className={classes.footer}>
      <Row
        backgroundColor={palette.background[footer.footerBackgroundColor.color]}
        className={classes.row}
        noPadding
      >
        <Grid item container xs={12} className={classes.footerContent}>
          <Grid container spacing={isMobile ? 10 : 5}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Link
                    className={classes.logoWrapper}
                    title="Noa Ignite Homepage"
                    to={"/"}
                  >
                    <Media
                      source={footer.footerLogo}
                      className={classes.logo}
                    />
                  </Link>
                </Grid>
                {!!footer.badges ? (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={8}
                    className={classes.badgesContainer}
                  >
                    {footer.badges.map(badge => (
                      <a
                        href={badge.cta.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        key={badge.id}
                        className={classes.badgeLink}
                      >
                        <Media
                          imgStyle={{ objectFit: "scale-down" }}
                          source={badge.badgeImage}
                          className={classes.badgeImage}
                        />
                      </a>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {!!useSimplifiedFooter ? (
              <SimplifiedFooter data={footer} />
            ) : (
              <>
                {process.env.SITE_VERSION === "PL" ? (
                  <FooterPL
                    data={footer}
                    topNavigationItems={topNavigationItems}
                  />
                ) : (
                  <FooterUK
                    data={footer}
                    topNavigationItems={topNavigationItems}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Row>
      <BottomSectionRow
        bottomSectionLogo={footer.bottomSectionLogo}
        copyrightText={footer.childContentfulFooterCopyrightTextNode}
        privacyPolicyText={footer.childContentfulFooterPrivacyPolicyTextNode}
      />
    </footer>
  );
};

export default Footer;
