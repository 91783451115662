import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MenuItemLink from "./menu-item-link";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  bgLight: {
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.functional_grey_3
    }
  },
  bgDark: {
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.background.blue
    }
  }
}));

const DropdownSubmenu = ({
  items,
  onSelectMenuItem,
  topNavigationDarkTheme
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      className={topNavigationDarkTheme ? classes.bgDark : classes.bgLight}
    >
      {items.map(item => (
        <Grid item xs={12} key={item.contentful_id}>
          <MenuItemLink
            item={item}
            onSelectMenuItem={onSelectMenuItem}
            topNavigationDarkTheme={topNavigationDarkTheme}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DropdownSubmenu;
