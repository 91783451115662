import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Row } from "../../grid";
import CollapsibleList from "./collapsible-list";
import useAllCasesTags from "../use-all-cases-tags";
import Tag from "./tag";

const useStyles = makeStyles(theme => ({
  tagsList: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
    listStyleType: "none",
    overflowWrap: "break-word",
    "& li": {
      display: "flex",
      position: "relative",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      cursor: "pointer"
    }
  },
  whiteText: {
    color: theme.palette.text.secondary
  },
  tagButton: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    padding: 0,
    margin: 0,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  selectedTag: {
    color: theme.palette.text.link
  }
}));

const TagsFilterSection = ({
  defaultTag,
  tags,
  onDefaultTagSelected,
  onDefaultTagSelectedWithoutScroll,
  onTagsSelected,
  onTagsSelectedWithoutScroll,
  fullWidth,
  darkBackground,
  initialTagName,
  isCasesPage = false,
  tagCategoriesOrder
}) => {
  const classes = useStyles();
  const allCasesTags = useAllCasesTags();
  const [currentTagsToShow, setCurrentTagsToShow] = useState(tags);
  const [isExpanded, setIsExpanded] = useState(false);

  function groupByCategory(objects) {
    const unsortedMap = objects.reduce((grouped, obj) => {
      const { category } = obj;
      if (!grouped.has(category)) {
        grouped.set(category, []);
      }
      grouped.get(category).push(obj);
      return grouped;
    }, new Map());

    return tagCategoriesOrder
      ? new Map(
          [...unsortedMap.entries()].sort((a, b) => {
            return (
              tagCategoriesOrder.indexOf(a[0]) -
              tagCategoriesOrder.indexOf(b[0])
            );
          })
        )
      : unsortedMap;
  }

  const categoryToTagsMap = groupByCategory(allCasesTags);

  const onDefaultTagClickedHandler = tag => {
    if (tag != null) {
      setSelectedTag(tag);
      onDefaultTagSelected();
      setIsExpanded(false);
    }
  };

  const onDefaultTagClickedHandlerInsideCollapsibleList = tag => {
    if (tag != null) {
      setSelectedTag(tag);
      onDefaultTagSelectedWithoutScroll();
      setIsExpanded(false);
    }
  };

  const onTagClickedHandler = tag => {
    if (tag != null) {
      setSelectedTag(tag);
      onTagsSelected([tag]);
    }
  };

  const onTagClickedHandlerInsideCollapsibleList = tag => {
    if (tag != null) {
      setSelectedTag(tag);
      setCurrentTagsToShow(prev => [
        tag,
        ...prev.filter(t => tag && t.name !== tag.name)
      ]);
      onTagsSelectedWithoutScroll([tag]);
    }
  };

  useEffect(() => {
    onTagClickedHandlerInsideCollapsibleList(
      allCasesTags.find(t => t.name === initialTagName)
    );
  }, [initialTagName, allCasesTags]);

  const [selectedTag, setSelectedTag] = useState(
    initialTagName
      ? allCasesTags.find(t => t.name === initialTagName)
      : defaultTag
  );

  const handleExpandCollapse = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <Row noPadding fullWidth={!!fullWidth}>
      <Grid item xs={12} md={9} lg={6}>
        <Grid component="ul" className={classes.tagsList}>
          {!!defaultTag && (
            <li key={defaultTag.id}>
              <Tag
                tag={defaultTag}
                onClick={
                  isExpanded
                    ? onDefaultTagClickedHandlerInsideCollapsibleList
                    : onDefaultTagClickedHandler
                }
                isSelected={selectedTag.name === defaultTag.name}
                darkBackground={darkBackground}
              />
            </li>
          )}
          {!isExpanded
            ? currentTagsToShow.map(tag => {
                return (
                  <li key={tag.id}>
                    <Tag
                      tag={tag}
                      onClick={onTagClickedHandler}
                      isSelected={selectedTag.name === tag.name}
                      darkBackground={darkBackground}
                    />
                  </li>
                );
              })
            : null}
        </Grid>
      </Grid>
      {isCasesPage ? (
        <CollapsibleList
          categoryToTagsMap={categoryToTagsMap}
          isExpanded={isExpanded}
          handleExpandCollapse={handleExpandCollapse}
          onTagClicked={tag => {
            setIsExpanded(false);
            onTagClickedHandlerInsideCollapsibleList(tag);
          }}
          selectedTag={selectedTag}
          darkBackground={darkBackground}
        />
      ) : null}
    </Row>
  );
};

export default TagsFilterSection;
