import React from "react";
import { graphql } from "gatsby";
import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../../media";
import ContactBanner from "../../contact-banner";
import SectionContainer from "../../section-container";
import ContactCompany from "../../contact-company";

export const TwoColumnsContainerSectionQueryFragment = graphql`
  fragment TwoColumnsContainerSection on ContentfulModuleTwoColumnsContainer {
    mainColumn {
      ... on ContentfulBlockSimpleText {
        id
        header
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulModuleQuote {
        id
        name
        quote {
          childMarkdownRemark {
            html
          }
        }
        author {
          name
          picture {
            ...Media200
          }
          position
          company
        }
      }
      ... on ContentfulBlockCompanyAddress {
        id
        name
        address {
          childMarkdownRemark {
            html
          }
        }
        phone
        email
        icon {
          ...Media200
        }
      }
      __typename
    }
    sideColumn {
      ... on ContentfulBlockSimpleText {
        id
        text {
          childMarkdownRemark {
            html
          }
        }
        __typename
      }
      ... on ContentfulModuleImage {
        id
        name
        image {
          ...Media700
        }
        __typename
      }
      ... on ContentfulBlockContactBanner {
        ...ContactBanner
      }
      __typename
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  sideColumnHidden: {
    display: "none"
  },
  image: {
    marginBottom: theme.spacing(2)
  },
  quoteContainer: {
    margin: theme.spacing(11.25, 0)
  },
  quote: {
    margin: theme.spacing(8, 0, 4, 0),
    "& p": {
      fontStyle: "italic",
      marginLeft: "10%",
      fontSize: "1.75rem",
      lineHeight: 1.2,
      [theme.breakpoints.down("sm")]: {
        marginRight: "10%"
      }
    }
  },
  quoteSignature: {
    alignItems: "center",
    marginBottom: theme.spacing(4),
    marginLeft: "10%"
  },
  quoteImageContainer: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4)
    }
  },
  quoteImage: {
    borderRadius: "50%",
    "& img": {
      margin: 0
    }
  },
  quoteAuthor: {},
  quoteAuthorName: {
    fontWeight: "bold"
  },
  sideContainer: {
    marginBottom: theme.spacing(3)
  },
  companyAddressWrapper: {
    display: "inline-block",
    width: "100%",
    "&:nth-child(2)": {
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(5)
      }
    }
  },
  companyAdress: {
    marginTop: 0,
    marginBottom: 0
  }
}));

const TwoColumnsContainerSection = ({
  data: { sideColumn, mainColumn, sectionProperties }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid item xs={12}>
        <Grid container spacing={isMobile ? 4 : 5}>
          <Grid
            item
            xs={12}
            sm={4}
            className={
              !sideColumn && isMobile ? classes.sideColumnHidden : null
            }
          >
            {sideColumn &&
              sideColumn.map(i => {
                if (i.__typename == "ContentfulBlockSimpleText") {
                  return (
                    <Grid
                      container
                      spacing={0}
                      key={i.id}
                      className={classes.sideContainer}
                    >
                      <RenderHtml html={i.text} />
                    </Grid>
                  );
                } else if (i.__typename == "ContentfulModuleImage") {
                  return (
                    <Grid
                      container
                      spacing={0}
                      key={i.id}
                      className={classes.sideContainer}
                    >
                      <Grid item xs={12}>
                        <Media source={i.image} className={classes.image} />
                      </Grid>
                    </Grid>
                  );
                } else if (i.__typename == "ContentfulBlockContactBanner") {
                  return <ContactBanner data={i} key={i.id} />;
                }
              })}
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            {!!mainColumn &&
              mainColumn.map(i => {
                if (i.__typename == "ContentfulBlockSimpleText") {
                  return (
                    <React.Fragment key={i.id}>
                      <RenderHtml html={i.text} />
                    </React.Fragment>
                  );
                } else if (i.__typename == "ContentfulModuleQuote") {
                  return (
                    <div key={i.id} className={classes.quoteContainer}>
                      <RenderHtml html={i.quote} className={classes.quote} />
                      <Grid
                        container
                        spacing={0}
                        className={classes.quoteSignature}
                      >
                        {!!i.author.picture ? (
                          <Grid
                            item
                            xs={2}
                            className={classes.quoteImageContainer}
                          >
                            <Media
                              source={i.author.picture}
                              className={classes.quoteImage}
                            />
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={8}
                          sm={4}
                          md={6}
                          lg={4}
                          className={classes.quoteAuthor}
                        >
                          <Typography
                            variant="body1"
                            className={classes.quoteAuthorName}
                          >
                            {i.author.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.quoteAuthorPosition}
                          >
                            {i.author.position}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.quoteAuthorCompany}
                          >
                            {i.author.company}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  );
                } else if (i.__typename === "ContentfulBlockCompanyAddress") {
                  return (
                    <Grid
                      key={i.id}
                      item
                      className={classes.companyAddressWrapper}
                      xs={12}
                      sm={6}
                      lg={5}
                      xl={4}
                    >
                      <ContactCompany
                        companyAddress={[i]}
                        smColumns={12}
                        mdColumns={12}
                        className={classes.companyAdress}
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default TwoColumnsContainerSection;
