import React, { useState, Fragment, useEffect } from "react";
import { graphql } from "gatsby";
import { Grid } from "@material-ui/core";
import { Row } from "../../grid";
import { makeStyles } from "@material-ui/core/styles";
import BlogTeaser from "./blog-teaser";
import InfiniteScroll from "react-infinite-scroller";
import {
  filterByTag,
  onDefaultTagSelected,
  onTagsSelected
} from "../../../helpers/tags-filter";
import TagsFilterSection from "../../tags-filter-section";
import loadMoreArticles from "../../../helpers/articles-loader";
import ContactBanner from "../../contact-banner";
import scrollTo from "gatsby-plugin-smoothscroll";

export const BlogPanelQueryFragment = graphql`
  fragment BlogPanel on ContentfulModuleBlogPanel {
    name
    defaultTag {
      id
      name
    }
    tags {
      id
      name
    }
    articles {
      ... on ContentfulPageBlogArticle {
        id
        name
        date(formatString: "MMMM D, YYYY")
        slug
        tags {
          id
          name
        }
        subheader {
          childMarkdownRemark {
            html
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
          }
        }
        mainImage {
          ...Media600
        }
        __typename
      }
      ... on ContentfulBlockContactBanner {
        ...ContactBanner
      }
    }
    promotedArticles {
      ... on ContentfulPageBlogArticle {
        id
        name
        date(formatString: "MMMM D, YYYY")
        slug
        tags {
          id
          name
        }
        subheader {
          childMarkdownRemark {
            html
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
          }
        }
        mainImage {
          ...Media900
        }
        __typename
      }
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(10)
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4.25),
      marginBottom: theme.spacing(8)
    },
    "& ul, & ul li": {
      listStyleType: "none",
      paddingInlineStart: 0,
      paddingLeft: 0
    }
  },
  item: {
    marginBottom: theme.spacing(17.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(7.5)
    }
  },
  banner: {
    height: "100%"
  }
}));

const BlogPanel = ({ data, responsiveStatus, location }) => {
  const classes = useStyles();

  const numberOfArticlesPerLoading = 6;

  const [queryTag, setQueryTag] = useState(location.state?.initialTagName);
  const [numberOfArticles, setNumberOfArticles] = useState(
    numberOfArticlesPerLoading
  );

  let listOfIndexesOfContactBanners = [];
  let listOfContactBannersWithIndexes = [];

  data.articles.forEach((article, index) => {
    if (article.__typename === "ContentfulBlockContactBanner") {
      listOfIndexesOfContactBanners.push(index);
      listOfContactBannersWithIndexes.push({ contactBanner: article, index });
    }
  });

  const promotedArticles = data.promotedArticles;

  const allArticles = data.articles
    .filter(article => article.__typename === "ContentfulPageBlogArticle")
    .filter(
      article =>
        !!promotedArticles &&
        !promotedArticles.map(a => a.id).includes(article.id)
    )
    .concat(!!promotedArticles ? promotedArticles : []);

  let teasersData = allArticles
    .filter(article => filterByTag(article, queryTag))
    .filter(
      article =>
        !(
          queryTag == null &&
          promotedArticles.map(article => article.id).includes(article.id)
        )
    )
    .sort(function(art1, art2) {
      return new Date(art2.date).getTime() - new Date(art1.date).getTime();
    });

  if (typeof queryTag === "undefined") {
    listOfIndexesOfContactBanners.map(index => {
      const { contactBanner } = listOfContactBannersWithIndexes.filter(
        item => item.index === index
      )[0];
      teasersData.splice(index, 0, contactBanner);
    });
  }

  useEffect(() => {
    setQueryTag(location.state?.initialTagName);
    if (queryTag != null || location.state?.initialTagName != null) {
      scrollTo("#blog-panel");
    }
  }, [location]);

  const handleTagSelected = (e, setQueryTag) => {
    scrollTo("#blog-panel");
    onTagsSelected(e, setQueryTag);
  };

  const handleDefaultTagSelected = (e, setQueryTag) => {
    scrollTo("#blog-panel");
    onDefaultTagSelected(e, setQueryTag);
  };

  return (
    <Fragment>
      <TagsFilterSection
        name={data.name}
        defaultTag={data.defaultTag}
        tags={data.tags}
        onDefaultTagSelected={() => handleDefaultTagSelected(setQueryTag)}
        onTagsSelected={e => handleTagSelected(e, setQueryTag)}
        isCasesPage={false}
      ></TagsFilterSection>
      <Row noPadding className={classes.container} id="blog-panel">
        <Grid item xs={12} className={classes.root}>
          <InfiniteScroll
            threshold={700}
            loadMore={() =>
              loadMoreArticles(
                setNumberOfArticles,
                responsiveStatus,
                numberOfArticlesPerLoading
              )
            }
            hasMore={numberOfArticles < teasersData.length}
          >
            <Grid container spacing={5} component="ul">
              {queryTag == null
                ? promotedArticles.map(article => {
                    return (
                      <Grid
                        key={article.id}
                        item
                        xs={12}
                        sm={6}
                        component="li"
                        className={classes.item}
                      >
                        <BlogTeaser
                          image={article.mainImage}
                          date={article.date}
                          timeToRead={
                            article.body.childMarkdownRemark.timeToRead
                          }
                          text={article.name}
                          link={article.slug}
                          tags={article.tags}
                          subheader={article.subheader}
                          target="_self"
                        />
                      </Grid>
                    );
                  })
                : null}
              {teasersData
                .slice(0, numberOfArticles)
                .map((teaserData, index) => {
                  if (teaserData.__typename === "ContentfulPageBlogArticle") {
                    return (
                      <Grid
                        key={teaserData.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        component="li"
                        className={classes.item}
                      >
                        <BlogTeaser
                          image={teaserData.mainImage}
                          date={teaserData.date}
                          timeToRead={
                            teaserData.body.childMarkdownRemark.timeToRead
                          }
                          text={teaserData.name}
                          link={teaserData.slug}
                          tags={teaserData.tags}
                          subheader={teaserData.subheader}
                          target="_self"
                        />
                      </Grid>
                    );
                  } else if (
                    teaserData.__typename === "ContentfulBlockContactBanner" &&
                    !teaserData.isHidden
                  ) {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        component="li"
                        className={classes.item}
                      >
                        <ContactBanner
                          data={teaserData}
                          className={classes.banner}
                        />
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Row>
    </Fragment>
  );
};

export default BlogPanel;
