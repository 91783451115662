import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "gatsby";
import Arrow from "../_assets/arrow.svg";
import { RenderHtml } from "../../../helpers";

export const LinksSectionQueryFragment = graphql`
  fragment LinksSection on ContentfulModuleLinksSection {
    sectionHeader {
      ...SectionHeader
    }
    links {
      id
      text {
        childMarkdownRemark {
          html
        }
      }
      url
    }
    linksVariant
    sectionHeaderAndLinksAreBelowEachOther
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.black
  },
  linkWrapper: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.background.black}`,
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 1.5)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.up(1600)]: {
      padding: theme.spacing(5, 3)
    },
    "&:hover $animatedBackground": {
      transform: "scaleX(1)"
    },
    "&:hover $arrow": {
      transform: "translateX(6px)"
    }
  },
  animatedBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    transform: "scaleX(0)",
    transformOrigin: "left center",
    transition: "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    backgroundColor: "#ded2c1"
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    position: "relative",
    width: "100%"
  },
  linkSimpleWrapper: {
    "&:not(:last-child)": {
      marginBottom: "2rem"
    }
  },
  linkSimple: {
    color: theme.palette.text.secondary
  },
  arrow: {
    zIndex: 1,
    transition: "transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s"
  },
  arrowWrapper: {
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem"
    },
    "& img": {
      height: "1em",
      width: "1em"
    }
  }
}));

const LinksSection = ({
  data: {
    sectionHeader,
    links,
    linksVariant = "animated",
    sectionHeaderAndLinksAreBelowEachOther,
    sectionProperties
  }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <SectionContainer
      sectionProperties={sectionProperties}
      className={classes.container}
    >
      <Grid container spacing={!!isMobile ? 3 : 5}>
        <Grid
          item
          xs={12}
          sm={!!sectionHeaderAndLinksAreBelowEachOther ? 12 : 4}
        >
          <SectionHeader data={sectionHeader} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={!!sectionHeaderAndLinksAreBelowEachOther ? 12 : 8}
        >
          <Grid container>
            {linksVariant === "animated"
              ? links.map(link => (
                  <Grid
                    item
                    key={link.id}
                    xs={12}
                    className={classes.linkWrapper}
                  >
                    <div className={classes.animatedBackground}></div>
                    <Link to={link.url} className={classes.link}>
                      <RenderHtml html={link.text} />
                    </Link>
                    <span className={classes.arrowWrapper}>
                      <img src={Arrow} className={classes.arrow} />
                    </span>
                  </Grid>
                ))
              : null}
            {linksVariant === "simple"
              ? links.map(link => (
                  <Grid
                    item
                    key={link.id}
                    xs={12}
                    className={classes.linkSimpleWrapper}
                  >
                    <Link to={link.url} className={classes.linkSimple}>
                      <RenderHtml html={link.text} />
                    </Link>
                  </Grid>
                ))
              : null}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default LinksSection;
