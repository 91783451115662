import React from "react";
import NavigationView from "./NavigationView";

export default ({ globalSettings, topNavigationLinksHidden, mwLogo }) => {
  return (
    <NavigationView
      globalSettings={globalSettings}
      mwLogo={mwLogo}
      topNavigationLinksHidden={topNavigationLinksHidden}
    />
  );
};
