import { Grid, makeStyles } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import ContactBanner from "../../contact-banner/_components/contact-banner";
import { Row } from "../../grid";

export const ContactBannerSectionQueryFragment = graphql`
  fragment ContactBannerSection on ContentfulModuleContactBannerSection {
    id
    name
    header {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...Cta
    }
    backgroundColor
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  banner: {
    margin: theme.spacing(7, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 6)
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(12, 0)
    }
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
}));

const ContactBannerSection = ({ data, fullWidth = false }) => {
  const classes = useStyles();

  if (data.isHidden) {
    return <></>;
  } else {
    return (
      <Row noPadding fullWidth={fullWidth}>
        <Grid item container xs={12}>
          <Grid container spacing={5}>
            <Grid item md={3} className={classes.hiddenOnMobile}></Grid>
            <Grid item xs={12} md={9}>
              <ContactBanner
                data={data}
                className={classes.banner}
                effectType={1}
              />
            </Grid>
          </Grid>
        </Grid>
      </Row>
    );
  }
};

export default ContactBannerSection;
