import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";

const Media = ({
  source,
  alt,
  className,
  style,
  fixed,
  poster,
  ...mediaProps
}) => {
  if (!source) {
    return <></>;
  }

  // we make sure all images have an alt value, even if empty
  alt = alt || source.description || "";

  const type = get(source, "file.contentType");
  if (type && type.includes("video"))
    return (
      <video
        src={source.file.url}
        alt={alt}
        className={className}
        style={style}
        type={source.file.contentType}
        poster={!!poster ? poster.file.url : null}
        {...mediaProps}
      />
    );

  if (type && type.includes("svg"))
    return (
      <img
        src={source.file.url}
        alt={alt}
        className={className}
        style={style}
        loading="auto"
        {...mediaProps}
      />
    );
  if (!!source.fullWidth) {
    return (
      <GatsbyImage
        image={{
          ...source.fullWidth
        }}
        alt={alt}
        className={className}
        style={style}
        loading="auto"
        {...mediaProps}
      />
    );
  }
  return !!source.gatsbyImageData && !fixed ? (
    <GatsbyImage
      image={{
        ...source.gatsbyImageData
      }}
      alt={alt}
      className={className}
      style={style}
      loading="auto"
      {...mediaProps}
    />
  ) : (
    <img
      src={source.file ? source.file.url : ""}
      alt={alt}
      className={className}
      style={style}
      loading="auto"
      {...mediaProps}
    />
  );
};

export default Media;
