import React from "react";
import { graphql } from "gatsby";
import { Grid } from "@material-ui/core";
import { Row } from "../grid";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../media";
import { RenderHtml } from "../../helpers";

export const ImageQueryFragment = graphql`
  fragment Image on ContentfulModuleImage {
    id
    name
    isHidden
    image {
      ...Media1000
    }
    header {
      childMarkdownRemark {
        html
      }
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(7)
    }
  },
  header: {
    marginBottom: theme.spacing(5)
  },
  caption: {
    marginTop: theme.spacing(1)
  }
}));

const Image = ({ data: { header, image, caption } }) => {
  const classes = useStyles();

  return (
    <Row className={classes.row}>
      <Grid item xs={12}>
        <RenderHtml html={header} className={classes.header} />
        <Media source={image} />
      </Grid>
    </Row>
  );
};

export default Image;
