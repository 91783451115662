import React from "react";
import Media from "./Media";
import { StaticQuery, graphql } from "gatsby";

const FileIcon = props => (
  <StaticQuery
    query={graphql`
      query FileIconQuery {
        icon: contentfulAsset(contentful_id: { eq: "015PipQnw0VtPHqzcWvQ6Q" }) {
          ...Media200
        }
        __typename
      }
    `}
    render={data => <Media source={data.icon} {...props} />}
  />
);

export default FileIcon;
