import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Media from "../../media";

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    width: 140,
    height: 100,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2.5)
    }
  },
  logo: {
    flexGrow: 1
  },
  header: {
    marginBottom: theme.spacing(2.5)
  }
}));

const SuccessStoryCard = ({ data: { logo, header, description } }) => {
  const classes = useStyles();
  return (
    <>
      {!!logo && (
        <div className={classes.logoWrapper}>
          <Media source={logo} className={classes.logo} />
        </div>
      )}
      <Typography variant="h3" className={classes.header}>
        {header}
      </Typography>
      <RenderHtml html={description} />
    </>
  );
};

export default SuccessStoryCard;
