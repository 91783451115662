import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";
import Arrow from "./arrow.svg";

export const RecruitmentProcessSectionQueryFragment = graphql`
  fragment RecruitmentProcessSection on ContentfulModuleRecruitmentProcessSection {
    sectionHeader {
      ...SectionHeader
    }
    blocks: cards {
      id
      header {
        childMarkdownRemark {
          html
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  blocksWrapper: {
    padding: 0,
    listStyleType: "none",
    [theme.breakpoints.up("md")]: {
      "& li:last-child": {
        alignSelf: "center",
        transform: "translateY(-20%)"
      }
    }
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2.5)
    },
    marginBottom: theme.spacing(5)
  },
  arrow: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      "& img": {
        width: "100%"
      }
    }
  }
}));

const RecruitmentProcessSection = ({
  data: { sectionHeader, blocks, sectionProperties }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          <SectionHeader data={sectionHeader} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={5}
            className={classes.blocksWrapper}
            component="ul"
          >
            {blocks.map((block, index) => (
              <Grid item xs={12} sm={6} md={2} key={block.id} component="li">
                <RenderHtml html={block.header} className={classes.header} />
                {index != blocks.length - 1 && !isMobile ? (
                  <Grid item xs={12} className={classes.arrow}>
                    <img src={Arrow} />
                  </Grid>
                ) : null}
                <RenderHtml html={block.text} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default RecruitmentProcessSection;
