import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import { Grid, makeStyles } from "@material-ui/core";
import Media from "../../media";
import HubspotForm from "../../hubspot-form";
import SectionHeader from "../../section-header";
import {
  isDarkBackground,
  getBackgroundStyles
} from "../../layout/theme/palette";

export const BannerWithFormQueryFragment = graphql`
  fragment BannerWithForm on ContentfulModuleBannerWithForm {
    sectionHeader {
      ...SectionHeader
    }
    media {
      ...Media1200
    }
    videoPlaceholderImage {
      ...Media1200
    }
    form {
      ...HubspotForm
    }
    backgroundColor
    isHidden
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  media: {
    height: "100%",
    width: "100%"
  },
  formWrapper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.background.creme
  },
  button: {
    width: "auto",
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4)
    }
  }
}));

const BannerWithForm = ({
  data: {
    sectionHeader,
    media,
    videoPlaceholderImage,
    form,
    backgroundColor,
    isHidden,
    sectionProperties
  }
}) => {
  const classes = useStyles();
  const backgroundStyles = getBackgroundStyles(backgroundColor);
  const darkBackground = isDarkBackground(backgroundColor);

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={5}>
          {!!sectionHeader ? (
            <Grid item xs={12}>
              <SectionHeader data={sectionHeader} />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container style={backgroundStyles} alignItems="center">
              <Grid item xs={12} lg={8}>
                <Media
                  source={media}
                  className={classes.media}
                  controls
                  poster={
                    !!videoPlaceholderImage ? videoPlaceholderImage : null
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                className={classes.formWrapper}
                style={backgroundStyles}
              >
                <HubspotForm
                  data={form}
                  buttonVariant={darkBackground ? "primaryRed" : "primaryBlue"}
                  buttonStyles={classes.button}
                  darkBackground={darkBackground}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default BannerWithForm;
