import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import SectionContainer from "../../section-container";
import { RenderHtml } from "../../../helpers";
import { Grid, makeStyles, Typography } from "@material-ui/core";

export const QuoteSectionQueryFragment = graphql`
  fragment QuoteSection on ContentfulModuleQuote {
    quote {
      childMarkdownRemark {
        html
      }
    }
    author {
      name
      position
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  quote: {
    marginBottom: theme.spacing(2),
    "& p": {
      fontStyle: "italic",
      fontSize: "1.5rem",
      [theme.breakpoints.up("sm")]: { fontSize: "2.5rem" },
      lineHeight: 1.2
    }
  },
  quoteVisible: {
    transform: "translateX(0)",
    opacity: 1,
    transition: "0.6s transform, 0.6s opacity"
  },
  quoteHidden: {
    transform: "translateX(-100px)",
    opacity: 0
  }
}));

const QuoteSection = ({ data: { quote, author, sectionProperties } }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsVisible(entry.isIntersecting);
      }
    });
    observer.observe(ref.current);
  }, []);

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid
        item
        xs={12}
        sm={7}
        ref={ref}
        className={isVisible ? classes.quoteVisible : classes.quoteHidden}
      >
        <RenderHtml html={quote} className={classes.quote} />
        <Typography variant="body1">{author.name}</Typography>
        <Typography variant="body1">{author.position}</Typography>
      </Grid>
    </SectionContainer>
  );
};

export default QuoteSection;
