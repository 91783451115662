import { graphql } from "gatsby";
import React from "react";
import { Row } from "../../grid";
import CardPerson from "../../card-person";
import { Grid } from "@material-ui/core";

export const ContactPeopleSectionQueryFragment = graphql`
  fragment ContactPeopleSection on ContentfulModuleContactPeopleSection {
    people {
      id
      name
      position
      picture {
        ...Media700
      }
      purpose
      phone
      email
    }
    isHidden
    __typename
  }
`;

const ContactPeopleSection = ({ data: { people, isHidden } }) => {
  if (isHidden) {
    return <></>;
  } else {
    return (
      <Row>
        <Grid container spacing={5}>
          {people.map(person => (
            <Grid item xs={12} sm={6} md={4} key={person.id}>
              <CardPerson
                picture={person.picture}
                name={person.name}
                purpose={person.purpose}
                position={person.position}
                phone={person.phone}
                email={person.email}
              />
            </Grid>
          ))}
        </Grid>
      </Row>
    );
  }
};

export default ContactPeopleSection;
