import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import SectionContainer from "../../section-container";
import TagsFilterSection from "../../tags-filter-section/_components/tags-filter-section";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import JobAdvertisement from "./job-advertisement";
import { isDarkBackground } from "../../layout/theme/palette";
import getNumberOfColumns from "../../../helpers/get-number-of-columns";
import {
  onTagsSelected,
  onDefaultTagSelected
} from "../../../helpers/tags-filter";
import scrollTo from "gatsby-plugin-smoothscroll";
import classNames from "classnames";
import axios from "axios";

export const JobAdvertisementsSectionQueryFragment = graphql`
  fragment JobAdvertisementsSection on ContentfulModuleJobAdvertisementsSection {
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.blue
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      rowGap: theme.spacing(3)
    },
    rowGap: theme.spacing(12)
  },
  text: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      margin: "0 auto"
    },
    ...theme.typography.h2
  },
  whiteText: {
    color: theme.palette.text.secondary
  },
  circularProgressWrapper: {
    margin: "0 auto"
  },
  circularProgress: {
    color: theme.palette.noa_primary_2
  }
}));

const JobAdvertisementsSection = ({
  data: { sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  const defaultTag = { id: "all jobs", name: "all jobs" };
  const [queryTag, setQueryTag] = useState(defaultTag.name);
  const [jobIdList, setJobIdList] = useState([]);
  const [jobAdvertisementsList, setJobAdvertisementsList] = useState([]);
  const [jobListEmpty, setJobListEmpty] = useState(false);

  const tagsList = useRef([]);
  const columns = getNumberOfColumns(jobAdvertisementsList.length);

  const darkBackground = sectionProperties?.backgroundColor?.color
    ? isDarkBackground(sectionProperties?.backgroundColor?.color)
    : true;

  const handleTagSelected = (e, setQueryTag) => {
    scrollTo("#job-advertisements-list");
    onTagsSelected(e, setQueryTag);
  };

  const handleDefaultTagSelected = (e, setQueryTag) => {
    scrollTo("#job-advertisements-list");
    onDefaultTagSelected(e, setQueryTag);
  };

  const filterByTag = (item, queryTag) => {
    if (queryTag == null) return true;
    if (item.tag == null || item.tag.length === 0) return false;
    if (item.tag === queryTag) return item;
  };

  const jobAdvertisementsFilteredByTag = jobAdvertisementsList.filter(item => {
    if (queryTag == defaultTag.name) {
      return jobAdvertisementsList;
    } else {
      return filterByTag(item, queryTag);
    }
  });

  useEffect(() => {
    let isMounted = true;
    axios({
      method: "get",
      url: "https://api.teamtailor.com/v1/jobs",
      headers: {
        Authorization: `Token token=${process.env.TEAMTAILOR_API_KEY}`,
        "X-Api-Version": "20210218"
      }
    })
      .then(response => {
        let ids = [];
        for (const item of response.data.data) {
          ids.push(item.id);
        }

        if (isMounted) {
          if (ids.length === 0) {
            setJobListEmpty(true);
          } else setJobIdList(ids);
        }
      })
      .catch(() => {
        setJobListEmpty(true);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    let promises = [];
    for (const item of jobIdList) {
      promises.push(
        axios({
          method: "get",
          url: `https://api.teamtailor.com/v1/jobs/${item}?include=department,locations`,
          headers: {
            Authorization: `Token token=${process.env.TEAMTAILOR_API_KEY}`,
            "X-Api-Version": "20210218"
          }
        })
          .then(response => {
            let tagName = "";
            let location = "";

            if (response.data.included) {
              for (const item of response.data.included) {
                if (item.type === "departments") {
                  tagName =
                    item.attributes.name.slice(0, 1).toLowerCase() +
                    item.attributes.name.slice(1);
                  const tagAddedToTagList = tagsList.current.some(
                    item => item.name === tagName
                  );
                  if (!tagAddedToTagList) {
                    tagsList.current.push({ id: tagName, name: tagName });
                  }
                }
                if (item.type === "locations") {
                  location = item.attributes.city;
                }
              }
            }
            return {
              data: response.data.data,
              tag: tagName,
              location: location
            };
          })
          .catch(err => {
            console.log(err);
          })
      );
    }
    Promise.all(promises).then(response => {
      if (isMounted) {
        setJobAdvertisementsList(response);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [jobIdList]);

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        className={classes.container}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            {tagsList.current.length > 0 ? (
              <TagsFilterSection
                defaultTag={defaultTag}
                tags={tagsList.current}
                onDefaultTagSelected={() =>
                  handleDefaultTagSelected(setQueryTag)
                }
                onTagsSelected={e => handleTagSelected(e, setQueryTag)}
                selectedTagName={queryTag ? queryTag : defaultTag.name}
                fullWidth
                darkBackground={!!darkBackground}
                isCasesPage={false}
              ></TagsFilterSection>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={5}
              className={classes.list}
              id="job-advertisements-list"
            >
              {jobAdvertisementsFilteredByTag.length > 0 ? (
                jobAdvertisementsFilteredByTag.map(jobAdvertisement => {
                  return (
                    <Grid
                      item
                      key={jobAdvertisement.data.id}
                      xs={12}
                      sm={6}
                      md={columns}
                    >
                      <JobAdvertisement
                        data={jobAdvertisement}
                        darkBackground={!!darkBackground}
                      />
                    </Grid>
                  );
                })
              ) : !!jobListEmpty ? (
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.text,
                    !!darkBackground ? classes.whiteText : null
                  )}
                >
                  Sorry, we did not find any offers at this time.
                </Typography>
              ) : (
                <Grid item className={classes.circularProgressWrapper}>
                  <CircularProgress
                    classes={{
                      root: classes.circularProgress
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default JobAdvertisementsSection;
