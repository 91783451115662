import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import CrossIcon from "./cross-icon";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  button: {
    position: "relative",
    background: theme.palette.background.black,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 1.5)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.up(1600)]: {
      padding: theme.spacing(5, 3)
    },
    textDecoration: "none",
    border: "none",
    textAlign: "left",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    position: "relative",
    "&:hover $animatedBackground": {
      transform: "scale(1)"
    },
    "&:hover $crossIcon": {
      backgroundColor: theme.palette.background.black
    },
    "&:hover $header": {
      color: theme.palette.text.black
    }
  },
  buttonActive: {
    backgroundColor: theme.palette.background.white
  },
  animatedBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    transform: "scaleX(0)",
    background: theme.palette.background.white,
    transformOrigin: "left center",
    transition: "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s"
  },
  header: {
    marginRight: theme.spacing(7),
    color: theme.palette.text.secondary,
    zIndex: 1
  },
  headerActive: {
    color: theme.palette.text.black
  },
  crossIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1.5)
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3)
    }
  },
  crossIconActive: {
    backgroundColor: theme.palette.background.black
  },
  crossIconWrapper: {
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem"
    },
    "& span": {
      height: "0.1em",
      width: "0.8em"
    }
  }
}));

const AccordionItemHeaderWithAnimatedBackground = ({
  header,
  onClick,
  isActive
}) => {
  const classes = useStyles();

  return (
    <button
      onClick={onClick}
      className={classNames(
        classes.button,
        !!isActive ? classes.buttonActive : null
      )}
    >
      <div className={classes.animatedBackground}></div>
      <Typography
        variant="h3"
        className={classNames(
          classes.header,
          !!isActive ? classes.headerActive : null
        )}
      >
        {header}
      </Typography>
      <span className={classes.crossIconWrapper}>
        <CrossIcon
          isActive={isActive}
          className={classNames(
            classes.crossIcon,
            !!isActive ? classes.crossIconActive : null
          )}
        />
      </span>
    </button>
  );
};

export default AccordionItemHeaderWithAnimatedBackground;
