import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  whiteText: {
    color: theme.palette.text.secondary
  },
  tagButton: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    padding: 0,
    margin: 0,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  selectedTag: {
    color: theme.palette.text.link
  }
}));

const Tag = ({ onClick, tag, isSelected, darkBackground }) => {
  const classes = useStyles();

  return (
    <button
      onClick={() => {
        onClick(tag);
      }}
      className={classNames(
        classes.tagButton,
        isSelected
          ? classes.selectedTag
          : !!darkBackground
          ? classes.whiteText
          : null
      )}
    >
      <Typography variant="body1" className="category">
        #{tag.name}
      </Typography>
    </button>
  );
};

export default Tag;
