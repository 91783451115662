import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import TagsListSection from "../../tags-list-section";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import Media from "../../media";
import truncateText from "../../../helpers/truncate-text";
import { isDarkBackground } from "../../layout/theme/palette";
import classNames from "classnames";

export const TwoColumnsAlternateQueryFragment = graphql`
  fragment TwoColumnsAlternate on ContentfulModuleTwoColumnsAlternate {
    sectionHeader {
      ...SectionHeader
    }
    items {
      ... on ContentfulPageCase {
        id
        name
        slug
        tags {
          id
          name
        }
        teaserHeader
        teaserSubheader {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          ...Media900
        }
        __typename
      }
      ... on ContentfulBlockCard {
        cardHeader: header {
          childMarkdownRemark {
            html
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          ...Media900
        }
        cta {
          ...Cta
        }
        __typename
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(0, 2.5)
    },
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 6.25),
      marginBottom: theme.spacing(20)
    }
  },
  image: {
    aspectRatio: "16/9"
  },
  header: {
    marginBottom: theme.spacing(1.25)
  },
  subheader: {
    marginBottom: theme.spacing(5)
  },
  tagsListSection: {
    marginBottom: theme.spacing(2.5)
  },
  lightFontColor: {
    "& a": {
      color: theme.palette.text.secondary,
      "&:hover, &:focus": {
        color: theme.palette.text.secondary,
        textDecoration: "underline"
      }
    }
  }
}));

const TwoColumnsAlternate = ({
  data: { sectionHeader, items, sectionProperties }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const darkBackground =
    !!sectionProperties.backgroundColor &&
    isDarkBackground(sectionProperties.backgroundColor.color);

  return (
    <>
      <SectionContainer
        sectionProperties={sectionProperties}
        fullWidth={!isMobile}
      >
        <Grid item>
          <Grid container>
            <Grid item xs={12} className={classes.sectionHeader}>
              <SectionHeader
                data={sectionHeader}
                darkBackground={darkBackground}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={10}>
                {items.map((item, index) => {
                  if (item.__typename === "ContentfulPageCase") {
                    return (
                      <Grid
                        item
                        container
                        spacing={8}
                        key={item.id}
                        justifyContent={
                          index % 2 === 0 ? "flex-end" : "flex-start"
                        }
                      >
                        {(!!isMobile || index % 2 !== 0) && (
                          <Grid item xs={12} sm={6}>
                            <Media
                              source={item.teaserImage}
                              className={classes.image}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={5} md={4}>
                          <Typography variant="h3" className={classes.header}>
                            {item.teaserHeader}
                          </Typography>
                          <TagsListSection
                            tags={item.tags}
                            linkTo="cases"
                            className={classNames(
                              classes.tagsListSection,
                              !!darkBackground && classes.lightFontColor
                            )}
                          />
                          <RenderHtml
                            html={truncateText(
                              item.teaserSubheader.childMarkdownRemark.html,
                              190,
                              true
                            )}
                            className={classes.subheader}
                          />
                          <Button
                            variant={
                              !!darkBackground
                                ? "secondaryWhite"
                                : "secondaryBlue"
                            }
                            href={`/cases/${item.slug}`}
                          >
                            See more
                          </Button>
                        </Grid>
                        {!isMobile && index % 2 === 0 && (
                          <Grid item xs={12} sm={6}>
                            <Media
                              source={item.teaserImage}
                              className={classes.teaserImage}
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  } else if (item.__typename === "ContentfulBlockCard") {
                    return (
                      <Grid
                        item
                        container
                        spacing={8}
                        key={item.id}
                        justifyContent={
                          index % 2 === 0 ? "flex-end" : "flex-start"
                        }
                      >
                        {(!!isMobile || index % 2 !== 0) && (
                          <Grid item xs={12} sm={6}>
                            <Media
                              source={item.image}
                              className={classes.image}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={5} md={4}>
                          <RenderHtml
                            html={item.cardHeader}
                            className={classes.header}
                          />
                          <RenderHtml
                            html={item.text}
                            className={classes.subheader}
                          />
                          {!!item.cta ? (
                            <Button
                              variant={
                                !!darkBackground
                                  ? "secondaryWhite"
                                  : "secondaryBlue"
                              }
                              href={item.cta.url}
                            >
                              {item.cta.text}
                            </Button>
                          ) : null}
                        </Grid>
                        {!isMobile && index % 2 === 0 && (
                          <Grid item xs={12} sm={6}>
                            <Media
                              source={item.image}
                              className={classes.image}
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    </>
  );
};

export default TwoColumnsAlternate;
