import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Link } from "gatsby";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  tagsSection: {
    padding: 0,
    margin: 0,
    listStyleType: "none"
  },
  tag: {
    paddingRight: theme.spacing(1.25),
    "& a": {
      color: theme.palette.text.primary,
      borderBottom: "none",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.primary,
        textDecoration: "underline"
      },
      "&:focus": {
        color: theme.palette.text.primary,
        textDecoration: "none"
      },
      "&:focus:hover": {
        textDecoration: "underline"
      }
    }
  }
}));

const TagsListSection = ({ tags, linkTo, className }) => {
  const classes = useStyles();

  switch (linkTo) {
    case "cases": {
      return (
        <Grid
          container
          component="ul"
          className={classNames(classes.tagsSection, className)}
        >
          {!!tags
            ? tags.map(tag => {
                return (
                  <Typography
                    component="li"
                    variant="body1"
                    className={classes.tag}
                    key={tag.id}
                  >
                    <Link
                      to={`/cases/`}
                      state={{ initialTagName: tag.name }}
                      className="category"
                    >
                      #{tag.name}
                    </Link>
                  </Typography>
                );
              })
            : null}
        </Grid>
      );
    }
    case "blog": {
      return (
        <Grid
          component="ul"
          container
          className={classNames(classes.tagsSection, className)}
        >
          {!!tags
            ? tags.map(tag => {
                return (
                  <Typography
                    component="li"
                    variant="body1"
                    className={classes.tag}
                    key={tag.id}
                  >
                    <Link
                      to={`/blog/`}
                      state={{ initialTagName: tag.name }}
                      className="category"
                    >
                      #{tag.name}
                    </Link>
                  </Typography>
                );
              })
            : null}
        </Grid>
      );
    }
  }
};

export default TagsListSection;
