import React from "react";
import Hero from "../components/hero";
import TwoColumns from "../components/two-columns";
import TwoColumnsContainer from "../components/two-columns-container";
import FullWidthImage from "../components/full-width-image";
import { ContactForm } from "../components/contact-form";
import Image from "../components/image";
import Metadata from "../components/metadata";
import ArticlePageSection from "./../components/article";
import Blog, { BlogPanel } from "../components/blog";
import Cases, { CasesPanel } from "../components/cases";
import { BlogArticleTags } from "../components/blog-article";
import BannerWithBackgroundImage from "../components/banner-with-background-image";
import HomepageHeroSection from "../components/homepage-hero-section";
import HeaderWithLogo from "../components/homepage-hero-section/_components/header-with-logo";
import HeaderWithButton from "../components/homepage-hero-section/_components/header-with-button";
import TechnologyExpertiseSection from "../components/technology-expertise-section";
import ClientsSection from "../components/clients-section";
import ContactUsSection from "../components/contact-us-section";
import PageHeroSection from "../components/page-hero-section";
import CardsSection from "../components/cards-section";
import VideoSection from "../components/video-section";
import BannerSection from "../components/banner-section";
import SimpleTextSection from "../components/simple-text-section";
import ButtonSection from "../components/button-section";
import PeopleSection from "../components/people-section";
import ArticlePageHeroSection from "../components/article-page-hero-section";
import ListSection from "../components/list-section";
import ContactBanner from "../components/contact-banner";
import ContactBannerSection from "../components/contact-banner-section";
import Subnavigation from "../components/subnavigation";
import CardsSectionSimple from "../components/cards-section-simple";
import ContactPeopleSection from "../components/contact-people-section";
import TechnologyCardsSection from "../components/technology-cards-section";
import AccordionSection from "../components/accordion-section";
import StepsSection from "../components/steps-section";
import SuccessStoriesSection from "../components/success-stories-section";
import ContactFormsSection from "../components/contact-form";
import CountdownTimerSection from "../components/countdown-timer-section";
import HubspotForm from "../components/hubspot-form";
import Banner from "../components/banner";
import PageHeroSectionWithImageAtTheTop from "../components/page-hero-section-with-image-at-the-top";
import BadgeSection from "../components/badge_section";
import TechnologyHighlights from "../components/technology-highlights";
import PageHeroSectionWithMediaBackground from "../components/page-hero-section-with-media-background";
import TwoColumnsAlternate from "../components/two-columns-alternate";
import ServiceCardsSection from "../components/service-cards-section";
import FullWidthBanner from "../components/full-width-banner";
import GatedContentSection from "../components/gated-content-section";
import BannerWithForm from "../components/banner-with-form";
import Popup from "../components/popup";
import HubspotFormSection from "../components/hubspot-form-section";
import RecruitmentProcessSection from "../components/recruitment-process-section";
import JobAdvertisementsSection from "../components/job-advertisements-section";
import QuoteSection from "../components/quote-section";
import LinksSection from "../components/links-section";

//Without type specified in query. Module comes with single "__typename" property. All the valid modules will have more properties
const isEmpty = data => {
  const keys = Object.keys(data);
  if (keys.length === 1) {
    return true;
  }

  return false;
};

const renderModule = (
  module,
  index,
  location,
  responsiveStatus,
  pageContext
) => {
  if (isEmpty(module)) {
    return <React.Fragment key={`missing-${index}`}></React.Fragment>;
  }

  if (module.isHidden) {
    return <React.Fragment key={`hidden-${index}`}></React.Fragment>;
  }

  const component = getComponentByType(module.__typename);
  return componentWithData(
    component,
    module,
    index,
    location,
    responsiveStatus,
    pageContext
  );
};

const getComponentByType = type => {
  switch (type) {
    case "ContentfulModuleHeroSection":
      return Hero;
    case "ContentfulModulePageHeroSection":
      return PageHeroSection;
    case "ContentfulModuleHomepageHeroSection":
      return HomepageHeroSection;
    case "ContentfulModuleHeaderWithLogo":
      return HeaderWithLogo;
    case "ContentfulModuleHeaderWithButton":
      return HeaderWithButton;
    case "ContentfulModuleCardsSection":
      return CardsSection;
    case "ContentfulModuleTechnologyExpertiseSection":
      return TechnologyExpertiseSection;
    case "ContentfulModuleClientsSection":
      return ClientsSection;
    case "ContentfulModuleContactUsSection":
      return ContactUsSection;
    case "ContentfulModuleTwoColumns":
      return TwoColumns;
    case "ContentfulModuleTwoColumnsContainer":
      return TwoColumnsContainer;
    case "ContentfulModuleVideoSection":
      return VideoSection;
    case "ContentfulModuleFullWidthImage":
      return FullWidthImage;
    case "ContentfulModuleCasesSection":
      return Cases;
    case "ContentfulModuleBlogSection":
      return Blog;
    case "ContentfulModuleButtonSection":
      return ButtonSection;
    case "ContentfulModuleImage":
      return Image;
    case "customArticlePageSection":
      return ArticlePageSection;
    case "ContentfulModuleBlogPanel":
      return BlogPanel;
    case "ContentfulModuleCasesPanel":
      return CasesPanel;
    case "ContentfulModuleContactForm":
      return ContactForm;
    case "ContentfulModuleBlogArticleTags":
      return BlogArticleTags;
    case "ContentfulModuleBannerWithBackgroundImage":
      return BannerWithBackgroundImage;
    case "ContentfulModuleBannerSection":
      return BannerSection;
    case "ContentfulModuleSimpleTextSection":
      return SimpleTextSection;
    case "ContentfulModulePeopleSection":
      return PeopleSection;
    case "ContentfulModuleArticlePageHeroSection":
      return ArticlePageHeroSection;
    case "ContentfulModuleListSection":
      return ListSection;
    case "ContentfulBlockContactBanner":
      return ContactBanner;
    case "ContentfulModuleContactBannerSection":
      return ContactBannerSection;
    case "ContentfulModuleSubnavigation":
      return Subnavigation;
    case "ContentfulModuleCardsSectionSimple":
      return CardsSectionSimple;
    case "ContentfulModuleContactPeopleSection":
      return ContactPeopleSection;
    case "ContentfulModuleTechnologyCardsSection":
      return TechnologyCardsSection;
    case "ContentfulModuleAccordionSection":
      return AccordionSection;
    case "ContentfulModuleStepsSection":
      return StepsSection;
    case "ContentfulModuleSuccessStoriesSection":
      return SuccessStoriesSection;
    case "ContentfulModuleContactFormsSection":
      return ContactFormsSection;
    case "ContentfulModuleCountdownTimerSection":
      return CountdownTimerSection;
    case "ContentfulModulePageHeroSectionWithImageAtTheTop":
      return PageHeroSectionWithImageAtTheTop;
    case "ContentfulBlockHubspotForm":
      return HubspotForm;
    case "ContentfulModuleBanner":
      return Banner;
    case "ContentfulModuleBadgeSection":
      return BadgeSection;
    case "ContentfulModuleTechnologyHighlights":
      return TechnologyHighlights;
    case "ContentfulModulePageHeroSectionWithMediaBackground":
      return PageHeroSectionWithMediaBackground;
    case "ContentfulModuleTwoColumnsAlternate":
      return TwoColumnsAlternate;
    case "ContentfulModuleServiceCardsSection":
      return ServiceCardsSection;
    case "ContentfulModuleFullWidthBanner":
      return FullWidthBanner;
    case "ContentfulModuleGatedContentSection":
      return GatedContentSection;
    case "ContentfulModuleBannerWithForm":
      return BannerWithForm;
    case "ContentfulBlockPopup":
      return Popup;
    case "ContentfulModuleHubspotFormSection":
      return HubspotFormSection;
    case "ContentfulModuleRecruitmentProcessSection":
      return RecruitmentProcessSection;
    case "ContentfulModuleJobAdvertisementsSection":
      return JobAdvertisementsSection;
    case "ContentfulModuleQuote":
      return QuoteSection;
    case "ContentfulModuleLinksSection":
      return LinksSection;
    default:
      return null;
  }
};

const componentWithData = (
  component,
  module,
  index,
  location,
  responsiveStatus,
  pageContext
) => {
  if (!component) return null;
  const ComponentTag = component;
  return (
    <ComponentTag
      data={module}
      key={`${module.name}-${index}`}
      location={location}
      responsiveStatus={responsiveStatus}
      pageContext={pageContext}
    />
  );
};

const Page = ({ pageData, children }) => {
  if (!pageData) {
    return <Metadata />;
  }

  return (
    <React.Fragment>
      <Metadata
        metadata={pageData.customPageMetaData}
        pageTitle={pageData.name}
      />
      {children}
    </React.Fragment>
  );
};

export { renderModule, Page };
