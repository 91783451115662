import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import palette from "../../layout/theme/palette";
import Media from "../../media";

export const HeaderWithButtonQueryFragment = graphql`
  fragment HeaderWithButton on ContentfulModuleHeaderWithButton {
    header {
      childMarkdownRemark {
        html
      }
    }
    image {
      ...Media1000
    }
    cta {
      ...Cta
    }
    backgroundColour
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  imageWrapper: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxHeight: 300
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: 400
    }
  },
  image: {
    height: "100%"
  },
  headerWrapper: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5)
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto"
  },
  header: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  }
}));

const HeaderWithButton = ({ data }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: palette.background[data.backgroundColour] }}
    >
      {!!data.image ? (
        <div className={classes.imageWrapper}>
          <Media source={data.image} className={classes.image} />
        </div>
      ) : null}
      <div className={classes.headerWrapper}>
        {!!data.header ? (
          <RenderHtml html={data.header} className={classes.header} />
        ) : null}
        {!!data.cta ? (
          <Button
            href={data.cta.url}
            className={classNames(
              "gtm-read-more-cta",
              !!data.cta.className && data.cta.className
            )}
          >
            {data.cta.text}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default HeaderWithButton;
