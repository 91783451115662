import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Media from "../../media";
import classNames from "classnames";

const mobileSize = 48;
const desktopSize = 64;

const useStyles = makeStyles(theme => ({
  iconsContainer: {
    marginBottom: theme.spacing(2),
    height: mobileSize,
    [theme.breakpoints.up("md")]: {
      height: desktopSize,
      marginBottom: theme.spacing(3)
    }
  },
  icons: {
    height: "100%",
    width: mobileSize,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: desktopSize,
      marginRight: theme.spacing(3)
    },
    "& img": {
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      verticalAlign: "middle"
    }
  },
  singleIcon: {
    width: "auto",
    display: "block",
    marginBottom: theme.spacing(2)
  },
  iconSmall: {
    height: 24
  },
  iconBig: {
    height: 58,
    marginBottom: theme.spacing(5.37)
  },
  verticalHelper: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle"
  },
  centeredContent: {
    justifyContent: "center"
  }
}));

const SimpleTextBlockIcons = ({ icons, icon, iconSize, centered }) => {
  icons = icons || [];
  const classes = useStyles();

  const iconSizeClass =
    iconSize === "Big" ? classes.iconBig : classes.iconSmall;

  return (
    <>
      {icons.length > 0 && (
        <Grid
          container
          className={classNames(
            classes.iconsContainer,
            !!centered && classes.centeredContent
          )}
        >
          {icons.map(icon => (
            <div className={classes.icons} key={icon.id}>
              <span
                className={classes.verticalHelper}
                key={`${icon.id}-helper`}
              />
              <img
                src={icon.file.url}
                alt={icon.title}
                loading="auto"
                key={`${icon.id}-img`}
              />
            </div>
          ))}
        </Grid>
      )}

      {icon && (
        <>
          <Media
            source={icon}
            className={classNames(
              classes.singleIcon,
              "singleIcon",
              iconSizeClass
            )}
          />
        </>
      )}
    </>
  );
};

export default SimpleTextBlockIcons;
