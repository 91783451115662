import Divider from "../../divider";
import React from "react";
import Arrow from "./arrow.svg";
import Pin from "./pin.svg";
import { Typography, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  whiteText: {
    color: theme.palette.text.secondary
  },
  divider: {
    marginBottom: theme.spacing(2.5),
    opacity: 0.3
  },
  flexContainer: {
    display: "flex",
    marginBottom: theme.spacing(1.25),
    "& img": {
      marginRight: theme.spacing(1)
    }
  },
  jobTitle: {
    marginBottom: theme.spacing(3.75),
    ...theme.typography.h3
  },
  jobSalary: {
    marginBottom: theme.spacing(3.75)
  },
  arrow: {
    cursor: "pointer"
  }
}));

const JobAdvertisement = ({ data: { data, location }, darkBackground }) => {
  const theme = useTheme();
  const classes = useStyles();
  const title = data.attributes.title;
  const remoteStatus =
    data.attributes["remote-status"].slice(0, 1).toUpperCase() +
    data.attributes["remote-status"].slice(1);
  const jobPageUrl = data.links["careersite-job-url"];
  const jobSalary =
    !!data.attributes["min-salary"] && !!data.attributes["max-salary"]
      ? `UoP ${data.attributes["min-salary"]} - ${data.attributes["max-salary"]} PLN/GROSS`
      : null;

  return (
    <div className={!!darkBackground ? classes.whiteText : null}>
      <Divider
        color={theme.palette.background.white}
        height={1}
        className={classes.divider}
      />
      <div className={classes.flexContainer}>
        <img src={Pin} />
        <Typography variant="body1">
          {location}
          {remoteStatus != "None" ? `/${remoteStatus} remote` : null}
        </Typography>
      </div>
      <Typography variant="body1" className={classes.jobTitle}>
        {title}
      </Typography>
      {!!jobSalary ? (
        <Typography variant="body1" className={classes.jobSalary}>
          {jobSalary}
        </Typography>
      ) : null}
      <a href={jobPageUrl}>
        <img src={Arrow} className={classes.arrow} />
      </a>
    </div>
  );
};

export default JobAdvertisement;
