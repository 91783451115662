import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import Cards from "./cards";
import SectionContainer from "../../section-container";

export const CardsSectionQueryFragment = graphql`
  fragment CardsSection on ContentfulModuleCardsSection {
    name
    isHidden
    sectionHeader {
      ...SectionHeader
    }
    cards {
      id
      image {
        ...Media900
      }
      logo {
        ...Media200
      }
      cardHeader: header {
        childMarkdownRemark {
          html
        }
      }
      subheader
      divider
      text {
        childMarkdownRemark {
          html
        }
      }
      cta {
        ...Cta
      }
    }
    sectionHeaderAndCardsAreBelowEachOther
    columns
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  margin: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(7.5)
    }
  }
}));

const CardsSection = ({
  data: {
    sectionHeader,
    cards,
    sectionHeaderAndCardsAreBelowEachOther,
    columns,
    sectionProperties,
    isHidden
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={isMobile ? 8 : 5}>
          {!!sectionHeader && (
            <Grid
              item
              xs={12}
              sm={cards.length <= 1 ? 4 : 12}
              md={!!sectionHeaderAndCardsAreBelowEachOther ? 6 : 4}
              className={classes.margin}
            >
              <SectionHeader data={sectionHeader} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={cards.length <= 1 ? 8 : 12}
            md={
              !!sectionHeader && !sectionHeaderAndCardsAreBelowEachOther
                ? 8
                : 12
            }
          >
            <Cards
              cards={cards}
              columns={columns}
              sectionHeader={sectionHeader}
            />
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default CardsSection;
