import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  circleWrapper: {
    position: "absolute",
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    top: "50%",
    right: theme.spacing(2.8),
    transform: "translate(0, 10%)",
    background: theme.palette.ignite_primary_1,
    borderRadius: "50%",
    marginLeft: theme.spacing(1.25)
  },
  rectangle: {
    position: "absolute",
    width: 1,
    height: theme.spacing(1),
    top: "50%",
    left: "50%",
    background: theme.palette.background.white
  },
  rotateLeft: {
    transform: "translate(-50%, -50%) rotate(45deg)"
  },
  rotateRight: {
    transform: "translate(-50%, -50%) rotate(-45deg)"
  }
}));

const CancelIcon = ({ visible }) => {
  const classes = useStyles();

  return (
    <>
      {visible && (
        <div className={classes.circleWrapper}>
          <div
            className={classNames(classes.rectangle, classes.rotateLeft)}
          ></div>
          <div
            className={classNames(classes.rectangle, classes.rotateRight)}
          ></div>
        </div>
      )}
    </>
  );
};

export default CancelIcon;
