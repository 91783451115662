import {
  Button,
  CircularProgress,
  FormHelperText,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import Attachment from "./contact-form-attachment";

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%"
  },
  button: {
    border: `2px solid ${theme.palette.ignite_primary_1}`,
    borderRadius: 0,
    padding: theme.spacing(0.625, 2.5),
    "&:hover": {
      color: theme.palette.background.white,
      background: theme.palette.background.blue
    }
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem"
    },
    fontWeight: "bold",
    paddingTop: theme.spacing(0.75)
  },
  attachmentWrapper: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  progress: {
    color: theme.palette.ignite_primary_1
  },
  fileUploadErrorMessage: {
    color: theme.palette.ignite_primary_2,
    position: "absolute",
    bottom: "-20px",
    fontSize: "0.8rem"
  }
}));

const FileAttachmentButton = ({
  label,
  files,
  setFiles,
  fileUploading,
  setFileUploading,
  attachmentUploadUrl,
  apiKey
}) => {
  const [fileUploadFailure, setFileUploadFailure] = useState(false);

  const classes = useStyles();

  const handleChange = event => {
    const bodyFormData = new FormData();
    const originalFileName = event.target.files[0].name;
    const newFileName = Date.now();

    bodyFormData.append("file", event.target.files[0], newFileName);

    setFileUploading(true);
    setFileUploadFailure(false);
    axios({
      method: "post",
      url: attachmentUploadUrl,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Ocp-Apim-Subscription-Key": apiKey
      }
    })
      .then(function(response) {
        let file = {
          name: originalFileName,
          url: response.data.url
        };
        setFiles([...files, file]);
      })
      .catch(function(response) {
        setFileUploadFailure(true);
      })
      .finally(function(response) {
        setFileUploading(false);
      });

    event.target.value = null;
  };

  function handleRemoveFile(index) {
    setFiles(files.filter((_, i) => i !== index));
  }

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          component="label"
          className={classes.button}
          disabled={fileUploading}
          disableRipple
        >
          {fileUploading ? (
            <CircularProgress className={classes.progress} size={30} />
          ) : (
            <Typography variant="body1" className={classes.label}>
              {label}
            </Typography>
          )}

          <input
            type="file"
            hidden
            onChange={handleChange}
            accept="application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/pdf, image/jpeg, application/vnd.ms-powerpoint,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/pkcs8"
          />
        </Button>
        <div>
          {files.length
            ? files.map((file, index) => (
                <div key={index} className={classes.attachmentWrapper}>
                  <Attachment
                    index={index}
                    filename={file.name}
                    handleRemove={handleRemoveFile}
                  />
                </div>
              ))
            : null}
        </div>
        {fileUploadFailure ? (
          <FormHelperText className={classes.fileUploadErrorMessage}>
            The selected file could not be uploaded
          </FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export default FileAttachmentButton;
