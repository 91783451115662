import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import SectionContainer from "../../section-container";
import CardSimple from "./card-simple";
import getNumberOfColumns from "../../../helpers/get-number-of-columns";
import { isDarkBackground } from "../../layout/theme/palette";

export const CardsSectionSimpleQueryFragment = graphql`
  fragment CardsSectionSimple on ContentfulModuleCardsSectionSimple {
    name
    sectionHeader {
      ...SectionHeader
    }
    cards {
      id
      cardHeader: header {
        childMarkdownRemark {
          html
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
      cta {
        ...Cta
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    rowGap: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      rowGap: theme.spacing(12)
    }
  }
}));

const CardsSectionSimple = ({
  data: { sectionHeader, cards, sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  const columns = getNumberOfColumns(cards.length);
  const darkBackground = isDarkBackground(
    sectionProperties.backgroundColor.color
  );

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <SectionHeader data={sectionHeader} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5} className={classes.cardsContainer}>
              {cards.map(card => (
                <Grid item xs={12} sm={6} md={columns} key={card.id}>
                  <CardSimple
                    header={card.cardHeader}
                    text={card.text}
                    cta={card.cta}
                    darkBackground={darkBackground}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default CardsSectionSimple;
