import { graphql } from "gatsby";
import React from "react";
import SectionHeader from "../../section-header";
import { Grid } from "@material-ui/core";
import StepCard from "./step-card";
import SectionContainer from "../../section-container";
import getNumberOfColumns from "../../../helpers/get-number-of-columns";

export const StepsSectionQueryFragment = graphql`
  fragment StepsSection on ContentfulModuleStepsSection {
    sectionHeader {
      ...SectionHeader
    }
    steps {
      id
      stepNumber
      header
      description {
        childMarkdownRemark {
          html
        }
      }
      cta {
        ...Cta
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const StepsSection = ({
  data: { sectionHeader, steps, sectionProperties }
}) => {
  const columns = getNumberOfColumns(steps.length);
  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={7}>
        {!!sectionHeader && (
          <Grid item xs={12}>
            <SectionHeader data={sectionHeader} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {!!steps &&
              steps.map(step => (
                <Grid item xs={12} sm={6} md={columns} key={step.id}>
                  <StepCard data={step} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default StepsSection;
