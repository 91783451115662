import { graphql, useStaticQuery } from "gatsby";

const useAllBlogArticles = () => {
  const {
    allContentfulPageBlogArticle: { edges }
  } = useStaticQuery(graphql`
    query AllBlogArticlesQuery {
      allContentfulPageBlogArticle {
        edges {
          node {
            id
            date(formatString: "MMMM D, YYYY")
            slug
            name
            tags {
              name
              id
            }
            subheader {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                timeToRead
              }
            }
            mainImage {
              ...Media600
            }
          }
        }
      }
    }
  `);
  return edges;
};

export default useAllBlogArticles;
