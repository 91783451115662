import React from "react";
import { RenderHtml } from "../../../../../helpers";
import Media from "../../../../media";
import { makeStyles, Grid } from "@material-ui/core";
import { Row } from "../../../../grid";

const useStyles = makeStyles(theme => ({
  bottomSectionRow: {
    backgroundColor: theme.palette.background.white,
    padding: theme.spacing(3, 0)
  },
  bottomSection: {
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(2)
    }
  },
  bottomSectionLogoWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  bottomSectionTextContainer: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
      alignSelf: "flex-end"
    },
    "& p": {
      fontSize: 14,
      margin: 0,
      "& a": {
        textSizeAdjust: "100%",

        color: theme.palette.text.primary,
        lineHeight: "28px",
        border: "none",
        textDecoration: "none",
        "&:hover, &:active, &:focus": {
          color: theme.palette.text.primary,
          border: "none",
          textDecoration: "underline"
        }
      }
    }
  },
  bottomSectionTextWrapper: {
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
      marginLeft: theme.spacing(3)
    }
  }
}));

const BottomSectionRow = ({
  bottomSectionLogo,
  copyrightText,
  privacyPolicyText
}) => {
  const classes = useStyles();

  return (
    <Row className={classes.bottomSectionRow} noPadding>
      <Grid container className={classes.bottomSection}>
        <Grid
          container
          item
          xs={12}
          sm={4}
          className={classes.bottomSectionLogoWrapper}
        >
          <a
            href="https://www.thenorthalliance.com/"
            target="_blank"
            rel="noopener"
          >
            <Media source={bottomSectionLogo} />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          className={classes.bottomSectionTextContainer}
        >
          <div className={classes.bottomSectionTextWrapper}>
            <RenderHtml html={copyrightText} />
          </div>
          <div className={classes.bottomSectionTextWrapper}>
            <RenderHtml html={privacyPolicyText} />
          </div>
        </Grid>
      </Grid>
    </Row>
  );
};

export default BottomSectionRow;
