import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import getCookie from "../../../helpers/get-cookie";
import CookieInfo from "../../cookie-info";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  playerWrapper: {
    position: "relative",
    aspectRatio: "16/9",
    maxHeight: "90vh",
    margin: "0 auto"
  },
  reactPlayer: {
    position: "absolute",
    top: "0",
    left: "0"
  },
  container: {
    "& #flex-container": {
      [theme.breakpoints.down("xs")]: {
        minHeight: 350
      }
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }
  },
  fullWidthContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%"
    }
  }
}));

const VideoEmbed = ({ url, fullWidthVideo }) => {
  const classes = useStyles();

  const [marketingConsent, setMarketingConsent] = useState(false);
  const [cookiebot, setCookiebot] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined") {
      const cookie = getCookie("CookieConsent");
      setMarketingConsent(!cookie ? false : cookie.includes("marketing:true"));
      setCookiebot(!!document.querySelector("#Cookiebot"));
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onAccept = () => {
        setMarketingConsent(!!Cookiebot.consent.marketing);
      };
      window.addEventListener("CookiebotOnAccept", onAccept);
      return () => {
        window.removeEventListener("CookiebotOnAccept", onAccept);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const findCookiebot = () => {
        setCookiebot(!!document.querySelector("#Cookiebot"));
      };
      window.addEventListener("load", findCookiebot);
      return () => {
        window.removeEventListener("load", findCookiebot);
      };
    }
  }, []);

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <>
      {" "}
      <div className={classes.playerWrapper} id="player-wrapper">
        {!!marketingConsent && (
          <ReactPlayer
            url={url}
            className={classes.reactPlayer}
            controls={true}
            width="100%"
            height="100%"
          />
        )}
        <CookieInfo
          cookiebot={cookiebot}
          className={`cookieconsent-optout-marketing ${classNames(
            classes.container,
            !!fullWidthVideo ? classes.fullWidthContainer : null
          )}`}
          fullWidthVideo={!!fullWidthVideo}
          cookiesToAcceptText="marketing"
        />
      </div>
    </>
  );
};

export default VideoEmbed;
