import React from "react";
import { makeStyles } from "@material-ui/core";
import MenuLink from "./menu-link";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    textDecoration: "none",
    position: "relative",
    margin: theme.spacing(0.5, 0),
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      margin: 0,
      padding: theme.spacing(2, 0, 1.25),
      paddingLeft: theme.spacing(5.5)
    },
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      fontSize: "1rem",
      lineHeight: "2.125rem",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        borderBottom: "2px solid transparent",
        transition: "border-bottom-color 200ms ease-out"
      },
      "&:hover:after": {
        borderBottomColor: theme.palette.text.primary,
        transition: "border-bottom-color 600ms ease-in"
      }
    }
  },
  linkDark: {
    color: theme.palette.text.secondary,
    "&:hover:after": {
      borderBottomColor: theme.palette.text.secondary
    }
  },
  bold: {
    fontWeight: "bold"
  },
  topLevel: {
    [theme.breakpoints.down(
      `${process.env.SITE_VERSION === "PL" ? "md" : "sm"}`
    )]: {
      padding: theme.spacing(1.25, 0),
      paddingLeft: theme.contentSizes.mobileMargin
    },
    [theme.breakpoints.only(
      `${process.env.SITE_VERSION === "PL" ? "md" : null}`
    )]: {
      paddingLeft: theme.contentSizes.desktopMargin
    },
    fontSize: "1.4rem",
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      fontSize: "1rem",
      lineHeight: "2.125rem"
    },
    height: "100%"
  },
  outlined: {
    border: `1px solid ${theme.palette.ignite_primary_1}`,
    transition: "250ms",
    "&:hover": {
      backgroundColor: theme.palette.ignite_primary_1,
      color: theme.palette.text.secondary
    },
    padding: theme.spacing(0, 3),
    textAlign: "center",
    fontSize: "1rem",
    [theme.breakpoints.down(
      `${process.env.SITE_VERSION === "PL" ? "md" : "sm"}`
    )]: {
      margin: theme.spacing(3.75, 0),
      marginLeft: theme.contentSizes.mobileMargin,
      padding: theme.spacing(0.8, 3)
    },
    [theme.breakpoints.only(
      `${process.env.SITE_VERSION === "PL" ? "md" : null}`
    )]: {
      marginLeft: theme.contentSizes.desktopMargin
    },
    width: "auto"
  },
  outlinedDark: {
    border: `1px solid ${theme.palette.background.white}`,
    "&:hover": {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.text.primary
    }
  }
}));

const MenuItemLink = ({
  item,
  onSelectMenuItem,
  bold,
  outlined,
  topLevelLink,
  topNavigationDarkTheme
}) => {
  const classes = useStyles();

  return (
    <MenuLink
      to={`${item.url}`}
      className={classNames(
        classes.link,
        bold ? classes.bold : "",
        outlined ? classes.outlined : "",
        topLevelLink ? classes.topLevel : "",
        "gtm-top-navigation-button",
        topNavigationDarkTheme ? classes.linkDark : null,
        topNavigationDarkTheme && outlined ? classes.outlinedDark : null
      )}
      onClick={onSelectMenuItem}
    >
      {item.name}
    </MenuLink>
  );
};

export default MenuItemLink;
