import { makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Divider from "../../divider";

const useStyles = makeStyles(theme => ({
  header: {
    margin: theme.spacing(2, 0)
  }
}));

const ListItem = ({ header, text, darkBackground }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div>
      <Divider
        height={1}
        color={
          !!darkBackground
            ? theme.palette.background.white
            : theme.palette.background.blue
        }
      />
      <Typography variant="h3" component="h2" className={classes.header}>
        {header}
      </Typography>
      {!!text ? <RenderHtml html={text} /> : null}
    </div>
  );
};

export default ListItem;
