import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import { RenderHtml } from "../../../helpers";
import SectionContainer from "../../section-container";

export const SimpleTextSectionQueryFragment = graphql`
  fragment SimpleTextSection on ContentfulModuleSimpleTextSection {
    name
    isHidden
    sectionHeader {
      ...SectionHeader
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
}));

const textStyles = {
  "& ul": {
    marginBottom: 0,
    marginTop: 8
  }
};

const SimpleTextSection = ({
  data: { sectionHeader, text, sectionProperties, isHidden }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={4}
            className={!sectionHeader ? classes.hiddenOnMobile : null}
          >
            {!!sectionHeader ? <SectionHeader data={sectionHeader} /> : null}
          </Grid>
          {!!text ? (
            <Grid item xs={12} sm={8} md={6}>
              <RenderHtml html={text} styles={textStyles} />
            </Grid>
          ) : null}
        </Grid>
      </SectionContainer>
    );
};

export default SimpleTextSection;
