import { makeStyles, Grid, Typography } from "@material-ui/core";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Button from "../../buttons";
import Divider from "../../divider";
import { Row } from "../../grid";

export const CountdownTimerSectionQueryFragment = graphql`
  fragment CountdownTimerSection on ContentfulModuleCountdownTimerSection {
    dateToCountdown
    sectionTitle
    cta {
      ...Cta
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginBottom: theme.spacing(2.25)
  },
  span: {
    paddingLeft: theme.spacing(1),
    fontSize: "1rem"
  },
  upperDivider: {
    marginBottom: theme.spacing(8)
  },
  bottomDivider: {
    margin: theme.spacing(8, 0, 11)
  }
}));

const CountdownTimerSection = ({
  data: { sectionTitle, dateToCountdown, cta}
}) => {
  const countdownDate = new Date(dateToCountdown).getTime();
  const [remainingTime, setRemainingTime] = useState(
    countdownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(countdownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  let remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  let remainingHours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
  let remainingMinutes = Math.floor((remainingTime / (1000 * 60)) % 60);
  let remainingSeconds = Math.floor((remainingTime / 1000) % 60);

  const classes = useStyles();

  return (
    <Row noPaddingBottom>
      <Typography variant="h3" className={classes.sectionTitle}>
        {sectionTitle}
      </Typography>
      <Divider height={1} color="#D9D9D9" className={classes.upperDivider} />
      <Grid container spacing={6} alignContent="center" justifyContent="center">
        <Grid item>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignContent="center"
          >
            <Grid item>
              <Typography variant="h2">
                {remainingDays < 10 && "0"}
                {remainingDays > 0 ? remainingDays : 0}
                <span className={classes.span}>d</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">
                {remainingHours < 10 && "0"}
                {remainingHours > 0 ? remainingHours : 0}
                <span className={classes.span}>h</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">
                {remainingMinutes < 10 && "0"}
                {remainingMinutes > 0 ? remainingMinutes : 0}
                <span className={classes.span}>m</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">
                {remainingSeconds < 10 && "0"}
                {remainingSeconds > 0 ? remainingSeconds : 0}
                <span className={classes.span}>s</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>          
          <Button variant="primaryRed" href={cta.url} className={cta.className}>
            {cta.text}
          </Button>
        </Grid>
      </Grid>
      <Divider height={1} color="#D9D9D9" className={classes.bottomDivider} />
    </Row>
  );
};

export default CountdownTimerSection;
