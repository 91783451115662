import React from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  effectContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0
  },
  effect: {
    display: "block",
    pointerEvents: "none",
    touchAction: "none",
    overflow: "hidden",
    objectFit: "cover",
    height: "100%",
    width: "100%"
  },
  effectAllDirections: {
    height: "150%"
  },
  effectSpline: {
    height: "300%"
  }
}));

const EffectStripesAllDirectionsClientSideOnlyLazy = React.lazy(() =>
  import("./effect-stripes-all-directions")
);
const EffectStripesSplineClientSideOnlyLazy = React.lazy(() =>
  import("./effect-stripes-spline")
);

export default function Effect({ effectType = 0, color, ...props }) {
  const isSSR = typeof window === "undefined";
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.effectContainer}>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          {effectType === 0 ? (
            <EffectStripesAllDirectionsClientSideOnlyLazy
              color={color}
              size={isMobile ? 60 : 110}
              gap={isMobile ? 30 : 40}
              className={classNames(
                classes.effect,
                classes.effectAllDirections
              )}
              {...props}
            />
          ) : effectType === 1 ? (
            <EffectStripesSplineClientSideOnlyLazy
              color={color}
              className={classNames(classes.effect, classes.effectSpline)}
              {...props}
            />
          ) : null}
        </React.Suspense>
      )}
    </div>
  );
}
