import { makeStyles } from "@material-ui/core";
import React from "react";
import Button from "../../buttons";

const useStyles = makeStyles(theme => ({
  link: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3rem"
    },
    fontWeight: "normal",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const SubnavigationItem = ({ item }) => {
  const classes = useStyles();

  return (
    <Button href={item.url} variant="linkBlue" className={classes.link}>
      {item.name}
    </Button>
  );
};

export default SubnavigationItem;
