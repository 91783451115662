import React from "react";
import Media from "./Media";
import { StaticQuery, graphql } from "gatsby";

const MoreIcon = props => (
  <StaticQuery
    query={graphql`
      query MoreIconQuery {
        icon: contentfulAsset(contentful_id: { eq: "iUhfHM7Hg2S99F2A5ft0c" }) {
          ...Media200
        }
        __typename
      }
    `}
    render={data => <Media source={data.icon} {...props} />}
  />
);

export default MoreIcon;
