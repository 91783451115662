import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";

const useStyles = makeStyles(theme => ({
  counter: {
    color: theme.palette.ignite_primary_2
  }
}));

const Counter = ({ date, description }) => {
  const classes = useStyles();
  const countdownDate = new Date(date).getTime();
  const [remainingTime, setRemainingTime] = useState(
    countdownDate - new Date().getTime()
  );
  let remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(countdownDate - new Date().getTime());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className={classes.counter}>
      {remainingDays} {description}
    </span>
  );
};

export default Counter;
