import React from "react";
import { RenderHtml } from "../../../helpers";
import { makeStyles, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Media from "../../media";
import ContactObfusscator from "../../contact-obfusscator";
import noWhiteSpaces from "../../../helpers/no-white-spaces";
import classNames from "classnames";
import LinkedInLogo from "../_assets/linkedIn_logo.svg";
import LinkedInLogoWhite from "../_assets/linkedIn_logo_white.svg";
import WebsiteIcon from "../_assets/website_icon.svg";
import WebsiteIconWhite from "../_assets/website_icon_white.svg";
import { graphql } from "gatsby";

export const ContactPersonQuery = graphql`
  fragment ContactPerson on ContentfulBlockContactPerson {
    id
    name
    email
    phone
    position
    picture {
      ...Media600
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    linkedIn
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "column"
  },
  purpose: {
    marginBottom: theme.spacing(5)
  },
  image: {
    paddingTop: "60%",
    maxWidth: "100%",
    marginBottom: theme.spacing(3.75)
  },
  imageVertical: {
    paddingTop: "128%",
    maxWidth: "100%",
    marginBottom: theme.spacing(3.75)
  },
  name: {
    marginBottom: theme.spacing(1.25)
  },
  position: {
    marginBottom: theme.spacing(3.75)
  },
  description: {
    marginBottom: theme.spacing(3)
  },
  contact: {
    marginBottom: theme.spacing(1.25),
    "& a": {
      display: "flex",
      borderBottom: "none",
      textDecoration: "none",
      "&:hover, &:focus": {
        textDecoration: "underline",
        borderBottom: "none"
      },
      "& img": {
        paddingRight: theme.spacing(1.5)
      }
    }
  },
  lightFontContact: {
    "& a": {
      color: theme.palette.text.secondary,
      "&:hover, &:focus": {
        color: theme.palette.text.secondary
      }
    }
  },
  darkFontContact: {
    "& a": {
      color: theme.palette.text.primary,
      "&:hover, &:focus": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    height: 28,
    width: 40
  },
  email: {
    marginBottom: theme.spacing(3)
  }
}));

const CardPerson = ({
  picture,
  name,
  purpose,
  position,
  description,
  phone,
  email,
  linkedIn,
  cta,
  fieldsToShow,
  darkBackground,
  imageOrientation = "horizontal"
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {!!purpose &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showPurpose)) ? (
        <Typography variant="h3" className={classes.purpose}>
          {purpose}
        </Typography>
      ) : null}
      {!!picture ? (
        <Media
          source={picture}
          className={
            imageOrientation === "vertical"
              ? classes.imageVertical
              : classes.image
          }
        />
      ) : null}
      {!!name ? (
        <Typography variant="h3" className={classNames(classes.name, "author")}>
          {name}
        </Typography>
      ) : null}
      {!!position &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showPosition)) ? (
        <Typography variant="body1" className={classes.position}>
          {position}
        </Typography>
      ) : null}
      {!!description &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showDescription)) ? (
        <RenderHtml html={description} className={classes.description} />
      ) : null}
      {!!phone &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showPhone)) ? (
        <Typography
          variant="body1"
          className={classNames(
            classes.contact,
            !!darkBackground
              ? classes.lightFontContact
              : classes.darkFontContact
          )}
        >
          <a href={`tel:${noWhiteSpaces(phone)}`}>{phone}</a>
        </Typography>
      ) : null}
      {!!email &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showEmail)) ? (
        <Typography
          variant="body1"
          className={classNames(
            !!darkBackground
              ? classes.lightFontContact
              : classes.darkFontContact,
            classes.emails,
            classes.contact
          )}
        >
          <ContactObfusscator email={email} />
        </Typography>
      ) : null}
      {!!linkedIn &&
      (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showLinkedIn)) ? (
        <Typography
          variant="body1"
          className={classNames(
            classes.contact,
            !!darkBackground
              ? classes.lightFontContact
              : classes.darkFontContact
          )}
        >
          <a href={linkedIn} target="_blank" rel="noopener noreferrer">
            {!!darkBackground ? (
              <img src={LinkedInLogoWhite} alt="" role="presentation" />
            ) : (
              <img src={LinkedInLogo} alt="" role="presentation" />
            )}
            LinkedIn
          </a>
        </Typography>
      ) : null}
      {!!cta
        ? (!fieldsToShow || (!!fieldsToShow && !!fieldsToShow.showLink)) && (
            <Typography
              variant="body1"
              className={classNames(
                classes.contact,
                !!darkBackground
                  ? classes.lightFontContact
                  : classes.darkFontContact
              )}
            >
              <a
                href={cta.url}
                className={!!cta.className && cta.className}
                target="_blank"
                rel="noopener noreferrer"
              >
                {!!darkBackground ? (
                  <img src={WebsiteIconWhite} className={classes.icon} />
                ) : (
                  <img src={WebsiteIcon} className={classes.icon} />
                )}
                {cta.text}
              </a>
            </Typography>
          )
        : null}
    </Grid>
  );
};

export default CardPerson;
