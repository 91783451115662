import React, { createContext, useState } from "react";
import useLocalStorage from "./../../helpers/local-storage";

const LayoutContext = createContext();

function LayoutProvider(props) {
  const [mainNavIsVisible, setMainNavIsVisible] = useState(true);

  const location =
    typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <LayoutContext.Provider
      value={{
        mainNavIsVisible,
        setMainNavIsVisible,
        location
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
}

export { LayoutContext, LayoutProvider };
