import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import SectionHeader from "../../section-header";
import { isDarkBackground } from "../../layout/theme/palette";
import { Grid, makeStyles } from "@material-ui/core";
import HubspotForm from "../../hubspot-form/_components/hubspot-form";

export const HubspotFormSectionQueryFragment = graphql`
  fragment HubspotFormSection on ContentfulModuleHubspotFormSection {
    sectionHeader {
      ...SectionHeader
    }
    hubspotForm {
      ...HubspotForm
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));
const HubspotFormSection = ({
  data: { sectionHeader, hubspotForm, sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  const darkBackground = isDarkBackground(
    sectionProperties?.backgroundColor?.color
  );

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <SectionHeader
              data={sectionHeader}
              darkBackground={darkBackground}
            ></SectionHeader>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <HubspotForm
              data={hubspotForm}
              buttonVariant="primaryRed"
              buttonStyles={classes.button}
              darkBackground={darkBackground}
            />
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default HubspotFormSection;
