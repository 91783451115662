import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { Link } from "gatsby";

const useStyles = makeStyles(theme => ({
  tagsList: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(4),
    listStyleType: "none",
    overflowWrap: "break-word",
    "& li": {
      display: "inline-block",
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      "& a": {
        color: theme.palette.functional_grey_1,
        borderBottom: "none",
        "&:hover": {
          textDecoration: "underline",
          borderBottom: "none"
        }
      }
    }
  }
}));

const BlogArticleTags = ({ tags }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid component="ul" className={classes.tagsList}>
        {tags.map(tag => {
          return (
            <Typography component="li" variant="h5" key={tag.id}>
              <Link to={`/blog/`} state={{ initialTagName: tag.name }}>
                #{tag.name}
              </Link>
            </Typography>
          );
        })}
      </Grid>
      <span style={{ display: "none" }} className="tags-gtm">
        {tags.map(x => x.name).join(",")}
      </span>
    </Grid>
  );
};

export default BlogArticleTags;
