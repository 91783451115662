import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { isAbsoluteUrl } from "../../../helpers";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    width: "100%",
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      "& img": {
        transform: "scale(1.1)"
      }
    }
  }
}));

const BlogTeaserLink = ({ children, link, target }) => {
  const classes = useStyles();
  const isAbsolute = isAbsoluteUrl(link);

  const to =
    process.env.SITE_VERSION === "PL"
      ? `https://noaignite.co.uk/blog/${link}`
      : `/blog/${link}`;

  if (isAbsolute) {
    return (
      <a href={link} className={classes.link} target={target} rel="noopener">
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} className={classes.link}>
        {children}
      </Link>
    );
  }
};

export default BlogTeaserLink;
