import typography from "./typography";
import palette from "./palette";
import muiCssBaseline from "./mui-css-baseline";

const contentSizes = {
  maxContentWidth: 1920,
  mobileMargin: 20,
  desktopMargin: 50
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 760,
    md: 1024,
    lg: 1400,
    xl: 2000
  }
};

const theme = {
  breakpoints,
  typography,
  palette,
  contentSizes,
  overrides: {
    ...muiCssBaseline
  }
};

export default theme;
