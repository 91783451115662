import { makeStyles, SvgIcon, Typography } from "@material-ui/core";
import React from "react";
import Media from "../../media";

const useStyles = makeStyles(theme => ({
  singleIcon: {
    width: "auto",
    height: 25,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  text: {
    display: "flex",
    alignItems: "center",
    textAlign: "left"
  }
}));

const ContactFormsSectionTabLabel = ({ text, iconDark, iconLight, active }) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" component="h2" className={classes.text}>
      <Media
        source={active ? iconLight : iconDark}
        className={classes.singleIcon}
      />
      {text}
    </Typography>
  );
};

export default ContactFormsSectionTabLabel;
