import React, { useState, useEffect } from "react";
import Container from "./_components/container";
import Navigation from "./_components/navigation";
import Footer from "./_components/footer";
import get from "lodash/get";
import themeProperties from "./theme";
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "../../assets/styles/base.css";
import { responsiveFontSizes } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LayoutProvider } from "./LayoutContext";
import HandleError404 from "./_components/404/404";
import getCookie from "../../helpers/get-cookie";
import { getBackgroundStyles } from "./theme/palette";

const theme = responsiveFontSizes(createTheme(themeProperties));
const Layout = props => {
  const {
    children,
    className,
    mwLogo,
    globalSettings,
    footerImage,
    error404data,
    pageContext
  } = props;
  const pagePath = get(props, "pageResources.page.path");
  const locationPath = get(props, "location.pathname");
  const [isClient, setClient] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  const [consentCookie, setConsentCookie] = useState(false);
  const [cookieBanner, setCookieBanner] = useState(false);

  useEffect(() => {
    setConsentCookie(!!getCookie("CookieConsent"));
  }, []);

  useEffect(() => {
    if (!consentCookie) {
      const onClickCookieBanner = () => {
        setConsentCookie(true);
      };
      window.addEventListener("CookiebotOnAccept", onClickCookieBanner);
      window.addEventListener("CookiebotOnDecline", onClickCookieBanner);
      return () => {
        window.removeEventListener("CookiebotOnAccept", onClickCookieBanner);
        window.removeEventListener("CookiebotOnDecline", onClickCookieBanner);
      };
    }
  }, []);

  useEffect(() => {
    setCookieBanner(!!document.querySelector("#Cookiebot"));
  }, []);

  useEffect(() => {
    const isCookieBannerExist = () => {
      setCookieBanner(!!document.querySelector("#Cookiebot"));
    };
    window.addEventListener("load", isCookieBannerExist);
    return () => {
      window.removeEventListener("load", isCookieBannerExist);
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme} key={isClient ? "client" : "server"}>
      <CssBaseline />
      <div style={{ height: "100%" }}>
        <LayoutProvider>
          {process.env.SITE_VERSION === "UK" &&
            !!cookieBanner &&
            !consentCookie && (
              <div
                style={{
                  position: "fixed",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  backgroundColor: "rgba(0,0,0,.5)",
                  zIndex: 100
                }}
              ></div>
            )}
          {pageContext.layout !== "no-header" && (
            <Navigation
              mwLogo={mwLogo}
              globalSettings={globalSettings}
              topNavigationLinksHidden={pageContext.topNavigationLinksHidden}
            />
          )}
          <Container
            className={className}
            style={pageContext.layout === "no-header" && { marginTop: 0 }}
            navigationBackgroundColor={getBackgroundStyles(
              globalSettings.navigationBackgroundColor
            )}
          >
            <div style={{ zIndex: 2, position: "relative" }}>{children}</div>
          </Container>
          <HandleError404
            pagePath={pagePath}
            locationPath={locationPath}
            data={error404data}
          />
          <Footer
            globalSettings={globalSettings}
            footerImage={footerImage}
            useSimplifiedFooter={pageContext.useSimplifiedFooter}
          />
        </LayoutProvider>
      </div>
    </MuiThemeProvider>
  );
};

export default Layout;
