import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ContactFormInput from "../../contact-form/_components/contact-form-input";
import ContactFormCheckbox from "../../contact-form/_components/contact-form-checkbox";
import SendFormChecker from "../../contact-form/_components/send-form-checker";
import Button from "../../buttons";
import classNames from "classnames";
import validate from "./helpers/form-validate";
import getCookie from "../../../helpers/get-cookie";
import getUserIpAddress from "../../../helpers/get-user-ip-address";
import {
  getCurrentPageName,
  getCurrentUrl
} from "../../../helpers/get-current-url";
import fetchDisallowedEmailDomains from "../../contact-form/helpers/fetch-disallowed-email-domains";
import axios from "axios";
import noWhiteSpaces from "../../../helpers/no-white-spaces";
import { navigate } from "gatsby";
import { pushToDataLayer } from "../../../helpers";
import pushFbPixelEvent from "../../../helpers/fb-pixel-events";
import Cookies from "universal-cookie";

export const HubspotFormQueryFragment = graphql`
  fragment HubspotForm on ContentfulBlockHubspotForm {
    formHeader: header
    hubspotFormId
    formFields {
      id
      name
      type
      label
      labelIcon {
        ...Media200
      }
      required
      placeholder
      width
      multiline
      rows
      rowsMax
    }
    privacyPolicy {
      childMarkdownRemark {
        html
      }
    }
    marketingConsent {
      childMarkdownRemark {
        html
      }
    }
    marketingConsentFieldIsRequired
    submitButtonText
    redirectOnSubmitUrl
    successfulSend
    failSend
    cookie {
      name
    }
    dataLayerEvents {
      event
      topic
      expert
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2)
  },
  whiteHeader: {
    color: theme.palette.text.secondary
  },
  submitButton: {
    width: "100%"
  },
  consentsWrapper: {
    [theme.breakpoints.up("lg")]: {
      order: 2
    }
  },
  buttonWrapper: {
    [theme.breakpoints.up("lg")]: {
      order: 1,
      "& button": {
        [theme.breakpoints.up("lg")]: {
          height: 50,
          padding: theme.spacing(0, 1)
        }
      }
    },
    alignSelf: "flex-end"
  },
  sendFormCheckerWrappper: {
    [theme.breakpoints.up("lg")]: {
      order: 3
    }
  }
}));

const HubspotForm = ({
  data: {
    formHeader,
    hubspotFormId,
    formFields,
    privacyPolicy,
    submitButtonText,
    marketingConsent,
    marketingConsentFieldIsRequired,
    redirectOnSubmitUrl,
    successfulSend,
    failSend,
    cookie,
    dataLayerEvents
  },
  className,
  buttonStyles,
  buttonVariant,
  formInputAndSubmitButtonInOneLine,
  sendFormCheckerStyles,
  darkBackground,
  location
}) => {
  const [disallowedEmailDomains, setDisallowedEmailDomains] = useState(null);
  const [
    isLoadingDisallowedEmailDomains,
    setIsLoadingDisallowedEmailDomains
  ] = useState(false);

  const classes = useStyles();

  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);

  const currentUrl = getCurrentUrl(location);
  const currentPageName = getCurrentPageName(location);

  const requiredFieldsList = !!formFields
    ? formFields.map(field => {
        return { [field.name]: !!field.required };
      })
    : {};

  const requiredFieldsObj =
    Object.keys(requiredFieldsList).length > 0
      ? Object.assign({}, ...requiredFieldsList)
      : {};
  requiredFieldsObj.marketingConsent = marketingConsentFieldIsRequired;

  const cookieExpirationDate = new Date();
  cookieExpirationDate.setFullYear(cookieExpirationDate.getFullYear() + 1);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      email: "",
      message: "",
      company: "",
      phone: "",
      privacy: false,
      marketingConsent: false
    },
    resolver: values =>
      validate(values, requiredFieldsObj, disallowedEmailDomains)
  });

  const watchEmail = watch("email");

  useEffect(() => {
    const getDisallowedEmailDomains = async () => {
      setIsLoadingDisallowedEmailDomains(true);
      const result = await fetchDisallowedEmailDomains(hubspotFormId);
      setIsLoadingDisallowedEmailDomains(false);
      setDisallowedEmailDomains(result);
    };

    if (
      watchEmail &&
      watchEmail !== "" &&
      disallowedEmailDomains == null &&
      !isLoadingDisallowedEmailDomains
    ) {
      getDisallowedEmailDomains();
    }
  }, [watchEmail]);

  const onSubmit = async values => {
    setSendSuccess(false);
    setSendError(false);

    const hutk = getCookie("hubspotutk");
    const ipAddress = await getUserIpAddress();

    const hubspotData = {
      fields: [
        {
          name: "firstname",
          value: values.name
        },
        {
          name: "email",
          value: values.email
        },
        {
          name: "message",
          value: values.message
        },
        {
          name: "company",
          value: values.company
        },
        {
          name: "phone",
          value: noWhiteSpaces(values.phone)
        }
      ],
      context: {
        hutk: hutk,
        pageName: currentPageName,
        pageUri: currentUrl,
        ipAddress: ipAddress
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: values.privacy,
          text:
            "I agree to allow NoA Ignite to store and process my personal data.",
          communications: [
            {
              value: values.marketingConsent || false,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from NoA Ignite."
            }
          ]
        }
      }
    };
    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
      data: JSON.stringify(hubspotData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        pushToDataLayer(dataLayerEvents);
        pushFbPixelEvent("formularz");
        setSendSuccess(true);
        reset();
        if (!!cookie.name) {
          const cookies = new Cookies();
          cookies.set(cookie.name, "true", {
            expires: cookieExpirationDate
          });
        }
        if (!!redirectOnSubmitUrl) {
          navigate(redirectOnSubmitUrl);
        }
      })
      .catch(() => {
        setSendError(true);
      });
  };

  return (
    <Grid item xs={12} className={className}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {!!formHeader && (
          <Grid item xs={12}>
            <Typography
              variant="h2"
              className={classNames(
                classes.header,
                darkBackground ? classes.whiteHeader : null
              )}
            >
              {formHeader}
            </Typography>
          </Grid>
        )}
        <Grid container spacing={4}>
          {!!formFields
            ? formFields.map(formField => (
                <Grid
                  item
                  key={formField.name}
                  xs={12}
                  sm={formField.width === "half" ? 6 : 12}
                  lg={
                    formField.width === "half"
                      ? 6
                      : !!formInputAndSubmitButtonInOneLine
                      ? 8
                      : 12
                  }
                >
                  <div>
                    <ContactFormInput
                      type={formField.type}
                      name={formField.name}
                      label={formField.label}
                      placeholder={formField.placeholder}
                      multiline={formField.multiline}
                      rows={formField.rows}
                      rowsMax={formField.rowsMax}
                      required={formField.required}
                      control={control}
                      darkBackground={darkBackground}
                    />
                  </div>
                </Grid>
              ))
            : null}
          <Grid
            item
            container
            spacing={!!marketingConsent && 2}
            className={
              !!formInputAndSubmitButtonInOneLine
                ? classes.consentsWrapper
                : null
            }
          >
            <Grid item xs={12}>
              <ContactFormCheckbox
                name="privacy"
                htmlText={privacyPolicy}
                control={control}
                required
                darkBackground={darkBackground}
              />
            </Grid>
            {!!marketingConsent ? (
              <Grid item xs={12}>
                <ContactFormCheckbox
                  name="marketingConsent"
                  htmlText={marketingConsent}
                  control={control}
                  required
                  darkBackground={darkBackground}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            lg={!!formInputAndSubmitButtonInOneLine ? 4 : 12}
            className={
              !!formInputAndSubmitButtonInOneLine ? classes.buttonWrapper : null
            }
          >
            <Button
              type="submit"
              size="lg"
              disabled={isSubmitting}
              className={classNames(
                classes.submitButton,
                !!buttonStyles && buttonStyles
              )}
              variant={!!buttonVariant ? buttonVariant : "primaryRed"}
            >
              {!!submitButtonText ? submitButtonText : "Contact us"}
            </Button>
          </Grid>
          {!!sendSuccess || sendError ? (
            <Grid item xs={12} className={classes.sendFormCheckerWrappper}>
              <SendFormChecker
                success={sendSuccess}
                error={sendError}
                successfulSend={successfulSend}
                failSend={failSend}
                textAlign="left"
                sendFormCheckerStyles={sendFormCheckerStyles}
                darkBackground={darkBackground}
              />
            </Grid>
          ) : null}
        </Grid>
      </form>
    </Grid>
  );
};

export default HubspotForm;
