import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import Contact from "./Contact";
import SocialMediaIcon from "./social-media-icon";

const useStyles = makeStyles(theme => ({
  companyAddresTitle: {
    marginBottom: theme.spacing(2.5)
  }
}));

const SimplifiedFooter = ({ data: { companyAddresses, socialMediaIcons } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid item xs={12}>
      <Grid container spacing={isMobile ? 10 : 5}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.companyAddresTitle}>
            Contact
          </Typography>
          <Grid container spacing={isMobile ? 10 : 5}>
            {companyAddresses.map(address => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={address.id}>
                <Contact companyAddress={address} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {socialMediaIcons.map(icon => (
            <SocialMediaIcon data={icon} key={icon.id} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimplifiedFooter;
