import React from "react";
import { graphql } from "gatsby";
import { RenderHtml } from "../../../helpers";
import {
  Grid,
  useTheme,
  useMediaQuery,
  makeStyles,
  Typography
} from "@material-ui/core";
import SectionContainer from "../../section-container";
import CardPerson from "../../card-person";
import SectionHeader from "../../section-header";
import ContactBanner from "../../contact-banner";
import { isDarkBackground } from "../../layout/theme/palette";

export const PeopleSectionQueryFragment = graphql`
  fragment PeopleSection on ContentfulModulePeopleSection {
    sectionHeader {
      ...SectionHeader
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    people {
      ... on ContentfulBlockContactPerson {
        id
        name
        position
        picture {
          ...Media1200
        }
        phone
        email
        description {
          childMarkdownRemark {
            html
          }
        }
        linkedIn
        cta {
          ...Cta
        }
        __typename
      }
      ... on ContentfulBlockContactBanner {
        ...ContactBanner
      }
    }
    personImageOrientation
    sectionHeaderHidden
    sectionHeaderAndPeopleAreBelowEachOther
    sectionProperties {
      ...SectionProperties
    }
    contactPersonFieldsToShow {
      showPurpose
      showPosition
      showPhone
      showEmail
      showCompany
      showDescription
      showLinkedIn
      showLink
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    rowGap: 40
  },
  banner: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "35%"
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "40%"
    }
  }
}));

const PeopleSection = ({
  data: {
    sectionHeader,
    text,
    people,
    personImageOrientation = "horizontal",
    sectionProperties,
    contactPersonFieldsToShow,
    sectionHeaderHidden,
    sectionHeaderAndPeopleAreBelowEachOther
  }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const darkBackground = isDarkBackground(
    sectionProperties?.backgroundColor?.color
  );

  const cardGridShouldTakeFullWidth =
    !!sectionHeaderHidden || !!sectionHeaderAndPeopleAreBelowEachOther;

  const gridItemMediaQueries = {
    xs: 12,
    sm: !!sectionHeaderHidden ? 6 : 12,
    md: cardGridShouldTakeFullWidth ? (people.length === 3 ? 4 : 6) : 6,
    lg: cardGridShouldTakeFullWidth
      ? people.length >= 4
        ? 3
        : people.length === 3
        ? 4
        : 6
      : 6
  };

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={5}>
        {!sectionHeaderHidden ? (
          <Grid item xs={12} sm={4}>
            {typeof sectionHeader === "object" && (
              <SectionHeader
                data={sectionHeader}
                darkBackground={darkBackground}
              />
            )}
            {typeof sectionHeader === "string" && (
              <Typography variant="h2">{sectionHeader}</Typography>
            )}
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={!!sectionHeaderHidden ? 12 : 8}
          md={cardGridShouldTakeFullWidth ? 12 : 8}
        >
          <Grid container spacing={isMobile ? 5 : 10}>
            {!!text ? (
              <Grid item xs={12} sm={9}>
                <RenderHtml html={text} />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Grid container spacing={5} className={classes.cardsContainer}>
                {people.map(card => {
                  if (card.__typename === "ContentfulBlockContactPerson") {
                    return (
                      <Grid
                        item
                        xs={gridItemMediaQueries.xs}
                        sm={gridItemMediaQueries.sm}
                        md={gridItemMediaQueries.md}
                        lg={gridItemMediaQueries.lg}
                        key={card.id}
                      >
                        <CardPerson
                          picture={card.picture}
                          name={card.name}
                          position={card.position}
                          description={card.description}
                          phone={card.phone}
                          email={card.email}
                          linkedIn={card.linkedIn}
                          cta={card.cta}
                          fieldsToShow={contactPersonFieldsToShow}
                          darkBackground={darkBackground}
                          imageOrientation={personImageOrientation}
                        />
                      </Grid>
                    );
                  } else if (card.__typename === "ContentfulBlockContactBanner")
                    return (
                      <Grid
                        item
                        xs={gridItemMediaQueries.xs}
                        sm={gridItemMediaQueries.sm}
                        md={gridItemMediaQueries.md}
                        lg={gridItemMediaQueries.lg}
                        key={card.id}
                      >
                        <ContactBanner data={card} className={classes.banner} />
                      </Grid>
                    );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default PeopleSection;
