import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: 0,
    "& $text h1": {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        maxWidth: "472px"
      },
      "& + p": {
        marginTop: "40px"
      }
    }
  },
  text: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.only("xs")]: {
      paddingTop: 0
    }
  },
  headerDate: {
    marginBottom: "12px"
  }
}));

const BlogArticleHeader = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid className={classes.text}>
        <Typography className={classes.headerDate} variant="body1">
          {data.date}
        </Typography>
        <Typography variant="h1">{data.name}</Typography>
      </Grid>
    </Grid>
  );
};

export default BlogArticleHeader;
