import React, { useState, useLayoutEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MenuItemLink from "./menu-item-link";
import theme from "../../../theme";
import MenuItemDropdown from "./menu-item-dropdown";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      padding: "10px 50px 50px 50px",
      width: "100vw"
    }
  },
  bgDark: {
    background: theme.palette.background.blue
  },
  itemLight: {
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.functional_grey_4
    }
  },
  itemDark: {
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.background.blue
    }
  }
}));

const DropdownMenu = ({
  items,
  defaultItem,
  onSelectMenuItem,
  windowWidth,
  topNavigationDarkTheme,
  backgroundStyles
}) => {
  const classes = useStyles();
  const [itemsWithSubitems, setItemsWithSubitems] = useState([]);
  const [itemsWithoutSubitems, setItemsWithoutSubitems] = useState([]);

  useLayoutEffect(() => {
    if (!!items && items.length > 0) {
      const withSubitems = [];
      const withoutSubitems = [];
      setItemsWithSubitems([]);
      setItemsWithoutSubitems([]);
      items.forEach(item =>
        !!item.expandable && !!item.subitems && !!item.subitems.length > 0
          ? withSubitems.push(item)
          : withoutSubitems.push(item)
      );
      setItemsWithSubitems(withSubitems);
      setItemsWithoutSubitems(withoutSubitems);
    }
  }, [items]);

  return (
    <>
      <Grid container className={classes.container} style={backgroundStyles}>
        <Grid container item xs={12} md={2}>
          <Grid
            item
            xs={12}
            className={
              topNavigationDarkTheme ? classes.itemDark : classes.itemLight
            }
            style={backgroundStyles}
          >
            {!!defaultItem ? (
              <MenuItemLink
                item={defaultItem}
                onSelectMenuItem={onSelectMenuItem}
                bold
                topNavigationDarkTheme={topNavigationDarkTheme}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          spacing={windowWidth > theme.breakpoints.values["md"] ? 3 : 0}
        >
          {!!itemsWithSubitems && itemsWithSubitems.length > 0
            ? itemsWithSubitems.map(item => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={
                    topNavigationDarkTheme
                      ? classes.itemDark
                      : classes.itemLight
                  }
                  key={item.contentful_id}
                >
                  <MenuItemDropdown
                    item={item}
                    text={item.name}
                    items={item.subitems}
                    topLevelDropdown={false}
                    onSelectMenuItem={onSelectMenuItem}
                    alwaysExpanded={item.alwaysExpanded}
                    windowWidth={windowWidth}
                    topNavigationDarkTheme={topNavigationDarkTheme}
                  />
                </Grid>
              ))
            : null}
          {!!itemsWithoutSubitems && itemsWithoutSubitems.length > 0 ? (
            <Grid
              item
              xs={12}
              md={3}
              className={
                topNavigationDarkTheme ? classes.itemDark : classes.itemLight
              }
            >
              {itemsWithoutSubitems.map(item => (
                <Grid item xs={12} key={item.contentful_id}>
                  <MenuItemLink
                    item={item}
                    onSelectMenuItem={onSelectMenuItem}
                    bold
                    topNavigationDarkTheme={topNavigationDarkTheme}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default DropdownMenu;