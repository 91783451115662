import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1)
    }
  },
  subheader: {
    "& p": {
      fontSize: "1.41rem",
      letterSpacing: "normal",
      marginBottom: "0 !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.3rem"
      }
    },
    "& a": {
      borderBottom: "none",
      "&:hover, &:active, &:focus": {
        borderBottom: "none",
        textDecoration: "underline"
      }
    }
  }
}));

const BlogArticleSubheader = ({ data }) => {
  const classes = useStyles();

  if (!data.subheader) {
    return <></>;
  }
  return (
    <Grid container className={classes.container}>
      {!!data.subheader ? (
        <RenderHtml
          className={classes.subheader}
          html={data.subheader.childMarkdownRemark.html}
        />
      ) : null}
    </Grid>
  );
};

export default BlogArticleSubheader;
