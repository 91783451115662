import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import getCookie from "../../../helpers/get-cookie";
import CookieInfo from "../../cookie-info";

const useStyles = makeStyles(theme => ({
  iframeWrapper: {
    padding: "90% 0 0 0",
    position: "relative",
    marginBottom: theme.spacing(3)
  },
  iframe: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    border: "none"
  },
  cookieInfoWrapper: {
    maxWidth: 450,
    margin: "0 auto",
    marginBottom: theme.spacing(3)
  },
  container: {
    "& #flex-container": {
      [theme.breakpoints.up("md")]: {
        minHeight: 600
      }
    }
  }
}));

const Iframe = ({ src, consents }) => {
  const classes = useStyles();
  const [preferencesConsent, setPreferencesConsent] = useState(false);
  const [statisticsConsent, setStatisticsConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [cookiebot, setCookiebot] = useState(false);
  const initializeConsentsArray = consents => {
    let result = [];
    if (!!consents.preferences) {
      result.push("preferences");
    }
    if (!!consents.statistics) {
      result.push("statistics");
    }
    if (!!consents.marketing) {
      result.push("marketing");
    }
    return result;
  };

  const getConsentsValues = arr => {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "preferences") {
        result.push(!!preferencesConsent);
      } else if (arr[i] === "statistics") {
        result.push(!!statisticsConsent);
      } else {
        result.push(!!marketingConsent);
      }
    }
    if (result.includes(false)) {
      return false;
    } else return true;
  };

  const getCookiesToAcceptText = arr => {
    let result = [...arr];
    if (result.length === 2) {
      result[0] += " and";
    } else if (result.length === 3) {
      result[0] += ",";
      result[1] += " and";
    }
    return result.join(" ");
  };

  const consentsArray = initializeConsentsArray(consents);
  const condition = getConsentsValues(consentsArray);
  const cookiesToAcceptText = getCookiesToAcceptText(consentsArray);

  useEffect(() => {
    if (typeof document !== "undefined") {
      const cookie = getCookie("CookieConsent");
      setPreferencesConsent(
        !!cookie ? cookie.includes("preferences:true") : false
      );
      setStatisticsConsent(
        !!cookie ? cookie.includes("statistics:true") : false
      );
      setMarketingConsent(!!cookie ? cookie.includes("marketing:true") : false);
      setCookiebot(!!document.querySelector("#Cookiebot"));
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onAccept = () => {
        setPreferencesConsent(!!Cookiebot.consent.preferences);
        setStatisticsConsent(!!Cookiebot.consent.statistics);
        setMarketingConsent(!!Cookiebot.consent.marketing);
      };
      window.addEventListener("CookiebotOnAccept", onAccept);
      return () => {
        window.removeEventListener("CookiebotOnAccept", onAccept);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const findCookiebot = () => {
        setCookiebot(!!document.querySelector("#Cookiebot"));
      };
      window.addEventListener("load", findCookiebot);
      return () => {
        window.removeEventListener("load", findCookiebot);
      };
    }
  }, []);

  return (
    <>
      {condition ? (
        <div className={classes.iframeWrapper}>
          <iframe src={src} className={classes.iframe}></iframe>
        </div>
      ) : (
        <div className={classes.cookieInfoWrapper}>
          <CookieInfo
            cookiebot={cookiebot}
            cookiesToAcceptText={cookiesToAcceptText}
            className={classes.container}
          />
        </div>
      )}
    </>
  );
};

export default Iframe;
