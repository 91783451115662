import React from "react";
import Partner from "../../../../partner";
import { Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const Partnerships = ({ partners }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Partnerships
      </Typography>
      <Grid container spacing={5}>
        {partners.map(partner => {
          return (
            <Grid item xs={12} md={6} lg={2} key={partner.id}>
              <Partner data={partner} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Partnerships;
