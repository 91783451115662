import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { RenderHtml } from "../../../helpers";
import { Row } from "../../grid";
import classNames from "classnames";

export const HeroSectionQueryFragment = graphql`
  fragment HeroSection on ContentfulModuleHeroSection {
    name
    noPadding
    header {
      childMarkdownRemark {
        html
      }
    }
    subheader {
      childMarkdownRemark {
        html
      }
    }
    textCentered
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0
    },
    "& $text h1": {
      "& + p": {
        marginTop: "40px"
      }
    }
  },
  text: {
    marginBottom: theme.spacing(2.75),
    paddingTop: theme.spacing(20),
    alignSelf: "center",
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(5)
    }
  },
  centeredText: {
    alignSelf: "center",
    paddingTop: 0
  },
  subheaderWrapper: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(3.5)
  }
}));

const Hero = ({ data }) => {
  const classes = useStyles();

  return (
    <Row noPadding={data.noPadding}>
      <Grid item xs={12} sm={7} className={classes.container}>
        <RenderHtml
          html={data.header}
          className={classNames(
            classes.text,
            !!data.textCentered && classes.centeredText
          )}
          preserveFirstH1tag
        ></RenderHtml>
      </Grid>
      {!!data.subheader && (
        <Grid container>
          <Grid
            item
            xs={12}
            md={10}
            lg={6}
            className={classes.subheaderWrapper}
          >
            <RenderHtml html={data.subheader}></RenderHtml>
          </Grid>
        </Grid>
      )}
    </Row>
  );
};

export default Hero;
