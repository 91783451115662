import React from "react";
import { graphql } from "gatsby";
import { renderModule } from "../../../helpers";
import Cookies from "universal-cookie";

export const GatedContentSectionQueryFragment = graphql`
  fragment GatedContentSection on ContentfulModuleGatedContentSection {
    modulesRenderedBeforeAccessContent {
      ...BannerWithForm
      ...PeopleSection
    }
    modulesRenderedAfterAccessContent {
      ...VideoSection
      ...ButtonSection
      ...ContactUsSection
      ...PeopleSection
    }
    cookie {
      name
    }
    isHidden
    __typename
  }
`;

const GatedContentSection = ({
  data: {
    modulesRenderedBeforeAccessContent,
    modulesRenderedAfterAccessContent,
    cookie,
    isHidden
  }
}) => {
  const cookies = new Cookies();

  if (isHidden) {
    return <></>;
  } else {
    if (!!cookies.get(cookie.name)) {
      return modulesRenderedAfterAccessContent.map((module, index) => {
        return renderModule(module, index);
      });
    } else {
      return modulesRenderedBeforeAccessContent.map((module, index) => {
        return renderModule(module, index);
      });
    }
  }
};

export default GatedContentSection;
