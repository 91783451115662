import React from "react";
import { graphql } from "gatsby";
import SectionContainer from "../../section-container";
import Button from "../../buttons";
import { Grid } from "@material-ui/core";
import downloadFile from "../../../helpers/download-file";

export const ButtonSectionQueryFragment = graphql`
  fragment ButtonSection on ContentfulModuleButtonSection {
    name
    cta {
      ...Cta
    }
    fileToDownload {
      ...Media1000
    }
    filenameOfTheDownloadedFile
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const ButtonSection = ({
  data: { cta, fileToDownload, filenameOfTheDownloadedFile, sectionProperties }
}) => {
  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={8}>
          {!!fileToDownload && !!filenameOfTheDownloadedFile ? (
            <Button
              variant="secondaryBlue"
              onClick={() =>
                downloadFile(
                  fileToDownload.file.url,
                  filenameOfTheDownloadedFile
                )
              }
            >
              {cta.text}
            </Button>
          ) : (
            !!cta && (
              <Button
                href={cta.url}
                className={!!cta.className && cta.className}
              >
                {cta.text}
              </Button>
            )
          )}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default ButtonSection;
