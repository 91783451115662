import { graphql } from "gatsby";
import React from "react";
import { Row } from "../../grid";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import TagsListSection from "../../tags-list-section";
import buildBreadcrumbs from "../../../helpers/breadcrumbs-builder";
import Breadcrumbs from "../../breadcrumbs/_components/breadcrumbs";

export const ArticlePageHeroSectionQueryFragment = graphql`
  fragment ArticlePageHeroSection on ContentfulModuleArticlePageHeroSection {
    textHeader: header
    breadcrumbText
    media {
      ...MediaFullWidth
    }
    subheader {
      childMarkdownRemark {
        html
      }
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(5)
    }
  },
  leftColumn: {
    position: "relative"
  },
  breadcrumbWrapper: { position: "absolute", top: -16, width: "auto" },
  breadcrumbLink: {
    color: theme.palette.text.primary,
    textDecoration: "none"
  },
  date: {
    marginBottom: theme.spacing(0.5)
  },
  mediaWrapper: {
    marginTop: theme.spacing(6.25),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7)
    },
    "& video": {
      width: "100%"
    }
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  subheader: {
    marginTop: theme.spacing(6.25)
  }
}));

const ArticlePageHeroSection = ({
  data: {
    textHeader,
    breadcrumbText,
    date,
    timeToRead,
    tags,
    media,
    imageCaption,
    mainImageDisplay = true,
    subheader,
    tagsLink
  },
  pageContext
}) => {
  const classes = useStyles();
  const breadcrumbs = buildBreadcrumbs(pageContext?.parentPage);

  const dateString =
    (!!date ? date : "") + (!!timeToRead ? ` / ${timeToRead} min read` : "");

  return (
    <Row noPadding className={classes.row}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} className={classes.leftColumn}>
          {!!breadcrumbs ? (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          ) : !!breadcrumbText ? (
            <Typography variant="body1">{breadcrumbText}</Typography>
          ) : null}
          <Typography variant="h1">{textHeader}</Typography>
        </Grid>
        <Grid item xs={12}>
          {!!date && (
            <Typography variant="body1" className={classes.date}>
              {dateString}
            </Typography>
          )}
          {!!tags && <TagsListSection tags={tags} linkTo={tagsLink} />}
        </Grid>
        {!!mainImageDisplay && !!media.file.contentType.includes("image") ? (
          <Grid item xs={12} className={classes.mediaWrapper}>
            <Media source={media} />
            {!!imageCaption && <RenderHtml html={imageCaption} />}
          </Grid>
        ) : null}
        {!!media.file.contentType.includes("video") && (
          <Grid item xs={12} className={classes.mediaWrapper}>
            <Media source={media} controls />
          </Grid>
        )}
        {!!subheader ? (
          <Grid item container spacing={5}>
            <Grid item xs={4} className={classes.hiddenOnMobile}></Grid>
            <Grid item xs={12} sm={8} md={6}>
              <RenderHtml
                html={subheader}
                component="h3"
                className={classes.subheader}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Row>
  );
};

export default ArticlePageHeroSection;
