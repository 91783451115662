import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import { ClickAwayListener, Collapse, Fade, Grid } from "@material-ui/core";
import { Link } from "gatsby";
import MenuItems from "./menu-items";
import classNames from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Media from "../../../../media";
import DropdownMenu from "./dropdown-menu";
import { throttle } from "lodash";
import { LayoutContext } from "../../../LayoutContext";
import { isDarkBackground, getBackgroundStyles } from "../../../theme/palette";

const VerticallyCentered = ({ children }) => (
  <Grid container alignContent="center" style={{ height: "100%" }}>
    <Grid item xs>
      {children}
    </Grid>
  </Grid>
);

const useStyles = makeStyles(theme => ({
  header: {
    top: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    background: theme.palette.background.white,
    position: "fixed",
    display: "block",
    transition: "all 0.4s, background-color 0ms"
  },
  backdrop: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    zIndex: 98,
    width: "100%",
    height: "100%",
    background: "rgba(0, 25, 50, 0.1)"
  },
  bgDark: {
    backgroundColor: theme.palette.background.blue
  },
  bgLight: {
    backgroundColor: theme.palette.background.white
  },
  wrapper: {
    margin: "0 50px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.contentSizes.mobileMargin,
      marginRight: theme.contentSizes.mobileMargin
    }
  },
  navContainer: {
    margin: 0,
    height: 99,
    [theme.breakpoints.down(
      `${process.env.SITE_VERSION === "PL" ? "md" : "sm"}`
    )]: {
      height: 60
    },
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      height: 100
    }
  },
  logoWrapper: {
    "& img": {
      paddingTop: "5px",
      height: "auto",
      width: 120,
      height: 31,
      display: "block",
      [theme.breakpoints.up("sm")]: {
        margin: "0 auto"
      },
      [theme.breakpoints.up("lg")]: {
        width: 140,
        height: 36
      }
    }
  },
  logoWrapperPl: {
    "& img": {
      paddingTop: "5px",
      height: "auto",
      width: 150,
      height: 40,
      display: "block",
      [theme.breakpoints.up("sm")]: {
        margin: "0 auto"
      },
      [theme.breakpoints.up("lg")]: {
        width: 200,
        height: 60
      }
    }
  },
  menuWrapper: {
    position: "fixed",
    top: "60px",
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    padding: 0,
    overflow: "scroll",
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      position: "static",
      height: "auto",
      width: "auto",
      background: "none",
      overflow: "hidden"
    }
  },
  btnMenu: {
    float: "right",
    position: "relative",
    display: "block",
    height: 22,
    width: 30,
    marginRight: "5%",
    padding: 0,
    border: "0 none",
    background: "none",
    cursor: "pointer",
    [theme.breakpoints.up(
      `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
    )]: {
      display: "none"
    },
    "&:focus": {
      outline: "0 none"
    },
    "& + div": {
      display: "none",
      [theme.breakpoints.up(
        `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
      )]: {
        display: "block"
      }
    },
    "& span": {
      position: "absolute",
      display: "block",
      height: 2,
      width: 30,
      transition: "transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s",
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transition: "transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s"
      }
    },
    "& span:nth-child(1)": {
      top: 4
    },
    "& span:nth-child(2)": {
      top: 10
    },
    "& span:nth-child(3)": {
      top: 16
    }
  },
  btnMenuDark: {
    "& span": {
      "&:after": {
        background: theme.palette.background.white
      }
    }
  },
  btnMenuLight: {
    "& span": {
      "&:after": {
        background: theme.palette.ignite_primary_1
      }
    }
  },
  btnMenuIsOpen: {
    "& + div": {
      display: "block"
    },
    "& span": {
      "&:after": {
        transition: "transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.3s"
      }
    },
    "& span:nth-child(1)": {
      transform: "translateY(6px)",
      "&:after": {
        transform: "rotate(45deg)"
      }
    },
    "& span:nth-child(2)": {
      "&:after": {
        opacity: 0
      }
    },
    "& span:nth-child(3)": {
      transform: "translateY(-6px)",
      "&:after": {
        transform: "rotate(-45deg)"
      }
    }
  },
  listWrapper: {
    top: "60px",
    width: "100%",
    height: "100%",
    minHeight: "450px",
    bottom: 0,
    overflow: "auto",
    WebkitOverflowScrolling: "touch"
  },
  isHidden: {
    transform: "translateY(-100%)"
  }
}));

export default ({ globalSettings, topNavigationLinksHidden, mwLogo }) => {
  const { mainNavIsVisible, setMainNavIsVisible } = useContext(LayoutContext);
  const classes = useStyles();
  const theme = useTheme();

  const { topNavigationItems, navigationBackgroundColor } = globalSettings;
  const topNavigationDarkTheme = isDarkBackground(navigationBackgroundColor);
  const navigationBackgroundStyles = getBackgroundStyles(
    navigationBackgroundColor
  );

  const [isOpen, setIsOpen] = useState(false);
  const mwNav = useRef(null);

  const [windowWidth, setWindowWidth] = useState(0);

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [expandedMenuDropdownId, setExpandedMenuDropdownId] = useState(null);
  const [
    expandableTopNavigationItems,
    setExpandableTopNavigationItems
  ] = useState(null);

  const onHoverMenuItemDropdown = id => {
    if (!dropdownMenuOpen) {
      setDropdownMenuOpen(true);
    }
    setExpandedMenuDropdownId(id);
  };

  const onClickMenuItemDropdown = id => {
    if (dropdownMenuOpen) {
      if (id === expandedMenuDropdownId) {
        setDropdownMenuOpen(!dropdownMenuOpen);
      } else {
        setExpandedMenuDropdownId(id);
      }
    } else {
      setExpandedMenuDropdownId(id);
      setDropdownMenuOpen(!dropdownMenuOpen);
    }
  };

  useEffect(() => {
    const expandableItems =
      !!topNavigationItems && topNavigationItems.length > 0
        ? topNavigationItems.filter(
            item =>
              !!item.expandable && !!item.subitems && item.subitems.length > 0
          )
        : null;

    if (!!expandableItems && expandableItems.length > 0) {
      setExpandableTopNavigationItems(expandableItems);
    } else {
      setExpandableTopNavigationItems([]);
    }
  }, [topNavigationItems]);

  const handleClickAway = () => setDropdownMenuOpen(false);

  useEffect(() => {
    if (isOpen && windowWidth < theme.breakpoints.values["md"]) {
      disableBodyScroll(mwNav);
    } else {
      enableBodyScroll(mwNav);
    }
  }, [windowWidth, isOpen, theme.breakpoints.values]);

  useLayoutEffect(() => {
    function updateWindowWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", updateWindowWidth);
    updateWindowWidth();

    // equivalent to componentWillUnmount()
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);
  useEffect(() => {
    // close mobile menu when resizing window to desktop size
    if (
      isOpen &&
      windowWidth >=
        theme.breakpoints.values[
          `${process.env.SITE_VERSION === "PL" ? "lg" : "md"}`
        ]
    ) {
      setIsOpen(false);
    }
  }, [windowWidth, isOpen, theme.breakpoints.values]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const handleScroll = throttle(
    () => {
      const currentScrollPos = window.pageYOffset;
      //show navigation when scrolling up or being at the top of the page
      setMainNavIsVisible(
        prevScrollPos > currentScrollPos || currentScrollPos < 100
      );
      //hide dropdown menu when scrolling down
      if (prevScrollPos < currentScrollPos) {
        setDropdownMenuOpen(false);
      }
      setPrevScrollPos(currentScrollPos);
    },
    200,
    {
      leading: false,
      trailing: true
    }
  );
  useLayoutEffect(() => {
    if (!isOpen) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, mainNavIsVisible, handleScroll]);

  const handleMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
    if (dropdownMenuOpen) {
      setDropdownMenuOpen(false);
    }
  };

  const MenuNav = () => {
    if (
      !!topNavigationItems &&
      topNavigationItems.length > 0 &&
      !!expandableTopNavigationItems
    ) {
      return (
        <Grid item xs>
          <VerticallyCentered>
            <nav>
              <button
                className={classNames(
                  classes.btnMenu,
                  isOpen ? classes.btnMenuIsOpen : null,
                  topNavigationDarkTheme
                    ? classes.btnMenuDark
                    : classes.btnMenuLight
                )}
                type="button"
                title="expand menu"
                onClick={handleMenuButtonClick}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div
                ref={mwNav}
                className={classes.menuWrapper}
                style={navigationBackgroundStyles}
              >
                <div className={isOpen ? classes.listWrapper : ""}>
                  <MenuItems
                    navigationItems={topNavigationItems}
                    onSelectMenuItem={handleNavLinkClick}
                    onHoverMenuItemDropdown={onHoverMenuItemDropdown}
                    onClickMenuItemDropdown={onClickMenuItemDropdown}
                    expandedMenuDropdownId={expandedMenuDropdownId}
                    dropdownMenuOpen={dropdownMenuOpen}
                    windowWidth={windowWidth}
                    topNavigationDarkTheme={topNavigationDarkTheme}
                  />
                </div>
              </div>
            </nav>
          </VerticallyCentered>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <Fade in={visible && dropdownMenuOpen} timeout={500}>
            <div className={classes.backdrop} />
          </Fade>
          <header
            onMouseLeave={
              windowWidth > theme.breakpoints.values["sm"]
                ? () => setDropdownMenuOpen(false)
                : null
            }
            className={classNames(
              isOpen
                ? classNames(classes.header, classes.headerIsOpen)
                : classes.header,
              !mainNavIsVisible ? classes.isHidden : ""
            )}
            style={navigationBackgroundStyles}
          >
            <div className={classes.wrapper}>
              <Grid
                container
                className={classes.navContainer}
                alignItems="center"
              >
                <div
                  className={
                    process.env.SITE_VERSION === "PL"
                      ? classes.logoWrapperPl
                      : classes.logoWrapper
                  }
                >
                  <VerticallyCentered>
                    {!!topNavigationLinksHidden ? (
                      <a
                        href={"/"}
                        title="NoA Ignite Homepage"
                        target="_blank"
                        rel="noopener"
                      >
                        <Media
                          source={
                            topNavigationDarkTheme
                              ? globalSettings.topNavigationLogoWhite
                              : globalSettings.topNavigationLogo
                          }
                          alt="NoA Ignite logo"
                        />
                      </a>
                    ) : (
                      <Link
                        to={"/"}
                        onClick={handleNavLinkClick}
                        title="NoA Ignite Homepage"
                      >
                        <Media
                          source={
                            topNavigationDarkTheme
                              ? globalSettings.topNavigationLogoWhite
                              : globalSettings.topNavigationLogo
                          }
                          alt="NoA Ignite logo"
                        />
                      </Link>
                    )}
                  </VerticallyCentered>
                </div>
                {!topNavigationLinksHidden ? <MenuNav /> : null}
              </Grid>
            </div>

            {/* DropdownMenu menu on desktop */}
            {/* DropdownMenu on mobile is handled differently, in MenuItems*/}
            <Collapse
              in={
                visible &&
                dropdownMenuOpen &&
                !!expandableTopNavigationItems &&
                expandableTopNavigationItems.length > 0 &&
                !!windowWidth &&
                windowWidth >= theme.breakpoints.values["md"]
              }
              timeout={500}
              unmountOnExit={true}
            >
              {!!expandableTopNavigationItems &&
              expandableTopNavigationItems.length > 0
                ? expandableTopNavigationItems.map(item =>
                    item.contentful_id === expandedMenuDropdownId ? (
                      <Fade
                        in={
                          visible &&
                          dropdownMenuOpen &&
                          item.contentful_id === expandedMenuDropdownId
                        }
                        timeout={500}
                        key={item.contentful_id}
                      >
                        <div>
                          <DropdownMenu
                            items={item.subitems}
                            defaultItem={item.defaultSubitem}
                            onSelectMenuItem={handleNavLinkClick}
                            windowWidth={windowWidth}
                            open={
                              dropdownMenuOpen &&
                              item.contentful_id === expandedMenuDropdownId
                            }
                            topNavigationDarkTheme={topNavigationDarkTheme}
                            backgroundStyles={navigationBackgroundStyles}
                          />
                        </div>
                      </Fade>
                    ) : null
                  )
                : null}
            </Collapse>
          </header>
        </>
      </ClickAwayListener>
    </React.Fragment>
  );
};
