import axios from "axios";

const getUserIpAddress = async () => {
  return axios({
    method: "get",
    url: "https://api.ipify.org/"
  }).then(response => response.data);
};

export default getUserIpAddress;
