import { graphql } from "gatsby";

export const GlobalSettingsQueryFragment = graphql`
  fragment GlobalSettings on Query {
    allContentfulGlobalSettings {
      edges {
        node {
          contentful_id
          slug
          topNavigationItems {
            id
            contentful_id
            url
            name
            outlined
            expandable
            alwaysExpanded
            defaultSubitem {
              contentful_id
              url
              name
            }
            subitems {
              contentful_id
              url
              name
              expandable
              alwaysExpanded
              subitems {
                contentful_id
                url
                name
                expandable
                alwaysExpanded
              }
            }
          }
          topNavigationLogo {
            ...Media200
          }
          topNavigationLogoWhite {
            ...Media200
          }
          navigationBackgroundColor
          footer {
            footerLogo {
              ...Media200
            }
            badges {
              id
              badgeImage {
                ...Media200
              }
              cta {
                url
              }
            }
            contentful_id
            childContentfulFooterCopyrightTextNode {
              childMarkdownRemark {
                html
              }
            }
            childContentfulFooterPrivacyPolicyTextNode {
              childMarkdownRemark {
                html
              }
            }
            bottomSectionLogo {
              ...Media200
            }
            companyAddresses {
              id
              address {
                childMarkdownRemark {
                  html
                }
              }
              phone
              email
            }
            services {
              id
              name
              slug
            }
            clientCases {
              id
              slug
              clientName
            }
            partners {
              id
              logo {
                ...Media200
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            socialMediaIcons {
              id
              name
              icon {
                ...Media200
              }
              url
              linkTitle
            }
            footerBackgroundColor {
              color
            }
          }
        }
      }
    }
  }
`;

const defaultSettingsUkCfId = "2NsRKsOMwDeoxuYRzAKpTJ";
const defaultSettingsPlCfId = "6tJtO58L4ZK8ZOqwPSukAw";

const getGlobalSettings = ({ allContentfulGlobalSettings }, pagePath) => {
  const nodes = allContentfulGlobalSettings.edges.map(x => x.node);

  // If there are 'Global settings' with some value of 'slug' field
  // matching the pagePath, then use these global settings...
  const pathPart = (pagePath || "").split("/").filter(x => !!x)[0];
  const nodeToReturn = nodes.filter(x => x.slug?.includes(pathPart))[0];
  if (nodeToReturn) {
    return nodeToReturn;
  }

  // ...otherwise, use the default ones
  if (process.env.SITE_VERSION === "PL") {
    return nodes.filter(x => x.contentful_id === defaultSettingsPlCfId)[0];
  } else {
    return nodes.filter(x => x.contentful_id === defaultSettingsUkCfId)[0];
  }
};

export { getGlobalSettings };
