import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    background: "white",
    position: "relative",
    height: "100%",
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto"
  },
  plWrapper: {
    width: "100%",
    height: 60,
    [theme.breakpoints.up("lg")]: {
      height: 100
    }
  },
  ukWrapper: {
    width: "100%",
    height: 60,
    [theme.breakpoints.up("md")]: {
      height: 100
    }
  }
}));

export default ({ children, style, navigationBackgroundColor }) => {
  const classes = useStyles();

  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const key = isClient ? `client` : `server`;

  return (
    <>
      <div
        style={navigationBackgroundColor}
        className={
          process.env.SITE_VERSION === "PL"
            ? classes.plWrapper
            : classes.ukWrapper
        }
      ></div>
      <div className={classes.container} key={key} style={style || {}}>
        {children}
      </div>
    </>
  );
};
