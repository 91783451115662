import { Grid, makeStyles } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import { renderModule } from "../../../helpers";
import { Row } from "../../grid";
import { getBackgroundColorByName } from "../../layout/theme/palette";

export const HomepageHeroSectionQueryFragment = graphql`
  fragment HomepageHeroSection on ContentfulModuleHomepageHeroSection {
    name
    isHidden
    leftPanel {
      ... on Node {
        ...HeaderWithButton
        ...HeaderWithLogo
      }
    }
    rightPanel {
      ... on Node {
        ...HeaderWithButton
        ...HeaderWithLogo
      }
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "720px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-80px"
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "720px"
    }
  },
  bannerWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 50,
      right: 50,
      zIndex: 3
    },
    [theme.breakpoints.up("lg")]: {
      right: 220
    }
  }
}));

const HomepageHeroSection = ({ data }) => {
  const classes = useStyles();

  const inlineStyles = {
    backgroundColor: getBackgroundColorByName(data.leftPanel.backgroundColor)
  };

  if (data.isHidden || (data.leftPanel.isHidden && data.rightPanel.isHidden)) {
    return <></>;
  } else {
    return (
      <Row noPadding fullWidth style={inlineStyles}>
        <Grid container className={classes.container}>
          {!!data.leftPanel && !data.leftPanel.isHidden ? (
            <Grid item xs={12} sm={!!data.rightPanel.isHidden ? 12 : 6}>
              {renderModule(data.leftPanel, 0)}
            </Grid>
          ) : null}
          {!!data.rightPanel && !data.rightPanel.isHidden ? (
            <Grid item xs={12} sm={!!data.leftPanel.isHidden ? 12 : 6}>
              {renderModule(data.rightPanel, 1)}
            </Grid>
          ) : null}
        </Grid>
      </Row>
    );
  }
};

export default HomepageHeroSection;
