import { graphql, useStaticQuery } from "gatsby";

const useAllCasesTags = () => {
  const {
    allContentfulItemCasesTag: { nodes }
  } = useStaticQuery(graphql`
    query AllCasesTagQuery {
      allContentfulItemCasesTag(sort: { name: ASC }) {
        nodes {
          id
          name
          category
        }
      }
    }
  `);
  return nodes;
};

export default useAllCasesTags;
