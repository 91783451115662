import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  breadcrumbLink: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  breadcrumbLinkDarkBg: {
    color: theme.palette.text.secondary
  }
}));

const Breadcrumb = ({ text, path, darkBackground }) => {
  const classes = useStyles();

  if (!text || !path) {
    return <></>;
  }

  return (
    <Link
      to={path}
      className={classNames(
        classes.breadcrumbLink,
        darkBackground ? classes.breadcrumbLinkDarkBg : null
      )}
    >
      {text}
    </Link>
  );
};

export default Breadcrumb;
