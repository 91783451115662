import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Row } from "../../grid";
import CaseTeaser from "./case-teaser";
import {
  filterByTag,
  onDefaultTagSelected,
  onTagsSelected
} from "../../../helpers/tags-filter";
import TagsFilterSection from "../../tags-filter-section";
import InfiniteScroll from "react-infinite-scroller";
import loadMoreArticles from "../../../helpers/articles-loader";
import ContactBanner from "../../contact-banner";
import ContactBannerSection from "../../contact-banner-section";
import scrollTo from "gatsby-plugin-smoothscroll";

export const CasesPanelQueryFragment = graphql`
  fragment CasesPanel on ContentfulModuleCasesPanel {
    id
    name
    defaultTag {
      id
      name
    }
    promotedTags {
      id
      name
      category
    }
    tagCategoriesOrder
    articles {
      ... on ContentfulPageCase {
        id
        name
        slug
        tags {
          id
          name
        }
        teaserHeader
        teaserSubheader {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          ...Media900
        }
        __typename
      }
      ... on ContentfulBlockContactBanner {
        ...ContactBanner
      }
      ... on ContentfulModuleContactBannerSection {
        ...ContactBannerSection
      }
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(10)
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4.25),
      marginBottom: theme.spacing(8)
    },
    "& ul, & ul li": {
      listStyleType: "none",
      paddingInlineStart: 0,
      paddingLeft: 0
    }
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  item: {
    marginBottom: theme.spacing(17.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(7.5)
    }
  },
  banner: {
    height: "100%"
  }
}));

const CasesPanel = ({ data, responsiveStatus, location }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const numberOfArticlesPerLoading = 6;

  const initialTagName = location.state ? location.state.initialTagName : null;

  const [queryTag, setQueryTag] = useState(initialTagName);
  const [numberOfArticles, setNumberOfArticles] = useState(
    numberOfArticlesPerLoading
  );

  useEffect(() => {
    setQueryTag(initialTagName);
    if (queryTag != null || initialTagName != null) {
      setTimeout(() => {
        scrollTo("#cases-panel");
      }, 0);
    }
  }, [location]);

  const teasersData = data.articles.filter(article =>
    filterByTag(article, queryTag)
  );

  const handleTagSelected = (e, setQueryTag) => {
    setTimeout(() => {
      scrollTo("#cases-panel");
    }, 0);
    onTagsSelected(e, setQueryTag);
  };

  const handleTagSelectedWithoutScroll = (e, setQueryTag) => {
    onTagsSelected(e, setQueryTag);
  };

  const handleDefaultTagSelected = (e, setQueryTag) => {
    setTimeout(() => {
      scrollTo("#cases-panel");
    }, 0);
    onDefaultTagSelected(e, setQueryTag);
  };

  const handleDefaultTagSelectedWithoutScroll = (e, setQueryTag) => {
    onDefaultTagSelected(e, setQueryTag);
  };

  return (
    <Fragment>
      <TagsFilterSection
        name={data.name}
        defaultTag={data.defaultTag}
        tags={data.promotedTags}
        onDefaultTagSelected={() => handleDefaultTagSelected(setQueryTag)}
        onDefaultTagSelectedWithoutScroll={() =>
          handleDefaultTagSelectedWithoutScroll(setQueryTag)
        }
        onTagsSelected={e => handleTagSelected(e, setQueryTag)}
        onTagsSelectedWithoutScroll={e =>
          handleTagSelectedWithoutScroll(e, setQueryTag)
        }
        tagCategoriesOrder={data.tagCategoriesOrder}
        initialTagName={initialTagName}
        isCasesPage={true}
      ></TagsFilterSection>
      <Row noPadding className={classes.container} id="cases-panel">
        <Grid item xs={12} className={classes.root}>
          <InfiniteScroll
            threshold={700}
            pageStart={0}
            loadMore={() =>
              loadMoreArticles(
                setNumberOfArticles,
                responsiveStatus,
                numberOfArticlesPerLoading
              )
            }
            hasMore={numberOfArticles < teasersData.length}
          >
            <Grid container spacing={5} component="ul">
              {teasersData
                .slice(0, numberOfArticles)
                .map((teaserData, index) => {
                  if (
                    index === 0 &&
                    queryTag == null &&
                    teaserData.__typename == "ContentfulPageCase"
                  ) {
                    return (
                      <Grid item xs={12} sm={6} md={12} key={teaserData.id}>
                        <Grid container spacing={5}>
                          <Grid
                            item
                            xs={4}
                            className={classes.hiddenOnMobile}
                          ></Grid>
                          <Grid
                            key={teaserData.id}
                            item
                            xs={12}
                            md={6}
                            component="li"
                            className={classes.item}
                          >
                            <CaseTeaser
                              tags={teaserData.tags}
                              header={teaserData.teaserHeader}
                              subheader={teaserData.teaserSubheader}
                              image={teaserData.teaserImage}
                              url={teaserData.slug}
                              target="_self"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                  if (teaserData.__typename == "ContentfulPageCase") {
                    return (
                      <Grid
                        key={teaserData.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        component="li"
                        className={classes.item}
                      >
                        <CaseTeaser
                          tags={teaserData.tags}
                          header={teaserData.teaserHeader}
                          subheader={teaserData.teaserSubheader}
                          image={teaserData.teaserImage}
                          url={teaserData.slug}
                          target="_self"
                        />
                      </Grid>
                    );
                  } else if (
                    teaserData.__typename == "ContentfulBlockContactBanner" &&
                    !teaserData.isHidden
                  ) {
                    return (
                      <Grid
                        key={`${teaserData.id}-${index}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        component="li"
                        className={classes.item}
                      >
                        <ContactBanner
                          data={teaserData}
                          className={classes.banner}
                        />
                      </Grid>
                    );
                  } else if (
                    teaserData.__typename ==
                      "ContentfulModuleContactBannerSection" &&
                    !teaserData.isHidden
                  ) {
                    return (
                      <Grid
                        key={`${teaserData.id}-${index}`}
                        item
                        xs={12}
                        component="li"
                        className={classes.item}
                      >
                        {!isMobile ? (
                          <ContactBannerSection data={teaserData} fullWidth />
                        ) : (
                          <ContactBanner
                            data={teaserData}
                            className={classes.banner}
                          />
                        )}
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Row>
    </Fragment>
  );
};

export default CasesPanel;
