import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MoreIcon } from "../../media";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import SimpleTextBlockIcons from "./simple-text-block-icons";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";

export const SimpleTextBlockQueryFragment = graphql`
  fragment SimpleTextBlock on ContentfulBlockSimpleText {
    id
    header
    text {
      childMarkdownRemark {
        html
      }
    }
    icons {
      id
      ...Media400
    }
    icon {
      ...Media400
    }
    iconSize
    link
    cta {
      ...Cta
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    width: "100%",
    height: "100%",
    textDecoration: "none",
    color: "inherit",
    "& $text h2": {
      fontSize: "1.78rem",
      lineHeight: 1.375
    }
  },
  hover: {
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.ignite_primary_2,
        color: theme.palette.text.secondary,
        "& .singleIcon": {
          filter: "invert(100%)"
        }
      }
    }
  },
  moreIcon: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    width: "25px",
    height: "25px"
  },
  text: {
    "& a": {
      display: "inline-block",
      fontWeight: "600",
      border: "none",
      textDecoration: "underline",
      "&:hover, &:active, &:focus": {
        border: "none",
        textDecoration: "underline"
      }
    }
  },
  centeredContent: {
    textAlign: "center"
  },
  textWrapper: {
    maxWidth: "400px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  whiteText: {
    color: theme.palette.text.secondary
  },
  button: {
    marginTop: theme.spacing(5)
  }
}));

const Wrapper = ({ url, hoverClass, className, ...props }) => {
  if (!!url) {
    return (
      <Link
        to={url}
        className={classNames(
          className,
          hoverClass,
          "gtm-simple-text-block-link"
        )}
        {...props}
      />
    );
  }

  return <div {...props} className={className} />;
};

const SimpleTextBlock = ({
  data: { text, icons, icon, url, iconSize, centered, cta },
  darkBackground,
  preserveFirstH1tag
}) => {
  icons = icons || [];
  const classes = useStyles();
  iconSize = iconSize || ["Small"];

  return (
    <Wrapper
      className={classNames(
        classes.root,
        !!centered && classes.centeredContent
      )}
      hoverClass={classes.hover}
      url={url}
    >
      <SimpleTextBlockIcons
        icon={icon}
        icons={icons}
        iconSize={iconSize[0]}
        centered={centered}
      />
      <RenderHtml
        html={text}
        className={classNames(
          classes.text,
          !!centered && classes.textWrapper,
          !!darkBackground && classes.whiteText
        )}
        preserveFirstH1tag={!!preserveFirstH1tag}
      />
      {!!cta && (
        <Button
          variant={!!darkBackground ? "secondaryWhite" : "primaryBlue"}
          href={cta.url}
          className={classNames(classes.button, cta.className)}
        >
          {cta.text}
        </Button>
      )}
      {!!url && <MoreIcon className={classes.moreIcon} />}
    </Wrapper>
  );
};

export default SimpleTextBlock;
