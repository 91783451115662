import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import Button from "../../buttons";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: "flex-start"
  },
  image: {
    width: 160
  },
  imageForHorizontal: {
    [theme.breakpoints.down("xs")]: {
      width: 160
    },
    [theme.breakpoints.up("sm")]: {
      height: 140
    }
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  text: {
    marginBottom: theme.spacing(3.75)
  },
  button: {
    textDecoration: "none"
  }
}));

const Badge = ({
  data: { badgeImage, header, text, cta },
  layout = "vertical"
}) => {
  const classes = useStyles();
  const isHorizontal = layout === "horizontal";

  return (
    <Grid container className={classes.container} spacing={6}>
      <Grid item>
        <Grid item xs={12} sm={2}>
          <Media
            source={badgeImage}
            className={
              isHorizontal ? classes.imageForHorizontal : classes.image
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={!isHorizontal && 8} lg={!isHorizontal && 9}>
        <Typography variant="h3" className={classes.header}>
          {header}
        </Typography>
        <RenderHtml html={text} className={classes.text} />
        {!!cta && (
          <Button
            variant="linkBlue"
            href={cta.url}
            className={classNames(
              classes.button,
              !!cta.className && cta.className
            )}
          >
            {cta.text}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Badge;
