import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import Services from "./Services";
import ClientCases from "./ClientCases";
import Partnerships from "./Partnerships";
import SocialMediaIcon from "./social-media-icon";
import Contact from "./Contact";
import NavigationItems from "./NavigationItems";

const useStyles = makeStyles(theme => ({
  linksWrapper: {
    "& a:nth-child(2) > p": {
      paddingTop: 0
    }
  },
  companyAddresTitle: {
    marginBottom: theme.spacing(5)
  },
  contact: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(16)
    },
    marginTop: theme.spacing(5)
  },
  partners: {
    marginTop: theme.spacing(5)
  },
  socialMediaIcons: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5)
    }
  }
}));

const FooterUK = ({
  data: { services, clientCases, companyAddresses, partners, socialMediaIcons },
  topNavigationItems
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <NavigationItems navigationItems={topNavigationItems} />
      <Grid item xs={12} sm={8}>
        <Grid
          container
          className={classes.linksWrapper}
          spacing={isMobile ? 10 : 5}
        >
          {!!services ? <Services services={services} /> : null}
          {!!clientCases ? <ClientCases clientCases={clientCases} /> : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={isMobile ? 10 : 5}>
          <Grid item xs={12} className={classes.contact}>
            <Typography variant="h4" className={classes.companyAddresTitle}>
              Contact
            </Typography>
            <Grid container spacing={isMobile ? 10 : 5}>
              {companyAddresses.map(address => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={address.id}>
                  <Contact companyAddress={address} />
                </Grid>
              ))}
              <Grid item xs={12} className={classes.partners}>
                <Partnerships partners={partners} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.socialMediaIcons}>
            {socialMediaIcons.map(icon => (
              <SocialMediaIcon data={icon} key={icon.id} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FooterUK;
