import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  line: {
    position: "absolute",
    top: "50%",
    left: 0,
    height: 1,
    width: 12,
    backgroundColor: theme.palette.text.primary,
    transition: "transform 200ms ease-out"
  },
  lineWhite: {
    backgroundColor: theme.palette.text.secondary
  },
  verticalLine: {
    transform: "rotate(90deg)"
  },
  iconWrapper: {
    position: "relative",
    width: 12,
    height: 12
  }
}));

const PlusMinusIcon = ({ icon = "minus", className, darkBackground }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      <span
        className={classNames(
          classes.line,
          darkBackground ? classes.lineWhite : null,
          className
        )}
      ></span>
      {icon === "plus" ? (
        <span
          className={classNames(
            classes.line,
            classes.verticalLine,
            darkBackground ? classes.lineWhite : null,
            className
          )}
        ></span>
      ) : null}
    </div>
  );
};

export default PlusMinusIcon;
