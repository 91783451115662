import React from "react";
import { Link } from "gatsby";
import { Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "& p": {
      padding: theme.spacing(0.375, 0)
    },
    "&:hover, &:active, &:focus, &:visited": {
      textDecoration: "underline"
    }
  },
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const ClientCases = ({ clientCases }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Link to="/cases" className={classes.link}>
        <Typography variant="h4" className={classes.title}>
          Client cases
        </Typography>
      </Link>
      {clientCases.map(item => {
        if (item.clientName) {
          return (
            <Link
              to={`/cases/${item.slug}`}
              className={classes.link}
              key={item.id}
            >
              <Typography variant="body1">{item.clientName}</Typography>
            </Link>
          );
        }
      })}
    </Grid>
  );
};

export default ClientCases;
