import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ContactObfusscator from "../contact-obfusscator";
import { RenderHtml } from "../../helpers";
import noWhiteSpaces from "../../helpers/no-white-spaces";
import ContactCompanyIcon from "./contact-company-icon";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  inlineHtmlContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2)
    },
    "& p": {
      marginBottom: 0,
      whiteSpace: "pre-wrap"
    },
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
      borderBottom: "none",
      "&:hover, &:active, &:focus": {
        color: theme.palette.text.primary,
        borderBottom: "none",
        textDecoration: "underline"
      }
    }
  },
  flexContainer: {
    display: "flex"
  },
  address: {
    marginBottom: theme.spacing(2)
  }
}));

const textStyles = {
  "& h3:not(:last-child)": {
    marginBottom: 20
  }
};

const ContactCompany = ({
  companyAddress,
  smColumns = 4,
  mdColumns = 4,
  className
}) => {
  const classes = useStyles();

  if (companyAddress.length > 0) {
    return (
      <Grid
        item
        xs={12}
        sm={smColumns}
        md={mdColumns}
        className={classNames(className, classes.inlineHtmlContainer)}
      >
        <div className={classes.flexContainer}>
          <ContactCompanyIcon icon={companyAddress[0].icon} />
        </div>
        <RenderHtml
          html={companyAddress[0].address}
          className={classes.address}
          styles={textStyles}
        />
        <Typography variant="body1">
          <a href={`tel:${noWhiteSpaces(companyAddress[0].phone)}`}>
            {companyAddress[0].phone}
          </a>
          <br />
          <ContactObfusscator email={companyAddress[0].email} />
        </Typography>
      </Grid>
    );
  } else {
    return <></>;
  }
};

export default ContactCompany;
