import get from "lodash/get";

const parse = markdown => {
  const text = get(markdown, "childMarkdownRemark.html", markdown);
  if (!text || !text.replace) {
    return "";
  }

  return text.replace(/\n/g, "\n"); //new line heights are difficult to control; try using CSS: white-space: pre-wrap
};

export default parse;
