import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import CrossIcon from "./cross-icon";
import {
  getBackgroundStyles,
  isDarkBackground
} from "../../layout/theme/palette";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  button: {
    position: "relative",
    background: theme.palette.background.blue,
    color: theme.palette.text.primary,
    padding: theme.spacing(3.5, 0),
    textDecoration: "none",
    border: "none",
    textAlign: "left",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2)
  },
  header: {
    marginRight: theme.spacing(4)
  },
  textWhite: {
    color: theme.palette.text.secondary
  }
}));

const AccordionItemHeader = ({
  header,
  onClick,
  isActive,
  backgroundColor
}) => {
  const classes = useStyles();
  const darkBackground = isDarkBackground(backgroundColor);

  return (
    <button
      onClick={onClick}
      className={classNames(
        classes.button,
        darkBackground ? classes.textWhite : null
      )}
      style={getBackgroundStyles(backgroundColor)}
    >
      <Typography variant="h3" className={classes.header}>
        {header}
      </Typography>
      <CrossIcon isActive={isActive} darkBackground={darkBackground} />
    </button>
  );
};

export default AccordionItemHeader;
