import React from "react";
import { graphql } from "gatsby";
import SimpleTextBlock from "./simple-text-block";
import { Grid, useTheme, useMediaQuery, makeStyles } from "@material-ui/core";
import Media from "../../media";
import classNames from "classnames";
import SectionContainer from "../../section-container";
import {
  getBackgroundStyles,
  isDarkBackground
} from "../../layout/theme/palette";
import SectionHeader from "../../section-header";

export const TwoColumnsSectionQueryFragment = graphql`
  fragment TwoColumnsSection on ContentfulModuleTwoColumns {
    id
    name
    isHidden
    centeredContent
    sectionHeader {
      ...SectionHeader
    }
    textBlocks {
      ... on ContentfulBlockSimpleText {
        ...SimpleTextBlock
      }
      ... on ContentfulBlockSimpleTextWithBackground {
        id
        text {
          ...SimpleTextBlock
        }
        backgroundColor
        __typename
      }
      ... on ContentfulModuleImage {
        id
        image {
          ...Media1000
        }
        __typename
      }
    }
    imageAboveTextOnMobile
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    padding: theme.spacing(12, 0)
  },
  centeredContent: {
    margin: "0 auto"
  },
  sectionHeader: {
    marginBottom: theme.spacing(10)
  },
  blockPadding: {
    padding: theme.spacing(2.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6.25)
    }
  },
  flexContainer: {
    display: "flex"
  },
  blockWrapper: {
    width: `${theme.contentSizes.maxContentWidth / 2}px`,
    padding: theme.spacing(6.25),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.5)
    }
  },
  image: {
    height: "100%"
  },
  blockOrder: {
    order: 1
  },
  blockOrder0Mobile: {
    [theme.breakpoints.down("xs")]: {
      order: 0
    }
  },
  blockOrder2Mobile: {
    [theme.breakpoints.down("xs")]: {
      order: 2
    }
  }
}));
const TwoColumnsSection = ({
  data: {
    sectionHeader,
    textBlocks,
    imageAboveTextOnMobile,
    centeredContent,
    sectionProperties
  }
}) => {
  textBlocks = textBlocks || [];
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const fullWidthSection = textBlocks.some(
    block => block.__typename === "ContentfulBlockSimpleTextWithBackground"
  );
  const allElementsAreBlockSimpleTextWithBackground = textBlocks.every(
    block => block.__typename === "ContentfulBlockSimpleTextWithBackground"
  );
  const imageCanBeCropped =
    !allElementsAreBlockSimpleTextWithBackground &&
    textBlocks.some(
      block => block.__typename === "ContentfulBlockSimpleTextWithBackground"
    ) &&
    textBlocks.some(block => block.__typename === "ContentfulModuleImage");

  if (allElementsAreBlockSimpleTextWithBackground) {
    return (
      <Grid container>
        {textBlocks.map((block, index) => {
          const { text, backgroundColor } = block;
          const backgroundStyles = getBackgroundStyles(backgroundColor);
          const darkBackground = isDarkBackground(backgroundColor);
          return (
            <Grid
              item
              key={block.id}
              xs={12}
              md={6}
              style={{
                ...backgroundStyles,
                justifyContent: index % 2 === 0 ? "flex-end" : "flex-start"
              }}
              className={classes.flexContainer}
            >
              <Grid item className={classes.blockWrapper}>
                <SimpleTextBlock
                  data={text}
                  darkBackground={!!darkBackground}
                  preserveFirstH1tag
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  } else
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        backgroundColor={
          sectionProperties?.backgroundColor?.color
            ? sectionProperties.backgroundColor.color
            : !!centeredContent && "gray"
        }
        className={classNames(!sectionProperties ? classes.row : null)}
        noPadding={!sectionProperties}
        fullWidth={!!fullWidthSection}
      >
        {!!sectionHeader && (
          <Grid item xs={12} sm={6} className={classes.sectionHeader}>
            <SectionHeader data={sectionHeader} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={!!centeredContent && 7}
          className={!!centeredContent ? classes.centeredContent : null}
        >
          <Grid
            container
            spacing={!fullWidthSection ? (isMobile ? 4 : 5) : null}
            className={classes.flexContainer}
            style={!imageCanBeCropped ? { alignItems: "flex-start" } : null}
          >
            {textBlocks.map((block, i) => {
              if (block.__typename === "ContentfulBlockSimpleText") {
                return (
                  <Grid
                    item
                    key={block.id}
                    xs={12}
                    sm={6}
                    className={classNames(
                      !!fullWidthSection ? classes.blockPadding : null,
                      classes.blockOrder
                    )}
                  >
                    <SimpleTextBlock data={block} />
                  </Grid>
                );
              } else if (
                block.__typename === "ContentfulBlockSimpleTextWithBackground"
              ) {
                const { text, backgroundColor } = block;
                const backgroundStyles = getBackgroundStyles(backgroundColor);
                const darkBackground = isDarkBackground(backgroundColor);
                return (
                  <Grid
                    item
                    key={block.id}
                    xs={12}
                    sm={6}
                    style={backgroundStyles}
                    className={classNames(
                      classes.blockPadding,
                      classes.blockOrder
                    )}
                  >
                    <SimpleTextBlock
                      data={text}
                      darkBackground={!!darkBackground}
                      preserveFirstH1tag
                    />
                  </Grid>
                );
              } else if (block.__typename === "ContentfulModuleImage") {
                return (
                  <Grid
                    key={block.id}
                    item
                    xs={12}
                    sm={6}
                    className={classNames(
                      classes.blockOrder,
                      !!imageAboveTextOnMobile
                        ? classes.blockOrder0Mobile
                        : imageAboveTextOnMobile === null
                        ? classes.blockOrder
                        : classes.blockOrder2Mobile
                    )}
                  >
                    <Media source={block.image} className={classes.image} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      </SectionContainer>
    );
};
export default TwoColumnsSection;
