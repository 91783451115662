const truncateText = (text, maxLength, useWordBoundary) => {
  if (text.length <= maxLength) {
    return text;
  }
  const subString = text.substr(0, maxLength - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
};

export default truncateText;
