import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { getContainerStyles } from "../layout/_components/container";
import {
  getBackgroundColorByName,
  isDarkBackground
} from "../layout/theme/palette";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden"
  },
  overflowVisible: {
    [theme.breakpoints.up("md")]: {
      overflow: "visible"
    }
  },
  paddingTop: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(22.5)
    }
  },
  paddingBottom: {
    paddingBottom: theme.spacing(12.5),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(22.5)
    }
  },
  padding: {
    padding: theme.spacing(10, 0, 12.5, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(22.5, 0, 22.5, 0)
    }
  },
  grid: {
    ...getContainerStyles(theme)
  },
  darkBackground: {
    color: theme.palette.text.secondary,
    "& p a": {
      color: theme.palette.text.secondary,
      "&:hover": {
        color: theme.palette.text.secondary
      }
    },
    "& form": {
      "& label": {
        color: theme.palette.text.secondary,
        "&.Mui-focused": {
          color: theme.palette.text.secondary
        },
        "&.Mui-error": {
          color: theme.palette.text.link
        }
      },
      "& .MuiInputBase-root ": {
        background: theme.palette.background.white
      },
      "& .MuiButton-root": {
        color: theme.palette.text.secondary,
        border: `2px solid ${theme.palette.background.white}`,
        "&:hover": {
          background: theme.palette.background.white,
          color: theme.palette.text.primary
        }
      },
      "& .MuiFormControlLabel-label a": {
        color: theme.palette.text.secondary
      }
    }
  },
  hidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  fullWidth: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const Row = ({
  children,
  backgroundColor,
  backgroundImage,
  noPadding,
  noPaddingTop,
  noPaddingBottom,
  fullWidth,
  overflowVisible,
  className,
  ...props
}) => {
  const classes = useStyles();

  let backgroundClass = "";
  let inlineStyles = null;
  if (isDarkBackground(backgroundColor)) {
    backgroundClass = classes.darkBackground;
  }
  inlineStyles = {
    backgroundColor: getBackgroundColorByName(backgroundColor)
  };

  const gridContainerInlineStyles = {
    backgroundSize: "cover",
    backgroundImage: !!backgroundImage
      ? `url(${backgroundImage.file.url}?q=90)`
      : null
  };

  let paddingClass = classes.padding;
  if (noPadding || (noPaddingTop && noPaddingBottom)) {
    paddingClass = "";
  } else if (noPaddingTop) {
    paddingClass = classes.paddingBottom;
  } else if (noPaddingBottom) {
    paddingClass = classes.paddingTop;
  }

  return (
    <div
      className={classNames(
        classes.root,
        !!overflowVisible && classes.overflowVisible,
        backgroundClass,
        className
      )}
      style={inlineStyles}
      {...props}
    >
      <Grid
        container
        spacing={0}
        classes={{
          root: classNames(
            classes.grid,
            !noPadding ? paddingClass : null,
            fullWidth ? classes.fullWidth : null
          )
        }}
        style={gridContainerInlineStyles}
      >
        {children}
      </Grid>
    </div>
  );
};

export default Row;
