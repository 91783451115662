import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import Card from "./card";

const Cards = ({ cards, columns = 2, sectionHeader }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const smCardWidth = Math.floor(12 / columns);

  return (
    <Grid container spacing={isMobile ? 8 : 5}>
      {cards.map(card => (
        <Grid
          item
          xs={12}
          sm={cards.length <= 1 ? 12 : !sectionHeader ? 6 : smCardWidth}
          md={cards.length <= 1 ? 9 : smCardWidth}
          key={card.id}
        >
          <Card
            image={card.image}
            logo={card.logo}
            header={card.cardHeader}
            subheader={card.subheader}
            divider={card.divider}
            text={card.text}
            cta={card.cta}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
