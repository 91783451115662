const getNumberOfColumns = length => {
  let columns = 0;
  if (length === 1 || length === 2) {
    columns = 6;
  } else if (length === 3) {
    columns = 4;
  } else if (length >= 4) {
    columns = 3;
  }

  return columns;
};

export default getNumberOfColumns;
