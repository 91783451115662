import React from "react";
import { graphql } from "gatsby";
import SectionContainer from "../../section-container";
import { Grid, makeStyles } from "@material-ui/core";
import { RenderHtml } from "../../../helpers";
import Media from "../../media";
import Divider from "../../divider";
import className from "classnames";

export const TechnologyHighlightsQueryFragment = graphql`
  fragment TechnologyHighlights on ContentfulModuleTechnologyHighlights {
    header {
      childMarkdownRemark {
        html
      }
    }
    technologies {
      id
      image {
        ...Media200
      }
      subheader {
        childMarkdownRemark {
          html
        }
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(9)
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    height: 40
  },
  logoWrapperForOnePieceSection: {
    alignItems: "flex-start",
    "& img": {
      height: "100%"
    }
  },
  logoWrapperForMultiElementSection: {
    alignItems: "center",
    width: 150,
    marginBottom: theme.spacing(5)
  },
  logo: {
    flexGrow: 1
  }
}));

const TechnologyHighlights = ({
  data: { header, technologies, sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={9}>
            <Divider height={1} className={classes.divider} />
            <RenderHtml html={header} className={classes.header} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={10}>
              {technologies.map(technology => {
                if (technologies.length === 1) {
                  return (
                    <Grid item xs={12} sm={10} key={technology.id}>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={4} lg={3}>
                          <div
                            className={className(
                              classes.logoWrapper,
                              classes.logoWrapperForOnePieceSection
                            )}
                          >
                            <Media source={technology.image} />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RenderHtml html={technology.subheader} />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={technologies.length === 2 ? 6 : 4}
                      key={technology.id}
                    >
                      <div
                        className={className(
                          classes.logoWrapper,
                          classes.logoWrapperForMultiElementSection
                        )}
                      >
                        <Media
                          source={technology.image}
                          className={classes.logo}
                        />
                      </div>
                      <RenderHtml html={technology.subheader} />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
};

export default TechnologyHighlights;
