import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { graphql } from "gatsby";
import React, { useState } from "react";
import { Row } from "../../grid";
import Form from "./contact-form";
import ContactFormsSectionTabLabel from "./contact-forms-section-tab-label";
import TabPanel from "./contact-forms-section-tab-panel";
import { useQueryParam, StringParam } from "use-query-params";

export const ContactFormsSectionQueryFragment = graphql`
  fragment ContactFormsSection on ContentfulModuleContactFormsSection {
    name: name
    forms {
      ...ContactForm
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(8)
    }
  },
  tab: {
    textTransform: "none",
    opacity: 1,
    color: theme.palette.ignite_primary_1,
    background: theme.palette.functional_grey_4,
    "&.Mui-selected": {
      color: "#fff",
      background: theme.palette.ignite_primary_1,
      opacity: 1,
      cursor: "default"
    },
    "&.MuiButtonBase-root > .MuiTab-wrapper": {
      alignItems: "flex-start",
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1),

      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3)
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(6)
      }
    }
  },
  tabIndicator: {
    display: "none"
  }
}));

const ContactFormsSection = ({ data }) => {
  const classes = useStyles();

  const [activeFormType, setActiveFormType] = useQueryParam(
    "form",
    StringParam
  );

  const formTypes = data.forms.map(form => form.formType);

  const [tabValue, setTabValue] = useState(
    !!activeFormType && formTypes.includes(activeFormType)
      ? data.forms.findIndex(form => form.formType === activeFormType)
      : 0
  );

  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
    setActiveFormType(formTypes[newTabValue]);
  };

  function a11yProps(index) {
    return {
      id: `forms-tab-${index}`,
      "aria-controls": `forms-tabpanel-${index}`
    };
  }

  if (data.isHidden) {
    return <></>;
  } else {
    return (
      <Row className={classes.container}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="forms tabs"
            className={classes.tabs}
            variant="fullWidth"
            TabIndicatorProps={{
              classes: {
                root: classes.tabIndicator
              }
            }}
          >
            {data.forms.map((formData, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <ContactFormsSectionTabLabel
                      text={formData.name}
                      iconDark={formData.iconDark}
                      iconLight={formData.iconLight}
                      active={
                        !!activeFormType && formTypes.includes(activeFormType)
                          ? index === tabValue &&
                            activeFormType === formData.formType
                          : index === tabValue
                      }
                    />
                  }
                  {...a11yProps(index)}
                  className={classes.tab}
                  disableRipple
                />
              );
            })}
          </Tabs>
          {data.forms.map((formData, index) => {
            return (
              <TabPanel value={tabValue} index={index} key={formData.id}>
                <Form data={formData} />
              </TabPanel>
            );
          })}
        </Grid>
      </Row>
    );
  }
};

export default ContactFormsSection;
