import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import { RenderHtml } from "../../../helpers";
import Media from "../../media";
import SectionContainer from "../../section-container";
import Divider from "../../divider";

export const ClientsSectionQueryFragment = graphql`
  fragment ClientsSection on ContentfulModuleClientsSection {
    name
    isHidden
    header {
      childMarkdownRemark {
        html
      }
    }
    logos {
      id
      ...Media200
    }
    divider
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.primary
  },
  header: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3)
    }
  },
  divider: {
    marginTop: -20
  },
  imagesContainer: {
    [theme.breakpoints.down("xs")]: {
      "& > div:nth-child(3n+1)": {
        justifyContent: "flex-start"
      },
      "& div:nth-child(3n)": {
        justifyContent: "flex-end"
      }
    },
    [theme.breakpoints.up("sm")]: {
      "& > div:nth-child(4n+1)": {
        justifyContent: "flex-start"
      },
      "& div:nth-child(4n)": {
        justifyContent: "flex-end"
      }
    },
    [theme.breakpoints.up("lg")]: {
      "& > div:nth-child(n)": {
        justifyContent: "center"
      },
      "& > div:nth-child(6n+1)": {
        justifyContent: "flex-start"
      },
      "& div:nth-child(6n)": {
        justifyContent: "flex-end"
      }
    }
  },

  logoContainer: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logoWrapper: {
    width: 160,
    height: 110,
    [theme.breakpoints.down("xs")]: {
      height: 80
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  logo: {
    maxHeight: "100%",
    maxWidth: "100%",
    flexGrow: 1
  }
}));

const ClientsSection = ({
  data: { header, logos, divider, sectionProperties, isHidden }
}) => {
  const classes = useStyles();
  const theme = useTheme();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        className={classes.container}
      >
        <Grid item container xs={12}>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12}>
              <RenderHtml
                html={header}
                className={!!divider ? null : classes.header}
              />
            </Grid>
            {!!divider && (
              <Grid item xs={12}>
                <Divider
                  height={1}
                  color={theme.palette.background.blue}
                  className={classes.divider}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.imagesContainer} spacing={5}>
                {!!logos
                  ? logos.map(logo => (
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        lg={2}
                        key={logo.id}
                        className={classes.logoContainer}
                      >
                        <div className={classes.logoWrapper}>
                          <Media
                            source={logo}
                            className={classes.logo}
                            objectFit="contain"
                          />
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default ClientsSection;
