import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.functional_grey_3}`,
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4)
    }
  }
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`forms-tabpanel-${index}`}
      aria-labelledby={`forms-tab-${index}`}
      className={classes.container}
      {...other}
    >
      {value === index ? <div>{children}</div> : null}
    </div>
  );
};

export default TabPanel;
