import { Grid, makeStyles } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import SectionContainer from "../../section-container";
import Badge from "./badge";

export const BadgeSectionQueryFragment = graphql`
  fragment BadgeSection on ContentfulModuleBadgeSection {
    badges {
      id
      badgeImage {
        ...Media200
      }
      header
      text {
        childMarkdownRemark {
          html
        }
      }
      cta {
        ...Cta
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6.25)
    },
    marginBottom: theme.spacing(11.25)
  },
  marginBadge: {
    marginBottom: theme.spacing(15)
  },
  marginBottomBadge: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6.25)
    },
    marginBottom: theme.spacing(11.25)
  }
}));

const BadgeSection = ({ data: { badges, sectionProperties, isHidden } }) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer
        noPaddingBottom={badges.length > 2 ? true : false}
        sectionProperties={sectionProperties}
        className={badges.length > 2 ? classes.container : null}
      >
        <Grid container spacing={badges.length > 2 ? 5 : 0}>
          {badges.length > 2
            ? badges.map(badge => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={badge.id}
                    className={classes.marginBottomBadge}
                  >
                    <Badge data={badge} layout="horizontal" />
                  </Grid>
                );
              })
            : badges.map((badge, i) => {
                return (
                  <React.Fragment key={badge.id}>
                    <Grid item sm={1} md={2}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      md={8}
                      className={
                        i !== badges.length - 1 ? classes.marginBadge : null
                      }
                    >
                      <Badge data={badge} />
                    </Grid>
                    <Grid item sm={1} md={2}></Grid>
                  </React.Fragment>
                );
              })}
        </Grid>
      </SectionContainer>
    );
};

export default BadgeSection;
