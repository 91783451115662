export default tags => {
  pushToDataLayer({
    tags: [...tags].map(x => x.name).join(",")
  });
};

export function pushToDataLayer(data) {
  if (!window) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}
