import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { RenderHtml, DoNotBreakOnHyphens } from "../../helpers";
import Row from "../grid/row";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  row: {
    padding: theme.spacing(10, 0, 3.75, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(20, 0)
    }
  },
  header: {
    marginBottom: "30px"
  },
  textContainer: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  articleBody: {
    "& h2": {
      fontSize: "1.764rem",
      fontWeight: "normal",
      lineHeight: "1.3",
      marginTop: "0.83em",
      marginBottom: "0.83em"
    }
  }
}));

const ArticlePageSection = ({ data: { name, body } }) => {
  const classes = useStyles();

  return (
    <Row noPadding className={classes.row}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={9} md={8} className={classes.textContainer}>
            <Typography variant="h1" className={classes.header}>
              <DoNotBreakOnHyphens text={name} />
            </Typography>
            <RenderHtml html={body} className={classes.articleBody} />
          </Grid>
        </Grid>
      </Grid>
    </Row>
  );
};

export default ArticlePageSection;
