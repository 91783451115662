import React from "react";
import Media from "./Media";
import { StaticQuery, graphql } from "gatsby";

const RemoveIcon = props => (
  <StaticQuery
    query={graphql`
      query RemoveIconQuery {
        icon: contentfulAsset(contentful_id: { eq: "3OIaXyj79s3lqB9XISwUr8" }) {
          ...Media200
        }
        __typename
      }
    `}
    render={data => <Media source={data.icon} {...props} />}
  />
);

export default RemoveIcon;
