import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import SectionHeader from "../../section-header";
import { ContactForm } from "../../contact-form";
import SectionContainer from "../../section-container";
import ContactCompany from "../../contact-company";
import HubspotForm from "../../hubspot-form";
import { isDarkBackground } from "../../layout/theme/palette";

export const ContactUsSectionQueryFragment = graphql`
  fragment ContactUsSection on ContentfulModuleContactUsSection {
    name
    isHidden
    sectionHeader {
      ... on ContentfulBlockSectionHeader {
        header {
          childMarkdownRemark {
            html
          }
        }
        subheader {
          childMarkdownRemark {
            html
          }
        }
        cta {
          ...Cta
        }
        __typename
      }
      ... on ContentfulBlockCompanyAddress {
        name
        address {
          childMarkdownRemark {
            html
          }
        }
        phone
        email
        icon {
          ...Media200
        }
        __typename
      }
    }
    contactForm {
      ...ContactForm
      ...HubspotForm
    }
    sectionHeaderIsHidden
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  address: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  button: {
    width: "auto"
  }
}));

const ContactUsSection = ({
  data: {
    sectionHeader,
    contactForm,
    sectionHeaderIsHidden,
    sectionProperties,
    isHidden
  }
}) => {
  const classes = useStyles();
  const darkBackground = isDarkBackground(
    sectionProperties?.backgroundColor?.color
  );

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container spacing={4}>
          {sectionHeader.__typename === "ContentfulBlockSectionHeader" ? (
            <Grid item xs={12} sm={4}>
              <SectionHeader data={sectionHeader} />
            </Grid>
          ) : null}
          {!sectionHeaderIsHidden ? (
            sectionHeader.__typename === "ContentfulBlockCompanyAddress" && (
              <ContactCompany
                companyAddress={[sectionHeader]}
                className={classes.address}
              />
            )
          ) : (
            <Grid item sm={4} className={classes.hiddenOnMobile}></Grid>
          )}
          <Grid item xs={12} sm={8} md={6}>
            {contactForm.__typename === "ContentfulModuleContactForm" && (
              <ContactForm data={contactForm} darkBackground={darkBackground} />
            )}
            {contactForm.__typename === "ContentfulBlockHubspotForm" && (
              <HubspotForm
                data={contactForm}
                buttonStyles={classes.button}
                buttonVariant="primaryBlue"
              />
            )}
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default ContactUsSection;
