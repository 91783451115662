import React from "react";
import { graphql } from "gatsby";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Media from "../../media";
import { RenderHtml } from "../../../helpers";
import classNames from "classnames";
import SectionContainer from "../../section-container";

export const BannerWithBackgroundImageQueryFragment = graphql`
  fragment BannerWithBackgroundImage on ContentfulModuleBannerWithBackgroundImage {
    text {
      childMarkdownRemark {
        html
      }
    }
    backgroundImage {
      ...MediaFullWidth
    }
    sectionProperties {
      ...SectionProperties
    }
    isHidden
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(7.5, 2.5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(20, 6.25)
    }
  },
  position: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 0
  },
  overlay: { backgroundColor: "rgba(0, 25, 50, 0.7)", zIndex: 1 },
  textWrapper: {
    zIndex: 2,
    margin: "0 auto",
    textAlign: "center"
  }
}));

const BannerWithBackgroundImage = ({
  data: { text, backgroundImage, sectionProperties, isHidden }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else {
    return (
      <SectionContainer sectionProperties={sectionProperties}>
        <Grid container className={classes.container}>
          <Media source={backgroundImage} className={classes.position} />
          <Grid
            item
            className={classNames(classes.overlay, classes.position)}
          ></Grid>
          <Grid item className={classes.textWrapper}>
            <RenderHtml html={text} />
          </Grid>
        </Grid>
      </SectionContainer>
    );
  }
};

export default BannerWithBackgroundImage;
