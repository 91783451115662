import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import validate from "../helpers/form-validate";
import SendFormChecker from "./send-form-checker";
import { pushToDataLayer, RenderHtml } from "../../../helpers";
import pushFbPixelEvent from "../../../helpers/fb-pixel-events";
import ContactFormInput from "./contact-form-input";
import ContactFormCheckbox from "./contact-form-checkbox";
import Button from "../../buttons";
import getCookie from "../../../helpers/get-cookie";
import fetchDisallowedEmailDomains from "../helpers/fetch-disallowed-email-domains";
import getUserIpAddress from "../../../helpers/get-user-ip-address";
import {
  getCurrentPageName,
  getCurrentUrl
} from "../../../helpers/get-current-url";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

export const ContactFormQueryFragment = graphql`
  fragment ContactForm on ContentfulModuleContactForm {
    id
    name
    header
    subheader {
      childMarkdownRemark {
        html
      }
    }
    formUrl
    attachmentUploadUrl
    apiKey
    formType
    hubspotFormId
    redirectOnSubmitUrl
    privacyPolicy {
      childMarkdownRemark {
        html
      }
    }
    marketingConsent {
      childMarkdownRemark {
        html
      }
    }
    successfulSend
    failSend
    formFields {
      id
      name
      type
      label
      labelIcon {
        ...Media200
      }
      required
      placeholder
      width
      multiline
      rows
      rowsMax
    }
    submitButtonText
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    maxWidth: "300px",
    marginBottom: theme.spacing(3),
    ["@media (min-width:450px)"]: {
      maxWidth: "none"
    }
  },
  subheader: {
    marginBottom: theme.spacing(5)
  },
  wrapper: {
    position: "relative",
    fontFamily: "Aften Screen"
  },
  submitWrapper: {
    marginTop: theme.spacing(2)
  },
  helperText: {
    color: theme.palette.text.primary,
    position: "absolute",
    bottom: "-15px"
  }
}));

const ContactForm = ({ data, location, darkBackground }) => {
  const [disallowedEmailDomains, setDisallowedEmailDomains] = useState(null);
  const [
    isLoadingDisallowedEmailDomains,
    setIsLoadingDisallowedEmailDomains
  ] = useState(false);

  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);

  const [files, setFiles] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);

  const classes = useStyles();

  const requiredFieldsList = data.formFields.map(field => {
    return { [field.name]: !!field.required };
  });
  const requiredFieldsObj = Object.assign({}, ...requiredFieldsList);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      email: "",
      message: "",
      company: "",
      privacy: false
    },
    resolver: values =>
      validate(values, requiredFieldsObj, disallowedEmailDomains)
  });

  const watchEmail = watch("email");

  useEffect(() => {
    const getDisallowedEmailDomains = async () => {
      setIsLoadingDisallowedEmailDomains(true);
      const result = await fetchDisallowedEmailDomains(data.hubspotFormId);
      setIsLoadingDisallowedEmailDomains(false);
      setDisallowedEmailDomains(result);
    };

    if (
      watchEmail &&
      watchEmail !== "" &&
      disallowedEmailDomains == null &&
      !isLoadingDisallowedEmailDomains
    ) {
      getDisallowedEmailDomains();
    }
  }, [watchEmail]);

  const onSubmit = async values => {
    setSendSuccess(false);
    setSendError(false);

    values.message = values.message.replace(/\n/g, "<br />");
    values.files = files;
    values.formType = data.formType;

    const hutk = getCookie("hubspotutk");
    const ipAddress = await getUserIpAddress();
    const currentUrl = getCurrentUrl(location);
    const currentPageName = getCurrentPageName(location);

    const hubspotData = {
      fields: [
        {
          name: "email",
          value: values.email
        },
        {
          name: "firstname",
          value: values.name
        },
        {
          name: "company",
          value: values.company
        },
        {
          name: "message",
          value: values.message
        }
      ],
      context: {
        hutk: hutk,
        pageName: currentPageName,
        pageUri: currentUrl,
        ipAddress: ipAddress
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: values.privacy,
          text:
            "I agree to allow NoA Ignite to store and process my personal data.",
          communications: [
            {
              value: values.marketingConsent || false,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from NoA Ignite."
            }
          ]
        }
      }
    };

    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.HUBSPOT_PORTAL_ID}/${data.hubspotFormId}`,
      data: JSON.stringify(hubspotData),
      headers: {
        "Content-Type": "application/json"
      }
    });
    axios({
      method: "post",
      url: data.formUrl,
      data: values,
      headers: {
        "Ocp-Apim-Subscription-Key": data.apiKey
      }
    })
      .then(() => {
        if (values.formType === "contact") {
          pushToDataLayer({
            event: "mw-contact-form-sent",
            attachment_added: values.files.length > 0 ? "true" : "false"
          });
        } else {
          pushToDataLayer({
            event: "mw-consultation-form-sent"
          });
        }
        pushFbPixelEvent("formularz");
        setSendSuccess(true);
        if (!!data.redirectOnSubmitUrl) {
          navigate(data.redirectOnSubmitUrl);
        }
        reset();
        setFiles([]);
      })
      .catch(() => {
        setSendError(true);
      });
  };

  return (
    <Grid item xs={12}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        {data.header || data.subheader ? (
          <Grid container spacing={4}>
            {data.header ? (
              <Grid item xs={12} sm={8}>
                <Typography variant="h2">{data.header}</Typography>
              </Grid>
            ) : null}
            {data.subheader ? (
              <Grid item xs={12} lg={8}>
                <RenderHtml
                  html={data.subheader}
                  className={classes.subheader}
                />
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        <Grid container spacing={4}>
          {data.formFields.map(formField => (
            <Grid
              item
              className={classes.wrapper}
              key={formField.name}
              xs={12}
              sm={formField.width === "half" ? 6 : 12}
            >
              <div>
                <ContactFormInput
                  type={formField.type}
                  name={formField.name}
                  label={formField.label}
                  placeholder={formField.placeholder}
                  multiline={formField.multiline}
                  rows={formField.rows}
                  rowsMax={formField.rowsMax}
                  files={files}
                  setFiles={setFiles}
                  fileUploading={fileUploading}
                  setFileUploading={setFileUploading}
                  attachmentUploadUrl={data.attachmentUploadUrl}
                  apiKey={data.apiKey}
                  labelIcon={formField.labelIcon}
                  required={formField.required}
                  control={control}
                />
              </div>
            </Grid>
          ))}
          <Grid
            container
            item
            xs={12}
            className={classes.wrapper}
            alignItems="center"
          >
            <ContactFormCheckbox
              name="privacy"
              htmlText={data.privacyPolicy}
              control={control}
              required
              darkBackground={darkBackground}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.submitWrapper} spacing={4}>
          <Grid item xs={12}>
            <Button
              variant={!!darkBackground ? "primaryWhite" : "primaryBlue"}
              type="submit"
              disabled={fileUploading || isSubmitting}
            >
              {data.submitButtonText || "Send your message"}
            </Button>
          </Grid>
          {!!sendSuccess || !!sendError ? (
            <Grid item xs={12}>
              <SendFormChecker
                success={sendSuccess}
                error={sendError}
                {...data}
              />
            </Grid>
          ) : null}
        </Grid>
      </form>
    </Grid>
  );
};

export default ContactForm;
