import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { isAbsoluteUrl } from "../../../helpers";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  link: {},
  primary: {
    color: theme.palette.text.primary
  }
}));

const CustomLink = ({
  children,
  link,
  target = "_blank",
  variant = "primary",
  className
}) => {
  const classes = useStyles();
  const isAbsolute = isAbsoluteUrl(link);

  if (isAbsolute) {
    return (
      <a
        href={link}
        className={classNames(classes.link, classes[variant], className)}
        target={target}
        rel="noopener"
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={link}
        className={classNames(classes.link, classes[variant], className)}
      >
        {children}
      </Link>
    );
  }
};

export default CustomLink;
