import axios from "axios";

const fetchDisallowedEmailDomains = async hubspotFormId => {
  let result;
  if (hubspotFormId) {
    await axios({
      method: "post",
      url: `https://prod-145.westeurope.logic.azure.com:443/workflows/52add09512b149989d941873a402d383/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QhyQBLa8ybSujj9VsbO3IwNFl0Iq55JUA4o9vfAMFEQ`,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        formId: hubspotFormId
      })
    }).then(function(response) {
      const responseData = response.data.response;
      const emailField = responseData.formFieldGroups.find(
        group => group.fields[0].name === "email"
      );
      const validationData = emailField.fields[0].validation.data;
      const disallowedEmailDomains = validationData
        ? validationData.replaceAll(" ", "").split(",")
        : [];
      result = disallowedEmailDomains;
    });
  }

  return result;
};

export default fetchDisallowedEmailDomains;
