import palette from "./palette";

const hRelatedProperties = {
  "& a, & a:hover,& a:active,& a:focus": {
    color: palette.ignite_primary_2,
    textDecoration: "underline"
  }
};

const baseProperties = {
  "& a, & a:hover,& a:active,& a:focus": {
    color: palette.ignite_primary_2,
    textDecoration: "underline"
  },
  "& p": {
    margin: 0
  }
};

export default {
  useNextVariants: true,
  fontFamily: ["Aften Screen", "Arial", "Helvetica", "sans-serif"].join(","),
  htmlFontSize: 18,
  h1: {
    fontSize: "3.33rem",
    "@media (max-width: 767px)": {
      fontSize: "2.8rem"
    },
    lineHeight: 1.17,
    fontWeight: "normal",
    letterSpacing: "normal",
    ...baseProperties,
    ...hRelatedProperties
  },
  h2: {
    fontSize: "2.22rem",
    "@media (max-width: 767px)": {
      fontSize: "2rem"
    },
    lineHeight: 1.375,
    fontWeight: "normal",
    letterSpacing: "normal",
    ...baseProperties,
    ...hRelatedProperties
  },
  h3: {
    fontSize: "1.78rem",
    "@media (max-width: 767px)": {
      fontSize: "1.6rem"
    },
    lineHeight: 1.375,
    fontWeight: "normal",
    letterSpacing: "normal",
    ...baseProperties,
    ...hRelatedProperties
  },
  h4: {
    fontSize: "1.33rem",
    "@media (max-width: 767px)": {
      fontSize: "1.2rem"
    },
    lineHeight: 1.46,
    ...baseProperties,
    ...hRelatedProperties
  },
  h5: {
    fontSize: "1.33rem",
    lineHeight: 1.46,
    fontWeight: "normal",
    letterSpacing: "normal",
    ...baseProperties,
    ...hRelatedProperties
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.56,
    ...baseProperties,
    fontFamily: ['"Aften Screen"']
  }
};
