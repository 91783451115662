import React from "react";
import "../../assets/styles/base.css";
import { ResponsiveProvider } from "react-responsive-context";
import Layout from "./Layout";

const LayoutResponsiveWrapper = props => {
  const sizes = {
    small: [-Infinity, 500],
    medium: [501, 1000],
    large: [1001, +Infinity]
  };

  if (typeof window === `undefined`) {
    return <Layout {...props} />;
  }

  return (
    <ResponsiveProvider sizes={sizes}>
      <Layout {...props} />
    </ResponsiveProvider>
  );
};

export default LayoutResponsiveWrapper;
