import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { graphql } from "gatsby";
import { Row } from "../../grid";
import SectionHeader from "../../section-header";
import Video from "../../video";
import { RenderHtml } from "../../../helpers";
import HubspotForm from "../../hubspot-form";
import SectionContainer from "../../section-container";
import VideoEmbed from "../../video-embed";

export const VideoSectionQueryFragment = graphql`
  fragment VideoSection on ContentfulModuleVideoSection {
    name
    isHidden
    sectionHeader {
      ...SectionHeader
    }
    videoUrl
    video {
      ...MediaFullWidth
    }
    videoPlaceholderImage {
      ...MediaFullWidth
    }
    fullWidthVideo
    removeSidePadding
    addControlsToVideo
    loopVideo
    sectionHeaderHidden
    text {
      childMarkdownRemark {
        html
      }
    }
    form {
      ...HubspotForm
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  textWrapper: {
    marginTop: theme.spacing(3)
  }
}));

const VideoSection = ({
  data: {
    sectionHeader,
    videoUrl,
    video,
    videoPlaceholderImage,
    fullWidthVideo,
    removeSidePadding,
    addControlsToVideo,
    loopVideo,
    sectionHeaderHidden,
    text,
    form,
    sectionProperties,
    isHidden
  }
}) => {
  const classes = useStyles();

  if (isHidden) {
    return <></>;
  } else
    return (
      <SectionContainer
        sectionProperties={sectionProperties}
        className={classes.row}
        fullWidth={!!removeSidePadding}
      >
        <Grid container spacing={5}>
          {!(fullWidthVideo && sectionHeaderHidden) ? (
            <Grid item xs={12} md={4}>
              <SectionHeader data={sectionHeader} />
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            md={!!fullWidthVideo ? 12 : 8}
            lg={!!fullWidthVideo ? 12 : 6}
          >
            {!!videoUrl ? (
              <VideoEmbed url={videoUrl} fullWidthVideo={!!fullWidthVideo} />
            ) : !!video ? (
              <Video
                url={video.file.url}
                videoPlaceholderImageUrl={
                  videoPlaceholderImage?.fullWidth.images.fallback.src
                }
                controls={addControlsToVideo}
                loopVideo={loopVideo}
              />
            ) : null}
          </Grid>
          {!!text || !!form ? (
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.hiddenOnMobile}
                ></Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <Grid container spacing={4}>
                    {!!text ? (
                      <Grid item xs={12} className={classes.textWrapper}>
                        <RenderHtml html={text} />
                      </Grid>
                    ) : null}
                    {!!form ? (
                      <Grid item xs={12} md={8}>
                        <HubspotForm data={form} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </SectionContainer>
    );
};

export default VideoSection;
