import React from "react";
import { graphql } from "gatsby";
import { Grid, makeStyles } from "@material-ui/core";
import TechnologyCard from "../../technology-card/_components/technology-card";
import SectionHeader from "../../section-header";
import SectionContainer from "../../section-container";

export const TechnologyCardsSectionQueryFragment = graphql`
  fragment TechnologyCardsSection on ContentfulModuleTechnologyCardsSection {
    sectionHeader {
      ...SectionHeader
    }
    elements: cards {
      ... on ContentfulBlockTechnologyCardLarge {
        id
        image {
          ...Media900
        }
        header
        subheader {
          childMarkdownRemark {
            html
          }
        }
        cta {
          ...Cta
        }
        __typename
      }
      ... on ContentfulBlockTechnologyCard {
        id
        image {
          ...Media900
        }
        header
        subheader {
          childMarkdownRemark {
            html
          }
        }
        cta {
          ...Cta
        }
        __typename
      }
    }
    sectionProperties {
      ...SectionProperties
    }
    __typename
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(10)
  }
}));

const TechnologyCardsSection = ({
  data: { sectionHeader, elements, sectionProperties }
}) => {
  const classes = useStyles();

  return (
    <SectionContainer sectionProperties={sectionProperties}>
      <Grid item container>
        {!!sectionHeader ? (
          <Grid item xs={12} md={6} className={classes.header}>
            <SectionHeader data={sectionHeader} />
          </Grid>
        ) : null}
        <Grid container item spacing={5}>
          {elements.map(element => {
            if (element.__typename === "ContentfulBlockTechnologyCardLarge") {
              return (
                <Grid item xs={12} sm={6} key={element.id}>
                  <TechnologyCard data={element} />
                </Grid>
              );
            } else if (element.__typename === "ContentfulBlockTechnologyCard") {
              return (
                <Grid
                  item
                  xs={12}
                  sm={!!sectionHeader ? 3 : 4}
                  key={element.id}
                >
                  <TechnologyCard data={element} />
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default TechnologyCardsSection;
