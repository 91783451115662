const validate = (values, requiredFields, disallowedEmailDomains) => {
  const errors = {};

  if (!values.name && requiredFields.name) {
    errors.name = "Required";
  } else if (values.name && values.name.trim().length < 3) {
    errors.name = "Must be more than 2 characters";
  }

  if (!values.email && requiredFields.email) {
    errors.email = "Required";
  } else if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  } else if (
    values.email &&
    disallowedEmailDomains &&
    disallowedEmailDomains.some(domain => values.email.includes(domain))
  ) {
    errors.email = "Use your business email";
  }

  if (!values.message && requiredFields.message) {
    errors.message = "Required";
  } else if (values.message && values.message.trim().length < 11) {
    errors.message = "Must be more than 10 characters";
  }

  if (!values.company && requiredFields.company) {
    errors.company = "Required";
  } else if (values.company && values.company.trim().length < 3) {
    errors.company = "Must be more than 2 characters";
  }

  if (!values.phone && requiredFields.phone) {
    errors.phone = "Required";
  } else if (values.phone && !/^[0-9()-+ ]{6,20}$/.test(values.phone)) {
    errors.phone = "Invalid phone number";
  }

  if (!values.privacy) {
    errors.privacy = "Required";
  }

  if (!values.marketingConsent && requiredFields.marketingConsent) {
    errors.marketingConsent = "Required";
  }

  return { values, errors };
};

export default validate;
